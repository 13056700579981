import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { SearchOutlined } from '@ant-design/icons';
import classnames from 'classnames';
import { debounce, isNil } from 'lodash-es';
import { Empty, TablePaginationConfig } from 'antd';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { SelectValue } from 'antd/lib/select';
import { useAnalyticsLinkActivated } from '../../../../../cross-cutting-concerns/analytics/hooks/useAnalyticsLinkActivated';
import { useAnalyticsSetFilter } from '../../../../../cross-cutting-concerns/analytics/hooks/useAnalyticsSetFilter';
import { useAnalyticsSetPageInfo } from '../../../../../cross-cutting-concerns/analytics/hooks/useAnalyticsSetPageInfo';
import { useAnalyticsSetSearch } from '../../../../../cross-cutting-concerns/analytics/hooks/useAnalyticsSetSearch';
import {
  AnalyticsLink,
  AnalyticsSiteListFilter,
  IAnalyticsFilter,
} from '../../../../../cross-cutting-concerns/analytics/interfaces/Analytics.types';
import {
  InputFilterSitesList,
  InputMaybe,
  InputSortOptions,
  Maybe,
  Site,
  SortOrders,
} from '../../../../../cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { SiteListActions } from '../../state/siteListActions';
import * as siteListSelectors from '../../state/siteListSelectors';
import { CreateSiteModal } from '../../../modals/components/CreateSiteModal/CreateSiteModal';
import { StyledSiteList } from './SiteList.styles';
import { getSiteListColumns } from './columns/SiteList.columns';
import { PrimaryButton } from 'lib/components/Button/PrimaryButton/PrimaryButton';
import { Input } from 'lib/components/Input/Input';
import { SiteModalsActions } from 'app/modules/site-management/modals/state/siteModalsActions';
import { PermissionGuard } from 'app/cross-cutting-concerns/authentication/components/PermissionGuard/PermissionGuard';
import { Permission } from 'config/permissions';
import { useAnalyticsSetSiteAttributes } from 'app/cross-cutting-concerns/analytics/hooks/useAnalyticsSetSiteAttributes';
import {
  InfiniteScrollConstants,
  OpenSearch,
  TAGS_MAX_LENGTH,
  TAGS_SEARCH_DELAY_IN_MS,
  TAGS_SEARCH_LIMIT,
} from 'config/constants';
import { Table } from 'lib/components/Table/Table';
import { DrawersActions } from 'app/cross-cutting-concerns/drawers/state/drawersSlice';
import { Select } from 'lib/components/Select/Select';
import { SelectUtils } from 'lib/components/Select/SelectUtils';
import { RoutePaths } from 'config/route-paths';

export const SiteList = (): JSX.Element => {
  const analyticsLinkActivated = useAnalyticsLinkActivated();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const data = useSelector(siteListSelectors.selectData) || [];
  const isLoading = !!useSelector(siteListSelectors.selectIsLoading);
  const totalCount = useSelector(siteListSelectors.selectTotalCount) || 0;
  const activeSearchTextFilter = useSelector(siteListSelectors.selectActiveSearchTextFilter);
  const sortField = useSelector(siteListSelectors.selectSortField) || 'name';
  const sortOrder = useSelector(siteListSelectors.selectSortOrder) || SortOrders.Asc;
  const [selectedRowKey, setSelectedRowKey] = useState<string>('');

  // TAGS FILTER
  const [isTagsFilterOpen, setIsTagsFilterOpen] = useState(false);
  const [searchVal, setSearchValue] = useState('');
  const activeIdsFilter = useSelector(siteListSelectors.selectActiveIdsFilter);
  const tagSuggestions = useSelector(siteListSelectors.selectTagSuggestions) || [];
  const areTagSuggestionsLoading = useSelector(siteListSelectors.selectAreTagSuggestionsLoading);
  const activeTagsFilter = useSelector(siteListSelectors.selectActiveTagsFilter);
  const isLoadingMoreData = useSelector(siteListSelectors.selectIsLoadingMoreData);
  const nextPaginationToken = useSelector(siteListSelectors.selectNextPaginationToken);
  const tagOptions =
    Array.from(new Set([...(activeTagsFilter || []), ...tagSuggestions])).map(tag => ({
      label: tag,
      value: tag,
    })) || [];

  const getActiveFiltersCallback = useCallback(() => {
    const activeFilters: IAnalyticsFilter[] = [];

    if (!isNil(activeSearchTextFilter)) {
      activeFilters.push(AnalyticsSiteListFilter.SEARCH);
    }

    if (!isNil(activeTagsFilter) && activeTagsFilter?.length > 0) {
      activeFilters.push(AnalyticsSiteListFilter.TAGS);
    }

    if (!isNil(activeIdsFilter)) {
      activeFilters.push(AnalyticsSiteListFilter.IDS);
    }

    return activeFilters;
  }, [activeSearchTextFilter, activeTagsFilter, activeIdsFilter]);

  useAnalyticsSetFilter({
    getActiveFiltersCallback,
  });
  useAnalyticsSetSearch(activeSearchTextFilter);
  useAnalyticsSetSiteAttributes({
    count: totalCount,
  });
  useAnalyticsSetPageInfo({});

  const requestParams = (): {
    filter?: InputMaybe<InputFilterSitesList>;
    sortOptions?: InputMaybe<InputSortOptions>;
  } => ({
    filter: {
      searchText: activeSearchTextFilter,
      ids: activeIdsFilter,
      tags: activeTagsFilter,
    },
    sortOptions: {
      field: sortField,
      order: sortOrder,
    },
  });

  useEffect(() => {
    dispatch(
      SiteListActions.getSiteListRequest({
        ...requestParams(),
        paginationOptions: {
          limit: InfiniteScrollConstants.MAX_ITEMS,
          paginationToken: '',
        },
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, activeSearchTextFilter, sortField, sortOrder, activeIdsFilter, activeTagsFilter]);

  const loadMore = (): void => {
    dispatch(
      SiteListActions.getSiteListMoreDataRequest({
        ...requestParams(),
        paginationOptions: {
          limit: InfiniteScrollConstants.MAX_ITEMS,
          paginationToken: nextPaginationToken,
        },
      })
    );
  };

  useEffect(() => {
    if (id) {
      dispatch(DrawersActions.showSiteDetailsDrawer({ siteId: id }));
    }
  }, [dispatch, id]);

  const tableColumns = getSiteListColumns({ t });

  const showCreateSiteModal = (): void => {
    dispatch(SiteModalsActions.showCreateSiteModal());
  };

  const handleSiteSearch = debounce((e: React.ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value.trim() || undefined;

    dispatch(SiteListActions.setSearchText(value));
  }, 500);

  const onChange = (
    _pagination: TablePaginationConfig,
    _filters: Record<string, FilterValue | null>,
    sorter: SorterResult<Site> | SorterResult<Site>[]
  ): void => {
    if (Array.isArray(sorter)) return;
    let sorterOrder = SortOrders.Asc;
    let sorterField = sorter.field;
    if (sorter.order === 'descend') sorterOrder = SortOrders.Desc;
    if (Array.isArray(sorter.field)) {
      sorterField = sorter.field[1];
    }
    dispatch(SiteListActions.setActiveSortField(sorterField as Maybe<string>));
    dispatch(SiteListActions.setActiveSortOrder(sorterOrder as Maybe<SortOrders>));
  };

  // TAGS FILTER
  useEffect(() => {
    dispatch(
      SiteListActions.listTagsRequest({
        filter: { textBegin: '' },
        paginationOptions: { limit: TAGS_SEARCH_LIMIT },
      })
    );
  }, [dispatch]);

  const handleTagsFilterChange = (values: SelectValue): void => {
    const tagValues = values as string[];

    analyticsLinkActivated({ linkName: AnalyticsLink.SITE_LIST_TAGS_FILTER });

    dispatch(SiteListActions.setActiveTagsFilter(tagValues));
  };

  useEffect(
    () => (): void => {
      dispatch(SiteListActions.resetState());
      dispatch(DrawersActions.hideSiteDetailsDrawer());
    },
    [dispatch]
  );

  const searchTags = useMemo(
    () =>
      debounce((searchValue: string): void => {
        dispatch(
          SiteListActions.listTagsRequest({
            filter: {
              textBegin: searchValue.trim(),
            },
            paginationOptions: {
              limit: OpenSearch.MAX_RESULT_WINDOW,
            },
          })
        );
      }, TAGS_SEARCH_DELAY_IN_MS),
    [dispatch]
  );

  const handleSearch = (value: string): void => {
    const truncatedValue = value.substring(0, TAGS_MAX_LENGTH);
    setSearchValue(truncatedValue);
    searchTags(truncatedValue);
  };

  const rowClassName = (record: Site): string => (record.id === selectedRowKey ? 'table__selected-row' : '');

  return (
    <StyledSiteList className="site-list">
      <div className="site-list__header">
        <div className="site-list__header-content">
          <div className="site-list__container--fluid">
            <div className="site-list__page-info">
              <h1 className="site-list__title">{t('siteList.title')}</h1>
              <PermissionGuard requiredPermissions={[Permission.Site.CREATE]}>
                <div className="add-new-site-wrapper">
                  <PrimaryButton size="m" className="site-list__add-new-button" onClick={showCreateSiteModal}>
                    {t('siteList.createNewSite')}
                  </PrimaryButton>
                </div>
              </PermissionGuard>
            </div>
            <div className="site-list__filter">
              <div className="site-list__select-wrapper tag-filter-wrapper" id="site-list-machines-filter-id">
                <Select
                  className="site-list__tags-filter"
                  loading={areTagSuggestionsLoading}
                  onChange={handleTagsFilterChange}
                  showSearch={true}
                  onSearch={handleSearch}
                  searchValue={searchVal}
                  onReset={(): void => {
                    setSearchValue('');
                    searchTags('');
                  }}
                  {...SelectUtils.getMultiSelectionProps({
                    mode: 'multiple',
                    options: tagOptions,
                    onDropdownVisibleChange: (isOpen: boolean): void => {
                      setIsTagsFilterOpen(isOpen);
                      setSearchValue('');
                      searchTags('');
                    },
                    dropdownVisibleState: isTagsFilterOpen,
                    valueArray: activeTagsFilter,
                    dropdownLabel: t('siteList.table.tags'),
                  })}
                />
              </div>
              <Input
                className={classnames('site-list__search-bar', 'site-list__search-input')}
                placeholder={t('common.searchFor')}
                prefix={
                  <span className="site-list__search-bar-icon">
                    <SearchOutlined />
                  </span>
                }
                onChange={handleSiteSearch}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="site-list__body">
        <div className="site-list__body-content">
          <div className="site-list__container--fluid">
            <Table
              dataSource={data}
              loading={isLoading}
              className="site-list__table"
              columns={tableColumns}
              rowKey="id"
              onRow={(siteRowData: Site): { onClick(): void } => ({
                onClick: (): void => {
                  analyticsLinkActivated({
                    linkName: AnalyticsLink.SITE_LIST_OPEN_DETAILS,
                  });
                  setSelectedRowKey(siteRowData.id);

                  dispatch(DrawersActions.showSiteDetailsDrawer({ siteId: siteRowData.id }));
                  navigate(RoutePaths.SITE_DETAILS.replace(':id', siteRowData.id));
                },
              })}
              rowClassName={rowClassName}
              locale={{
                emptyText: (
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('siteList.table.noSitesFound')} />
                ),
              }}
              onChange={onChange}
              sortDirections={['ascend', 'descend', 'ascend']}
              infiniteScroll={{
                id: 'site-list-infinite-scroll',
                next: loadMore,
                nextPaginationToken,
                isLoadingMoreData,
              }}
              showScrollButtons={true}
            />
          </div>
        </div>
      </div>
      <CreateSiteModal />
    </StyledSiteList>
  );
};
