import { get, isNil, isNumber } from 'lodash-es';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import GoogleMapReact, { Coords } from 'google-map-react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useTheme } from 'styled-components';
import { ColorUtils } from '../../../../../../lib/utils/colors/ColorUtils';
import { SiteMapPin } from '../../../components/SiteMapPin/SiteMapPin';
import { IShowChangeGeofenceModalAction, SiteModalsActions } from '../../../modals/state/siteModalsActions';
import { StyledSiteMap } from './SiteMap.styles';
import { Site } from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { MapUtils } from 'lib/utils/map/MapUtils';
import { GEOFENCE_MINIMUM_RADIUS, Map } from 'app/components/Map/Map';
import { SecondaryButton } from 'lib/components/Button/SecondaryButton/SecondaryButton';
import { Optional } from 'lib/types/Optional';

const CONSTANTS = {
  enableZoomPadding: true,
  initSize: { width: 1, height: 1 },
};

export interface SiteMapProps {
  site: Site;
  circleGeofence?: CircleGeofence;
  showChangeGeofenceButton?: boolean;
  isLoading?: boolean;
  onGoogleApisInitialized?: ({ mapInstance, mapApis }: { mapInstance: any; mapApis: any }) => void;
}

export interface OptionalCoords {
  lat: Optional<number>;
  lng: Optional<number>;
}

export interface CircleGeofence {
  center: Coords;
  radius: number;
  onCircleGeofenceInitialized?: (circleGeofenceInstance: any) => void;
}

const hasLocationData = (siteCoords: OptionalCoords): siteCoords is Coords =>
  isNumber(get(siteCoords, 'lat')) && isNumber(get(siteCoords, 'lng'));

export const SiteMap = ({
  site,
  circleGeofence,
  showChangeGeofenceButton = false,
  isLoading,
  onGoogleApisInitialized,
}: SiteMapProps): JSX.Element => {
  const mapInstanceRef = useRef<any>(null);
  const mapApisRef = useRef<any>(null);
  const mapRef = useRef<HTMLDivElement>(null);
  const circleGeofenceInstanceRef = useRef<any>(null);
  const [isViewportInitialized, setIsViewportInitialized] = useState(false);
  const [googleApisInitialized, setGoogleApisInitialized] = useState(false);
  const [size, setSize] = useState(CONSTANTS.initSize);
  const [center, setCenter] = useState<GoogleMapReact.Coords | undefined>(undefined);
  const [zoom, setZoom] = useState<number | undefined>(undefined);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useTheme();

  useEffect(
    () =>
      setSize({
        width: mapRef.current?.clientWidth ?? 1,
        height: mapRef.current?.clientHeight ?? 1,
      }),
    []
  );

  const siteCoords: OptionalCoords = useMemo(
    (): OptionalCoords => ({
      lat: site.location?.latitude,
      lng: site.location?.longitude,
    }),
    [site.location?.latitude, site.location?.longitude]
  );

  const points: Coords[] = useMemo(() => {
    const pointsArray = [];

    if (hasLocationData(siteCoords)) {
      pointsArray.push(siteCoords);
    }

    return pointsArray;
  }, [siteCoords]);

  const boundingBox = useMemo(
    () => MapUtils.calculateBoundingBox({ googleApisInitialized, mapApisRef, points }),
    [googleApisInitialized, points]
  );

  const initialCenter = useMemo(
    () => MapUtils.calculateCenter({ points, boundingBox, size }),
    [boundingBox, points, size]
  );

  const initialZoom = useMemo(
    () => MapUtils.calculateZoom({ points, boundingBox, size, enableZoomPadding: CONSTANTS.enableZoomPadding }),
    [points, boundingBox, size]
  );

  const onZoomAnimationEnd = (args: number): void => setZoom(args);

  const handleGoogleApisInitialized = ({ mapInstance, mapApis }: { mapInstance: any; mapApis: any }): void => {
    mapInstanceRef.current = mapInstance;
    mapApisRef.current = mapApis;

    if (onGoogleApisInitialized) {
      onGoogleApisInitialized({ mapInstance, mapApis });
    }

    setGoogleApisInitialized(true);
  };

  const onChange = useCallback(
    ({ center: newCenter, zoom: newZoom }: { center: Coords | undefined; zoom: number | undefined }): void => {
      if (isViewportInitialized) {
        setCenter(newCenter);
        setZoom(newZoom);
        return;
      }

      if (initialCenter !== undefined && initialZoom !== undefined) {
        setCenter(initialCenter);
        setZoom(initialZoom);
        setIsViewportInitialized(true);
      }
    },
    [initialCenter, initialZoom, isViewportInitialized]
  );

  useEffect(() => {
    // Super hacky way to trigger setting the initial viewport (zoom and center). Don't do this at home (☉_ ☉)
    // Since there was no suitable callback we are triggering the initial onChange after a small timeout.
    if (!isViewportInitialized) {
      setTimeout(() => {
        onChange({ center: undefined, zoom: undefined });
      }, 1000);
    }
  }, [isViewportInitialized, onChange]);

  useEffect(() => {
    if (!googleApisInitialized) return;
    let geofence;

    const hasCircleGeofenceProp =
      !isNil(circleGeofence) &&
      circleGeofence?.center.lat !== undefined &&
      circleGeofence?.center.lng !== undefined &&
      circleGeofence.radius !== undefined;

    const hasSiteGeofence =
      !isNil(site.geofence?.centerPoint?.latitude) &&
      !isNil(site.geofence?.centerPoint?.longitude) &&
      isNumber(site.geofence?.radius);

    if (hasCircleGeofenceProp) {
      geofence = {
        center: circleGeofence?.center,
        radius: circleGeofence?.radius,
      };
    } else if (hasSiteGeofence) {
      geofence = {
        center: {
          lat: site.geofence?.centerPoint?.latitude ?? 0,
          lng: site.geofence?.centerPoint?.longitude ?? 0,
        },
        radius: site.geofence?.radius ?? 0,
      };
    }

    if (!isNil(geofence)) {
      const circleInstance: google.maps.Circle = new mapApisRef.current.Circle({
        strokeColor: ColorUtils.getCssVariableValue(theme.colors.primary),
        fillColor: ColorUtils.getCssVariableValue(theme.colors.primary),
        strokeOpacity: 1,
        fillOpacity: 0.3,
        strokeWeight: 2,
        editable: false,
        draggable: false,
        center: geofence.center,
        radius: geofence.radius,
        suppressUndo: true,
      });

      mapApisRef.current?.event.addListener(
        circleInstance,
        'radius_changed',
        function handleCircleRadiusChanged(this: google.maps.Circle): void {
          if (this.getRadius() < GEOFENCE_MINIMUM_RADIUS) {
            this.setRadius(GEOFENCE_MINIMUM_RADIUS);
          }
        }
      );

      // Remove old circle from map
      circleGeofenceInstanceRef.current?.setMap(null);
      circleGeofenceInstanceRef.current = circleInstance;

      // Add new circle to map
      circleInstance.setMap(mapInstanceRef.current);

      if (circleGeofence?.onCircleGeofenceInitialized) {
        circleGeofence.onCircleGeofenceInitialized(circleGeofenceInstanceRef.current);
      }

      onChange({ center: initialCenter, zoom: initialZoom });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    googleApisInitialized,
    circleGeofence,
    site.geofence?.centerPoint?.latitude,
    site.geofence?.centerPoint?.longitude,
    site.geofence?.radius,
    theme.colors.primary,
  ]);

  return (
    <StyledSiteMap className="site-map">
      <Map
        className="site-map__map"
        ref={mapRef}
        onChange={onChange}
        onGoogleApisInitialized={handleGoogleApisInitialized}
        onZoomAnimationEnd={onZoomAnimationEnd}
        zoom={zoom}
        center={center}
        isLoading={isLoading}
      >
        {points.length > 0 ? <SiteMapPin lat={siteCoords.lat} lng={siteCoords.lng} /> : <></>}
      </Map>
      {showChangeGeofenceButton && (
        <SecondaryButton
          className="site-map__change-geofence-btn"
          onClick={(): IShowChangeGeofenceModalAction => dispatch(SiteModalsActions.showChangeGeofenceModal())}
        >
          {t('siteDetails.changeGeofenceBtn')}
        </SecondaryButton>
      )}
    </StyledSiteMap>
  );
};
