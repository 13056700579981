import styled from 'styled-components';

export const StyledSiteDetailsPanel = styled.div`
  min-height: inherit;

  .loading-wrapper {
    height: calc(100vh - 294px);
  }

  .site-details__title {
    margin-bottom: ${(props): string => props.theme.spacing.xs};
    ${(props): string => props.theme.fontStyles.redesign.heading2}
    text-transform: uppercase;
  }

  .site-details__description {
    ${(props): string => props.theme.fontStyles.redesign.base.strong}
    font-weight: 500;
  }

  .site-details__heading {
    margin-bottom: ${(props): string => props.theme.spacing.base};
    flex-direction: column;
  }

  .site-details__machine-list-table-wrapper,
  .site-details__cleaning-plan-wrapper {
    border: 1px solid ${(props): string => props.theme.colors.brightGrey};
    padding: ${(props): string => props.theme.spacing.lg};
  }

  .site-details__edit-button-assigned-machines {
    height: 17px;
    width: 17px;
    padding: 0 0 0 0;
    margin-bottom: 10px;

    svg {
      path {
        fill: ${(props): string => props.theme.colors.darkGrey};
      }
    }
  }

  .site-details__delete-site-wrapper {
    height: 72px;
    width: 100%;

    .site-details__delete-button {
      text-transform: capitalize;
    }
  }
`;
