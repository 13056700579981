import { ColumnsType } from 'antd/lib/table';
import { TFunction } from 'react-i18next';
import { i18n as i18next } from 'i18next';
import { DATE_FORMAT_PATTERN } from '../../../../../../../lib/utils/date-handling/DateTime.types';
import { Machine } from '../../../../../machine-inventory/interfaces/Machine.types';
import { SiteStatusIndicator } from '../../SiteStatusIndicator/SiteStatusIndicator';
import { DateTime } from 'lib/utils/date-handling/DateTime';
import { SvgIcon } from 'lib/components/SvgIcon/SvgIcon';
import { TextButton } from 'lib/components/Button/TextButton/TextButton';

export const getSiteDetailsMachineListColumns = ({
  t,
  i18n,
  handleUnassignModal,
  hasPermissionSiteMachineUpdate,
}: {
  t: TFunction;
  i18n: i18next;
  handleUnassignModal: (machine: Machine) => void;
  hasPermissionSiteMachineUpdate: boolean;
}): ColumnsType<Machine> => [
  {
    title: t('siteDetails.machineList.table.name'),
    dataIndex: 'name',
    width: 139,
    ellipsis: {
      showTitle: true,
    },
  },
  {
    title: t('siteDetails.machineList.table.serialNo'),
    dataIndex: 'serialNumber',
    width: 90,
    ellipsis: {
      showTitle: true,
    },
  },
  {
    title: t('siteDetails.machineList.table.materialNo'),
    dataIndex: 'materialNumber',
    width: 123,
    ellipsis: {
      showTitle: true,
    },
  },
  {
    title: t('siteDetails.machineList.table.lastConnection'),
    width: 143,
    dataIndex: 'lastActivityAt',
    render: (lastActivityAt): JSX.Element => (
      <div
        className="site-details__last-connection"
        title={DateTime.formatDateByLocale(i18n.language, lastActivityAt, DATE_FORMAT_PATTERN.DATE)
          .concat(' ')
          .concat(DateTime.formatDateByLocale(i18n.language, lastActivityAt, DATE_FORMAT_PATTERN.TIME))}
      >
        {DateTime.formatDateByLocale(i18n.language, lastActivityAt, DATE_FORMAT_PATTERN.DATE)}
        <span>&nbsp;</span>
        {DateTime.formatDateByLocale(i18n.language, lastActivityAt, DATE_FORMAT_PATTERN.TIME)}
      </div>
    ),
    ellipsis: {
      showTitle: true,
    },
  },
  {
    title: t('siteDetails.machineList.table.status'),
    dataIndex: 'lastActivityAt',
    key: 'lastActivityAt',
    className: 'machine-list__status-column',
    render: (_, machine: Machine): React.JSX.Element => (
      <SiteStatusIndicator lastActivityAt={machine.lastActivityAt} className="machine-list__status-indicator" />
    ),
    width: 70,
    align: 'center',
    ellipsis: {
      showTitle: true,
    },
  },
  ...(hasPermissionSiteMachineUpdate
    ? [
        {
          width: 48,
          render: (_: string, machine: Machine): JSX.Element => (
            <TextButton
              className="site-details-site-machine-list__unassign-btn"
              key="delete-button"
              onClick={(e): void => {
                e.stopPropagation();
                handleUnassignModal(machine);
              }}
            >
              <SvgIcon name="delete" />
            </TextButton>
          ),
        },
      ]
    : []),
];
