import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { StyledMachineStatusIndicatorTooltip } from './SiteStatusIndicatorTooltip.styles';
import { SvgIcon } from 'lib/components/SvgIcon/SvgIcon';
import { IMachineStatusItemData } from 'app/modules/machine-inventory/interfaces/Machine.types';

interface SiteStatusIndicatorTooltipProps {
  statusItem: IMachineStatusItemData;
}
export const SiteStatusIndicatorTooltip = ({ statusItem }: SiteStatusIndicatorTooltipProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <StyledMachineStatusIndicatorTooltip className="machine-status-indicator-tooltip">
      <SvgIcon
        name={statusItem.icon}
        className={classnames(
          'machine-status-indicator-tooltip__icon',
          `machine-status-indicator-tooltip__icon--${statusItem.icon}`
        )}
      />
      <div className="machine-status-indicator-tooltip__info">
        <div className="machine-status-indicator-tooltip__title">{t(statusItem.title)}</div>
        <div className="machine-status-indicator-tooltip__description">{t(statusItem.description)}</div>
      </div>
    </StyledMachineStatusIndicatorTooltip>
  );
};
