import { CallEffect, ForkEffect, GetContextEffect, JoinEffect, PutEffect } from 'redux-saga/effects';
import { getContext, call, put, takeLatest, fork, join } from 'typed-redux-saga';
import { IDependencies } from '../../../../cross-cutting-concerns/dependency-injection/interfaces/IDependencies';
import {
  MachinesAvailableToBeAssigned,
  MachinesAvailableToBeAssignedVariantData,
} from '../../../machine-inventory/interfaces/Machine.types';
import { SiteAddressesSuggestionResponse, SiteMachinesAvailable, SiteTagsList } from '../../interfaces/Site.types';
import {
  IAvailableMachinesErrorAction,
  IAvailableMachinesSuccessAction,
  IAvailableSiteMachinesErrorAction,
  IAvailableSiteMachinesRequestAction,
  IAvailableSiteMachinesSuccessAction,
  IGetAddressesSuggestionErrorAction,
  IGetAddressesSuggestionRequestAction,
  IGetAddressesSuggestionSuccessAction,
  IGetSiteManagerListErrorAction,
  IGetSiteManagerListRequestAction,
  IGetSiteManagerListSuccessAction,
  IHideAssignMachinesModalAction,
  IListTagsErrorAction,
  IListTagsRequestAction,
  IListTagsSuccessAction,
  ISetSearchingAddressValueAction,
  SiteModalsActions,
} from './siteModalsActions';
import { UserListResponse } from 'app/modules/user-management/interfaces/User.types';

export function* availableMachinesSaga(): Generator<
  | GetContextEffect
  | ForkEffect<MachinesAvailableToBeAssigned>
  | ForkEffect<MachinesAvailableToBeAssignedVariantData>
  | JoinEffect
  | PutEffect<IAvailableMachinesSuccessAction>
  | PutEffect<IAvailableMachinesErrorAction>
  | PutEffect<IHideAssignMachinesModalAction>,
  void,
  IDependencies
> {
  const { machineService } = yield* getContext<IDependencies>('dependencies');

  try {
    const availableMachinesResponseTask = yield* fork(machineService.listAvailableToBeAssigned);
    const availableMachinesPicturesResponseTask = yield* fork(machineService.listAvailableToBeAssignedPictures);

    const availableMachinesResponse = yield* join(availableMachinesResponseTask);
    yield* put(SiteModalsActions.availableMachinesSuccess(availableMachinesResponse));

    const availableMachinesPicturesResponse = yield* join(availableMachinesPicturesResponseTask);
    yield* put(SiteModalsActions.availableMachinesPicturesSuccess(availableMachinesPicturesResponse));
  } catch (error) {
    console.error(error);

    yield* put(
      SiteModalsActions.availableMachinesError({
        error,
      })
    );
  }
}

export function* getAddressesSuggestionSaga(
  action: IGetAddressesSuggestionRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<SiteAddressesSuggestionResponse>
  | PutEffect<ISetSearchingAddressValueAction>
  | PutEffect<IGetAddressesSuggestionRequestAction>
  | PutEffect<IGetAddressesSuggestionSuccessAction>
  | PutEffect<IGetAddressesSuggestionErrorAction>,
  void,
  IDependencies
> {
  const { siteService } = yield* getContext<IDependencies>('dependencies');

  try {
    yield* put(SiteModalsActions.setSearchingAddressValue(action.payload.filter.text));

    const { siteAddressesSuggestion } = yield* call(siteService.getAddressesSuggestion, action.payload);

    yield* put(
      SiteModalsActions.getAddressesSuggestionSuccess({
        filter: action.payload.filter,
        siteAddressesSuggestion,
      })
    );
  } catch (error) {
    console.error(error);

    yield* put(
      SiteModalsActions.getAddressesSuggestionError({
        filter: action.payload.filter,
        error,
      })
    );
  }
}

export function* availableSiteMachinesSaga(
  action: IAvailableSiteMachinesRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<SiteMachinesAvailable>
  | PutEffect<IAvailableSiteMachinesSuccessAction>
  | PutEffect<IAvailableSiteMachinesErrorAction>,
  void,
  IDependencies
> {
  const { siteService } = yield* getContext<IDependencies>('dependencies');

  try {
    const response = yield* call(siteService.listAvailableSiteMachines, action.payload);

    yield* put(SiteModalsActions.availableSiteMachinesSuccess(response));
  } catch (error) {
    console.error(error);

    yield* put(
      SiteModalsActions.availableSiteMachinesError({
        error,
      })
    );
  }
}

export function* getSiteManagerListSaga(
  action: IGetSiteManagerListRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<UserListResponse>
  | PutEffect<IGetSiteManagerListSuccessAction>
  | PutEffect<IGetSiteManagerListErrorAction>,
  void,
  IDependencies
> {
  const { userService } = yield* getContext<IDependencies>('dependencies');

  try {
    const response = yield* call(userService.list, action.payload);

    yield* put(SiteModalsActions.getSiteManagerListSuccess(response));
  } catch (error) {
    console.error(error);

    yield* put(
      SiteModalsActions.getSiteManagerListError({
        error,
      })
    );
  }
}

export function* listTagsSaga(
  action: IListTagsRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<SiteTagsList>
  | PutEffect<IListTagsRequestAction>
  | PutEffect<IListTagsSuccessAction>
  | PutEffect<IListTagsErrorAction>,
  void,
  IDependencies
> {
  const { siteService } = yield* getContext<IDependencies>('dependencies');

  try {
    const { tags } = yield* call(siteService.listTags, action.payload);

    yield* put(
      SiteModalsActions.listTagsSuccess({
        tags,
      })
    );
  } catch (error) {
    console.error(error);

    yield* put(
      SiteModalsActions.listTagsError({
        error,
      })
    );
  }
}

export function* siteModalsSaga(): Generator<ForkEffect<never>, void> {
  yield* takeLatest(SiteModalsActions.AVAILABLE_MACHINES_REQUEST, availableMachinesSaga);
  yield* takeLatest(SiteModalsActions.GET_ADDRESSES_SUGGESTION_REQUEST, getAddressesSuggestionSaga);
  yield* takeLatest(SiteModalsActions.AVAILABLE_SITE_MACHINES_REQUEST, availableSiteMachinesSaga);
  yield* takeLatest(SiteModalsActions.GET_SITE_MANAGER_LIST_REQUEST, getSiteManagerListSaga);
  yield* takeLatest(SiteModalsActions.LIST_TAGS_REQUEST, listTagsSaga);
}
