import { Col, Row } from 'antd';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { isNil, isNumber } from 'lodash-es';
import { Coords } from 'google-map-react';
import { PageNamePrefix } from '../../../../../../config/pages';
import { ThemeConstants } from '../../../../../../config/theme';
import { LoadingPage } from '../../../../../../lib/components/LoadingPage/LoadingPage';
import { useAnalyticsSetPageInfo } from '../../../../../cross-cutting-concerns/analytics/hooks/useAnalyticsSetPageInfo';
import { FeatureFlagSelectors } from '../../../../../cross-cutting-concerns/feature-flags/state/featureFlagSelectors';
import { CleaningActions } from '../../../../cleaning/state/cleaningActions';
import * as siteDetailsSelectors from '../../state/siteDetailsPanelSelectors';
import * as siteModalsSelectors from '../../../modals/state/siteModalsSelectors';
import { SiteDetailsPanelActions } from '../../state/siteDetailsPanelActions';
import { SiteDetailsInfo } from '../SiteDetailsInfo/SiteDetailsInfo';
import { AssignMachineToSiteModal } from '../../../modals/components/AssignMachineToSiteModal/AssignMachineToSiteModal';
import { UnassignMachineFromSiteModal } from '../../../modals/components/UnassignMachineToSiteModal/UnassignMachineFromSiteModal';
import { SiteMap } from '../SiteMap/SiteMap';
import { CleaningPlan } from '../CleaningPlan/CleaningPlan';
import { SiteDetailsSiteManagerList } from '../SiteDetailsSiteManagerList/SiteDetailsSiteManagerList';
import { SiteDetailsAssignedMachinesList } from '../SiteDetailsAssignedMachinesList/SiteDetailsAssignedMachinesList';
import { StyledSiteDetailsPanel } from './SiteDetailsPanel.styles';
import { ChangeGeofenceModal } from 'app/modules/site-management/modals/components/ChangeGeofenceModal/ChangeGeofenceModal';
import { Permission } from 'config/permissions';
import { isUserPermitted } from 'app/cross-cutting-concerns/authentication/utils/isUserPermitted';
import { selectPermissions } from 'app/cross-cutting-concerns/authentication/state/authenticationSelectors';
import { AddWorkIntervalModal } from 'app/modules/site-management/modals/components/AddWorkIntervalModal/AddWorkIntervalModal';
import { EditWorkIntervalModal } from 'app/modules/site-management/modals/components/EditWorkIntervalModal/EditWorkIntervalModal';
import { DeleteWorkIntervalModal } from 'app/modules/site-management/modals/components/DeleteWorkIntervalModal/DeleteWorkIntervalModal';
import { AssignSiteManagerModal } from 'app/modules/site-management/modals/components/AssignSiteManagerModal/AssignSiteManagerModal';
import { UnassignSiteManagerModal } from 'app/modules/site-management/modals/components/UnassignSiteManagerModal/UnassignSiteManagerModal';
import { WorkIntervalDataRequestGuardContext } from 'app/modules/cleaning/contexts/WorkIntervalDataRequestGuardContext';
import * as authenticationSelectors from 'app/cross-cutting-concerns/authentication/state/authenticationSelectors';
import { PermissionGuard } from 'app/cross-cutting-concerns/authentication/components/PermissionGuard/PermissionGuard';
import { SecondaryButton } from 'lib/components/Button/SecondaryButton/SecondaryButton';
import { SiteModalsActions } from 'app/modules/site-management/modals/state/siteModalsActions';
import { DeleteSiteModal } from 'app/modules/site-management/modals/components/DeleteSiteModal/DeleteSiteModal';
import { EditSiteInfoModal } from 'app/modules/site-management/modals/components/EditSiteInfoModal/EditSiteInfoModal';
import { DrawersActions } from 'app/cross-cutting-concerns/drawers/state/drawersSlice';

interface SiteDetailsProps {
  id: string;
}

export const SiteDetailsPanel = ({ id }: SiteDetailsProps): JSX.Element => {
  const features = useSelector(FeatureFlagSelectors.selectFeatureFlagConfig);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const site = useSelector(siteDetailsSelectors.selectData);
  const isSiteLoading = useSelector(siteDetailsSelectors.selectIsLoading) ?? true;
  const isMapLoading = useSelector(siteDetailsSelectors.selectIsMapLoading) ?? false;
  const isDeleteSiteModalLoading = useSelector(siteModalsSelectors.selectIsDeleteSiteModalLoading) ?? true;
  const isGcd = useSelector(authenticationSelectors.selectHasAccessToGCD);

  const machinePaginationTokens = useSelector(siteDetailsSelectors.selectMachinePaginationTokens);
  const isMachineLoading = !!useSelector(siteDetailsSelectors.selectIsMachineLoading) ?? true;
  const machinePage = useSelector(siteDetailsSelectors.selectMachinePage);
  const machinePageSize = useSelector(siteDetailsSelectors.selectMachinePageSize);

  const hasLocation = isNumber(site?.location?.latitude) && isNumber(site?.location?.latitude);

  const userPermissions = useSelector(selectPermissions);

  const isSiteDetailsMapEnabled = features.SITE_DETAILS_MAP;

  const isLoading = (): boolean => {
    if (!site) return true;
    if (isDeleteSiteModalLoading) return true;

    return isSiteLoading && isMachineLoading;
  };

  useAnalyticsSetPageInfo({
    pageName: `${PageNamePrefix.SITE_DETAILS}${site?.name}`,
  });

  useEffect(() => {
    if (id) {
      dispatch(
        SiteDetailsPanelActions.getSiteDetailsPanelRequest({
          id,
          machinePaginationOptions: {
            limit: machinePageSize,
            paginationToken: machinePaginationTokens[machinePage] ?? '',
          },
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id, machinePage, machinePageSize]);

  // Loading data for cleaning plan work intervals here for performance reasons,
  // so it can be executed in parallel with the site details request
  useEffect(() => {
    if (id) {
      dispatch(CleaningActions.getWorkIntervalsRequest({ filter: { siteId: id } }));
    }
  }, [dispatch, id]);

  useEffect(
    () => (): void => {
      dispatch(SiteDetailsPanelActions.resetState());
      dispatch(DrawersActions.hideSiteDetailsDrawer());
    },
    [dispatch]
  );

  const showDeleteSiteModal = (): void => {
    dispatch(SiteModalsActions.showDeleteSiteModal());
  };

  const hasPermissionSiteUpdate = useMemo(
    () => isUserPermitted(userPermissions, [Permission.Site.UPDATE]),
    [userPermissions]
  );

  const getGeofence = ():
    | {
        center: Coords;
        radius: number;
        onCircleGeofenceInitialized?: (circleGeofenceInstance: any) => void;
      }
    | undefined => {
    const hasGeofence =
      !isNil(site?.geofence?.centerPoint?.latitude) &&
      !isNil(site?.geofence?.centerPoint?.longitude) &&
      isNumber(site?.geofence?.radius);

    if (hasGeofence) {
      return {
        center: {
          lat: site?.geofence?.centerPoint?.latitude ?? 0,
          lng: site?.geofence?.centerPoint?.longitude ?? 0,
        },
        radius: site?.geofence?.radius ?? 0,
      };
    }

    return undefined;
  };

  if (isLoading()) {
    return (
      <StyledSiteDetailsPanel className="site-details">
        <div className="loading-wrapper">
          <LoadingPage />
        </div>
      </StyledSiteDetailsPanel>
    );
  }

  return (
    <StyledSiteDetailsPanel className="site-details-panel">
      {site && (
        <Row gutter={[ThemeConstants.XL_GAP, ThemeConstants.BASE_GAP]} justify="center">
          <Col xs={14} sm={14} md={14} lg={14}>
            <Row className="site-details__heading">
              <h1 className="site-details__title">{site.name}</h1>
              <div className="site-details__description">{site.location?.address || '-'}</div>
            </Row>
            <SiteDetailsInfo site={site} isLoading={isSiteLoading} />
          </Col>
          <Col xs={10} sm={10} md={10} lg={10}>
            {isSiteDetailsMapEnabled && (
              <SiteMap
                site={site}
                circleGeofence={getGeofence()}
                showChangeGeofenceButton={hasPermissionSiteUpdate && hasLocation}
                isLoading={isMapLoading}
              />
            )}
          </Col>
          <Col xs={24} sm={24} md={24} lg={24}>
            <SiteDetailsAssignedMachinesList />
          </Col>
          {isGcd && (
            <Col xs={24} sm={24} md={24} lg={24}>
              <div className="site-details__cleaning-plan-wrapper">
                <WorkIntervalDataRequestGuardContext.Provider
                  value={{
                    iConfirmThatThisComponentDispatchesWorkIntervalRequestAction: true,
                  }}
                >
                  <CleaningPlan site={site} />
                </WorkIntervalDataRequestGuardContext.Provider>
              </div>
            </Col>
          )}
          <Col xs={24} sm={24} md={24} lg={24}>
            <SiteDetailsSiteManagerList site={site} />
          </Col>
          <Col xs={24} sm={24} md={24} lg={24}>
            <Row className="site-details__delete-site-wrapper" justify="center" align="bottom">
              <Col>
                <PermissionGuard requiredPermissions={[Permission.Site.DELETE]} key="site-details__delete-button">
                  <SecondaryButton size="m" onClick={showDeleteSiteModal} className="site-details__delete-button">
                    {t('siteDetails.info.deleteSite')}
                  </SecondaryButton>
                </PermissionGuard>
              </Col>
            </Row>
          </Col>
          <DeleteSiteModal site={site} />
          <EditSiteInfoModal />
          <AssignMachineToSiteModal site={site} />
          {/* TODO: Disabled while BE only allows assignment of a single machine */}
          {/* <AssignMachineToSiteModal site={site} assignedMachines={site?.machines.data ?? []} /> */}
          <UnassignMachineFromSiteModal site={site} />
          <ChangeGeofenceModal site={site} />
          <AddWorkIntervalModal site={site} />
          <DeleteWorkIntervalModal site={site} />
          <EditWorkIntervalModal site={site} />
          <AssignSiteManagerModal site={site} />
          <UnassignSiteManagerModal site={site} />
        </Row>
      )}
    </StyledSiteDetailsPanel>
  );
};
