import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Col, Empty, Row } from 'antd';
import * as siteDetailsSelectors from '../../state/siteDetailsPanelSelectors';
import { SiteDetailsPanelActions } from '../../state/siteDetailsPanelActions';
import { StyledSiteDetailsAssignedMachinesList } from './SiteDetailsAssignedMachinesList.styles';
import { getSiteDetailsMachineListColumns } from './columns/SiteDetailsMachineList.columns';
import { useAnalyticsLinkActivated } from 'app/cross-cutting-concerns/analytics/hooks/useAnalyticsLinkActivated';
import { AnalyticsLink } from 'app/cross-cutting-concerns/analytics/interfaces/Analytics.types';
import { Machine } from 'app/modules/machine-inventory/interfaces/Machine.types';
import { RoutePaths } from 'config/route-paths';
import { Table } from 'lib/components/Table/Table';
import {
  IShowAssignMachinesModalAction,
  SiteModalsActions,
} from 'app/modules/site-management/modals/state/siteModalsActions';
import { SecondaryButton } from 'lib/components/Button/SecondaryButton/SecondaryButton';
import { PermissionGuard } from 'app/cross-cutting-concerns/authentication/components/PermissionGuard/PermissionGuard';
import { Permission } from 'config/permissions';
import { isUserPermitted } from 'app/cross-cutting-concerns/authentication/utils/isUserPermitted';
import { selectPermissions } from 'app/cross-cutting-concerns/authentication/state/authenticationSelectors';
import { DrawersActions } from 'app/cross-cutting-concerns/drawers/state/drawersSlice';

export const SiteDetailsAssignedMachinesList = (): JSX.Element => {
  const analyticsLinkActivated = useAnalyticsLinkActivated();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userPermissions = useSelector(selectPermissions);
  const machineData = useSelector(siteDetailsSelectors.selectMachineListData) ?? [];
  const isMachineLoading = !!useSelector(siteDetailsSelectors.selectIsMachineLoading) ?? true;
  const machineTotalCount = useSelector(siteDetailsSelectors.selectMachineTotalCount) ?? 0;
  const machinePage = useSelector(siteDetailsSelectors.selectMachinePage);
  const machinePageSize = useSelector(siteDetailsSelectors.selectMachinePageSize);

  const onPageChange = (value: number): void => {
    dispatch(SiteDetailsPanelActions.changeTablePage({ page: value }));
  };

  const handleClickTableRow = (machine: Machine): void => {
    analyticsLinkActivated({
      linkName: AnalyticsLink.MACHINE_LIST_OPEN_DETAILS,
    });

    navigate(RoutePaths.MACHINE_DETAILS.replace(':id', machine.id));
    dispatch(DrawersActions.hideSiteDetailsDrawer());
  };

  const handleUnassignModal = (machine: Machine): void => {
    dispatch(SiteModalsActions.showUnassignMachinesModal(machine));
  };

  const hasPermissionSiteMachineUpdate = useMemo(
    () => isUserPermitted(userPermissions, [Permission.Site.Machine.UPDATE]),
    [userPermissions]
  );

  const tableColumns = getSiteDetailsMachineListColumns({
    t,
    i18n,
    handleUnassignModal,
    hasPermissionSiteMachineUpdate,
  });

  return (
    <StyledSiteDetailsAssignedMachinesList className="site-details__machine-list-table-wrapper">
      <Row justify="space-between" align="middle" className="site-details__machine-list-table-head">
        <Col>
          <h3 tabIndex={0} className="site-details__sub-header">
            {t('common.assignedMachines')}
          </h3>
        </Col>
      </Row>
      <Table
        dataSource={machineData}
        loading={isMachineLoading}
        className="site-details__machine-list-table"
        columns={tableColumns}
        rowKey="id"
        onRow={(machineRowData: Machine): { onClick(): void } => ({
          onClick: (): void => {
            handleClickTableRow(machineRowData);
          },
        })}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={t('siteDetails.machineList.table.noMachinesFound')}
            />
          ),
        }}
        pagination={
          machineTotalCount > 10
            ? {
                total: machineTotalCount,
                pageSize: machinePageSize,
                page: machinePage,
                onPageChange,
                isLoading: isMachineLoading,
              }
            : undefined
        }
      />
      <PermissionGuard requiredPermissions={[Permission.Site.Machine.UPDATE]}>
        <div className="site-details__assign-btn-row">
          <SecondaryButton
            size="s"
            onClick={(): IShowAssignMachinesModalAction => dispatch(SiteModalsActions.showAssignMachinesModal())}
            className="site-details__assign-button"
            key="site-details__assign-button"
          >
            {t('siteDetails.assignNewMachine')}
          </SecondaryButton>
        </div>
      </PermissionGuard>
    </StyledSiteDetailsAssignedMachinesList>
  );
};
