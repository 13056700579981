import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useAnalyticsLinkActivated } from '../../../../../cross-cutting-concerns/analytics/hooks/useAnalyticsLinkActivated';
import { useAnalyticsSetForm } from '../../../../../cross-cutting-concerns/analytics/hooks/useAnalyticsSetForm';
import {
  AnalyticsForm,
  AnalyticsLink,
} from '../../../../../cross-cutting-concerns/analytics/interfaces/Analytics.types';
import { Site } from '../../../../../cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { SiteDetailsPanelActions } from '../../../site-details-panel/state/siteDetailsPanelActions';
import { SiteModalsActions } from '../../state/siteModalsActions';
import { selectIsDeleteSiteModalLoading, selectIsDeleteSiteModalVisible } from '../../state/siteModalsSelectors';
import { StyledDeleteSiteModal } from './DeleteSiteModal.styles';
import { TextButton } from 'lib/components/Button/TextButton/TextButton';
import { PrimaryButton } from 'lib/components/Button/PrimaryButton/PrimaryButton';

export interface DeleteSiteModalProps {
  site: Site;
}

export const DeleteSiteModal = ({ site }: DeleteSiteModalProps): JSX.Element => {
  const analyticsLinkActivated = useAnalyticsLinkActivated();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isVisible = useSelector(selectIsDeleteSiteModalVisible);
  const isLoading = useSelector(selectIsDeleteSiteModalLoading);

  const handleCancel = useCallback(() => dispatch(SiteModalsActions.hideDeleteSiteModal()), [dispatch]);

  const handleOk = useCallback(() => {
    analyticsLinkActivated({
      linkName: AnalyticsLink.DELETE_SITE,
    });

    dispatch(SiteDetailsPanelActions.deleteSiteRequest({ id: site.id }));
  }, [analyticsLinkActivated, dispatch, site.id]);

  useAnalyticsSetForm({
    name: AnalyticsForm.DELETE_SITE,
    fields: {
      site,
    },
    isVisible,
  });

  return (
    <StyledDeleteSiteModal
      className="delete-site-modal"
      title={t('siteDetails.deleteSiteModal.deleteSite')}
      open={isVisible}
      footer={[
        <TextButton size="m" onClick={handleCancel} className="cancel-button" key="cancel-button">
          {t('common.cancel')}
        </TextButton>,
        <PrimaryButton
          size="m"
          key="submit"
          className="submit-button"
          type="primary"
          loading={isLoading}
          onClick={handleOk}
        >
          {t('siteDetails.deleteSiteModal.deleteSite')}
        </PrimaryButton>,
      ]}
    >
      <p className="modal__body">{t('siteDetails.deleteSiteModal.modalBody')}</p>
      <h3 className="site-name">{site?.name}</h3>
    </StyledDeleteSiteModal>
  );
};
