import styled from 'styled-components';

export const StyledCleaningPlanEvent = styled.div`
  &.rbc-event {
    border: 1px solid ${(props): string => props.theme.colors.brightGrey};
    background-color: ${(props): string => props.theme.colors.celadonGreen};
    border-radius: 0;
    z-index: 2;
    display: block;
    padding: 5px;
  }

  .cleaning-plan-event__event-name {
    font-family: ${(props): string => props.theme.fonts.captionMedium};
    font-size: ${(props): string => props.theme.fontSizes.caption};
    line-height: ${(props): string => props.theme.lineHeights.caption};
    font-weight: ${(props): number => props.theme.fontWeights.captionMedium};
    color: ${(props): string => props.theme.colors.white};
    margin-bottom: 5px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    line-height: 14px;
    max-height: 28px;

    &--single-line {
      -webkit-line-clamp: 1;
      max-height: 14px;
    }
  }

  .cleaning-plan-event__time-span {
    font-family: ${(props): string => props.theme.fonts.captionNews};
    font-size: ${(props): string => props.theme.fontSizes.caption};
    line-height: ${(props): string => props.theme.lineHeights.caption};
    font-weight: ${(props): number => props.theme.fontWeights.captionNews};
    color: ${(props): string => props.theme.colors.white};
    opacity: 70%;
    line-height: 14px;
    margin: 0;
    ${(props): string => props.theme.mixins.truncateText}
  }
`;
