import { ColumnsType } from 'antd/lib/table';
import { TFunction } from 'react-i18next';
import { Tags } from '../../../../../../../lib/components/Tags/Tags';
import { Site } from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';

export const getSiteListColumns = ({ t }: { t: TFunction }): ColumnsType<Site> => [
  {
    title: t('siteList.table.name'),
    dataIndex: 'name',
    width: 322,
    render: (name: string): JSX.Element => <>{name || '-'}</>,
    sorter: true,
    key: 'name',
    defaultSortOrder: 'ascend',
    fixed: true,
  },
  {
    title: t('siteList.table.address'),
    dataIndex: ['location', 'address'],
    width: 322,
    render: (address: string): JSX.Element => <>{address || '-'}</>,
    key: 'location',
  },
  {
    title: t('siteList.table.assignedMachines'),
    dataIndex: 'numberMachinesAssigned',
    width: 322,
  },
  {
    title: t('siteList.table.tags'),
    dataIndex: 'tags',
    width: 322,
    render: (tags: string[]): JSX.Element => <Tags tags={tags} />,
  },
];
