import classnames from 'classnames';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import debounce from 'lodash-es/debounce';
import { SiteModalsActions } from '../../state/siteModalsActions';
import * as siteModalsSelectors from '../../state/siteModalsSelectors';
import { StyledAddressesSuggestionAutoComplete } from './AddressesSuggestionAutoComplete.styles';
import { AddressesSuggestionAutoCompleteGlobalStyles } from './AddressesSuggestionAutoComplete.global.styles';
import { AutoCompleteProps } from 'lib/components/AutoComplete/AutoComplete';
import { AutoCompleteInput } from 'lib/components/AutoCompleteInput/AutoCompleteInput';
import { DEFAULT_ADDRESSES_SUGGESTION_LIMIT_VALUE } from 'config/constants';

interface SearchAddressItem {
  addressText: string;
  searchingAddressValue: string;
}
const SearchAddressItem = (props: SearchAddressItem): JSX.Element => {
  const { addressText, searchingAddressValue } = props;
  const regex = new RegExp(searchingAddressValue, 'gi');
  const arrMatchText = addressText.match(regex);
  const matchWords: string = arrMatchText?.[0] || '';
  const leftWords = addressText.slice(matchWords?.length);
  return (
    <p className="auto-complete__dropdown-text">
      <span className="auto-complete__words-match">{matchWords}</span>
      <span className="auto-complete__words-not-match">{leftWords}</span>
    </p>
  );
};

export type AddressesSuggestionAutoCompleteProps = AutoCompleteProps & {
  placeholder?: string;
};

export const AddressesSuggestionAutoComplete = (props: AddressesSuggestionAutoCompleteProps): JSX.Element => {
  const { className = '', placeholder, onChange, value, ...rest } = props;
  const siteLocationAutocompleteClassName = classnames('addresses-suggestion-auto-complete', className);
  const dispatch = useDispatch();

  const searchingAddressValue = useSelector(siteModalsSelectors.selectSearchingAddressValue);
  const addressesSuggestionData = useSelector(siteModalsSelectors.selectAddressesSuggestion);

  const calculatedAutoCompleteOptions = useMemo(() => {
    if (!searchingAddressValue) {
      return [];
    }

    const { isLoading, addresses } = addressesSuggestionData[searchingAddressValue];
    if (isLoading || !addresses) {
      return [];
    }
    return addresses.map(addressText => ({
      value: addressText,
      label: <SearchAddressItem addressText={addressText} searchingAddressValue={searchingAddressValue} />,
    }));
  }, [addressesSuggestionData, searchingAddressValue]);

  const calculatedIsLoading = useMemo(() => {
    if (!searchingAddressValue) {
      return false;
    }

    const { isLoading } = addressesSuggestionData[searchingAddressValue];
    return isLoading;
  }, [addressesSuggestionData, searchingAddressValue]);

  const handleSelect = (searchValue: string): void => {
    if (onChange) {
      onChange(searchValue, { value: searchValue, label: searchValue });
    }
  };

  const handleSearch = debounce((searchValue: string): void => {
    const text = searchValue.trim();

    // Use caching value for saving money as AWS costs per API request
    if (!text || addressesSuggestionData[text]?.addresses) {
      if (!text) {
        handleSelect('');
      }

      dispatch(SiteModalsActions.setSearchingAddressValue(text));
      return;
    }

    // Fetch new one
    dispatch(
      SiteModalsActions.getAddressesSuggestionRequest({
        filter: {
          text,
        },
        paginationOptions: {
          limit: DEFAULT_ADDRESSES_SUGGESTION_LIMIT_VALUE,
        },
      })
    );
  }, 500);

  return (
    <>
      <AddressesSuggestionAutoCompleteGlobalStyles />
      <StyledAddressesSuggestionAutoComplete
        {...rest}
        allowClear
        defaultValue={value}
        options={calculatedAutoCompleteOptions}
        className={siteLocationAutocompleteClassName}
        onSearch={handleSearch}
        onSelect={handleSelect}
      >
        <AutoCompleteInput
          placeholder={placeholder}
          className="auto-complete__custom-input"
          prefix={<SearchOutlined />}
          suffix={calculatedIsLoading && <LoadingOutlined />}
        />
      </StyledAddressesSuggestionAutoComplete>
    </>
  );
};
