import styled from 'styled-components';

export const StyledSiteMap = styled.div`
  height: 100%;
  min-height: 300px;
  position: relative;

  .site-map__map {
    height: 100%;
  }

  .site-map__change-geofence-btn {
    position: absolute;
    right: 10px;
    bottom: 22px;
  }
`;
