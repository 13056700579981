import { IState } from 'app/cross-cutting-concerns/state-management/interfaces/IState';
import { SiteWithOperatingTime } from 'app/modules/site-management/interfaces/Site.types';
import { Optional } from 'lib/types/Optional';

export class SiteWidgetsFleetDashboardSelectors {
  public static selectData = (state: IState): Optional<SiteWithOperatingTime[]> =>
    state.modules['site-management'].widgets['fleet-dashboard'].data;

  public static selectIsLoading = (state: IState): Optional<boolean> =>
    state.modules['site-management'].widgets['fleet-dashboard'].isLoading;
}
