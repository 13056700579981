import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RuleObject } from 'antd/es/form';
import { Form } from 'antd';
import { debounce } from 'lodash-es';
import { AddressesSuggestionAutoComplete } from '../AddressesSuggestionAutoComplete/AddressesSuggestionAutoComplete';
import { SiteModalsActions } from '../../state/siteModalsActions';
import {
  selectEditSiteTagsModalTagsData,
  selectEditSiteTagsModalTagsIsLoading,
  selectIsEditSiteInfoModalLoading,
  selectIsEditSiteInfoModalVisible,
} from '../../state/siteModalsSelectors';
import { StyledEditModalInfo } from './EditSiteInfoModal.styles';
import { PrimaryButton } from 'lib/components/Button/PrimaryButton/PrimaryButton';
import { Input } from 'lib/components/Input/Input';
import { selectData } from 'app/modules/site-management/site-details-panel/state/siteDetailsPanelSelectors';
import { TAGS_MAX_SIZE, TAGS_SEARCH_DELAY_IN_MS, TAGS_SEARCH_LIMIT } from 'config/constants';
import { SiteDetailsPanelActions } from 'app/modules/site-management/site-details-panel/state/siteDetailsPanelActions';
import { PopConfirm } from 'lib/components/PopConfirm/PopConfirm';
import { Select } from 'lib/components/Select/Select';
import { TextButton } from 'lib/components/Button/TextButton/TextButton';

export const EditSiteInfoModal = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [formInstance] = Form.useForm();

  const site = useSelector(selectData);
  const initialNameValue = site?.name || '';
  const initialAddressValue = site?.location?.address || '';
  const initialTagsValues = site?.tags || [];

  const isVisible = useSelector(selectIsEditSiteInfoModalVisible);
  const isLoading = useSelector(selectIsEditSiteInfoModalLoading);
  const isTagsLoading = useSelector(selectEditSiteTagsModalTagsIsLoading);
  const tagsSearchResult = useSelector(selectEditSiteTagsModalTagsData);

  const [isOpenPop, setIsPopOpen] = useState(false);
  const [isTagsOpen, setIsTagsOpen] = useState(false);
  const [selectedTags, setSelectedTags] = useState<string[] | undefined>(initialTagsValues);

  const handleCancel = useCallback(() => {
    const isFieldsTouched = formInstance.isFieldsTouched();

    if (isFieldsTouched) {
      setIsPopOpen(true);
    } else {
      dispatch(SiteModalsActions.hideEditSiteInfoModal());
      formInstance.resetFields();
    }
  }, [dispatch, formInstance]);

  const handleOk = useCallback(() => {
    formInstance.submit();
  }, [formInstance]);

  const handleSearch = debounce((searchValue: string): void => {
    dispatch(
      SiteModalsActions.listTagsRequest({
        filter: {
          textBegin: searchValue.trim(),
        },
        paginationOptions: {
          limit: TAGS_SEARCH_LIMIT,
        },
      })
    );
  }, TAGS_SEARCH_DELAY_IN_MS);

  const onFinish = useCallback(
    (values: { name: string; address: string; tags: string[] }) => {
      dispatch(
        SiteDetailsPanelActions.editSiteInfoRequest({
          id: site?.id || '',
          input: {
            inputSite: {
              name: values.name,
              location: {
                address: values.address,
              },
            },
            inputTag: {
              tags: values.tags,
            },
          },
        })
      );
    },
    [dispatch, site?.id]
  );

  const handleValuesChange = useCallback((): void => {
    setSelectedTags(formInstance.getFieldsValue().tags);
  }, [formInstance]);

  const onPopConfirm = (): void => {
    setIsPopOpen(false);
    dispatch(SiteModalsActions.hideEditSiteInfoModal());
    formInstance.resetFields();
  };
  const onPopCancel = (): void => setIsPopOpen(false);

  const checkEmptyName = (): RuleObject => ({
    validator(_, value: RuleObject): Promise<any> {
      if (!value || !formInstance.getFieldValue('name')) {
        return Promise.reject(new Error(t('siteModals.editSiteInfo.form.errors.emptySiteName')));
      }
      return Promise.resolve();
    },
  });

  useEffect(
    () => () => {
      formInstance.resetFields();
    },
    [formInstance]
  );

  useEffect(() => {
    if (!isVisible) {
      dispatch(SiteModalsActions.createSiteModalResetState());
      dispatch(SiteModalsActions.setSearchingAddressValue());
      formInstance.resetFields();
    }
  }, [isVisible, formInstance, dispatch]);

  return (
    <StyledEditModalInfo
      className="edit-site-info-modal"
      title={t('siteModals.editSiteInfo.title')}
      open={isVisible}
      width={520}
      destroyOnClose
      footer={[
        <PopConfirm
          key="cancel-button"
          title={t('common.discarding.discardingChanges').toUpperCase()}
          description={t('common.discarding.description')}
          cancelText={t('common.noThanks')}
          okText={t('common.discarding.yesDiscard')}
          open={isOpenPop}
          onConfirm={onPopConfirm}
          onCancel={onPopCancel}
        >
          <TextButton size="m" onClick={handleCancel} className="cancel-button">
            {t('common.discardChanges')}
          </TextButton>
        </PopConfirm>,
        <PrimaryButton
          size="m"
          key="submit"
          className="submit-button"
          type="primary"
          onClick={handleOk}
          loading={isLoading}
        >
          {t('common.saveAdjustments')}
        </PrimaryButton>,
      ]}
    >
      <Form
        form={formInstance}
        className="edit-site-info-modal__form"
        name="edit-site-info-modal__form"
        layout="vertical"
        preserve={false}
        initialValues={{
          name: initialNameValue,
          address: initialAddressValue,
          tags: initialTagsValues,
        }}
        onValuesChange={handleValuesChange}
        onFinish={onFinish}
      >
        <Form.Item
          name="name"
          required
          label={t('siteModals.editSiteInfo.form.label.siteName')}
          rules={[checkEmptyName()]}
        >
          <Input />
        </Form.Item>

        <Form.Item name="address" label={t('siteModals.editSiteInfo.form.label.address')}>
          <AddressesSuggestionAutoComplete />
        </Form.Item>

        <Form.Item
          name="tags"
          label={t('siteModals.editSiteInfo.form.label.tags')}
          rules={[
            {
              type: 'array',
              max: TAGS_MAX_SIZE,
              message: t('siteModals.editSiteInfo.form.errors.maxTagSize', { size: TAGS_MAX_SIZE }),
            },
          ]}
        >
          <Select
            onSearch={handleSearch}
            onReset={(): void => {
              handleSearch('');
            }}
            loading={isTagsLoading}
            options={[...new Set(initialTagsValues.concat(tagsSearchResult))].map(tag => ({
              label: tag,
              value: tag,
            }))}
            onDropdownVisibleChange={(isOpen: boolean): void => setIsTagsOpen(isOpen)}
            dropdownVisibleState={isTagsOpen}
            value={selectedTags}
            placeholder={t('machineModals.editMachineInfo.form.placeholder.default')}
            mode="tags"
          />
        </Form.Item>
      </Form>
    </StyledEditModalInfo>
  );
};
