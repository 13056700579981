import { SelectEffect, CallEffect, ForkEffect, GetContextEffect, PutEffect, JoinEffect } from 'redux-saga/effects';
import { getContext, call, put, takeLatest, select, retry, SagaGenerator, fork, join } from 'typed-redux-saga';
import { RoutePaths } from '../../../../../config/route-paths';
import { Site } from '../../../../cross-cutting-concerns/communication/interfaces/am-api-graphql';
import {
  IDeleteSiteModalIsLoadingAction,
  IDeleteSiteModalIsNotLoadingAction,
  IHideDeleteSiteModalAction,
  IHideEditSiteNameModalAction,
  IHideAssignMachinesModalAction,
  SiteModalsActions,
  IEditSiteNameModalIsLoadingAction,
  IEditSiteNameModalIsNotLoadingAction,
  IEditSiteLocationModalIsLoadingAction,
  IEditSiteLocationModalIsNotLoadingAction,
  IHideEditSiteLocationModalAction,
  IHideAssignSiteManagerModalAction,
  IHideUnassignSiteManagerModalAction,
  IEditSiteTagsModalIsLoadingAction,
  IEditSiteTagsModalIsNotLoadingAction,
  IHideEditSiteTagsModalAction,
} from '../../modals/state/siteModalsActions';
import {
  SiteDelete,
  SiteAssignMachineResponse,
  SiteDetails,
  SiteUnassignMachineResponse,
  SiteUpdateResponse,
  SiteAddressCoordinateResponse,
  SiteGeofenceSetupResponse,
  SiteAssignManagersResponse,
  SiteUnassignManagerResponse,
  SiteMachineVariantData,
  SiteTagsUpdateResponse,
} from '../../interfaces/Site.types';
import {
  IAssignMachineToSiteErrorAction,
  IAssignMachineToSiteRequestAction,
  IAssignMachineToSiteSuccessAction,
  IDeleteSiteErrorAction,
  IDeleteSiteRequestAction,
  IDeleteSiteSuccessAction,
  IGetSiteDetailsErrorAction,
  IGetSiteDetailsRequestAction,
  IGetSiteDetailsSuccessAction,
  IUpdateSiteErrorAction,
  IUpdateSiteRequestAction,
  IUpdateSiteSuccessAction,
  IUnassignMachineFromSiteErrorAction,
  IUnassignMachineFromSiteRequestAction,
  IUnassignMachineFromSiteSuccessAction,
  SiteDetailsActions,
  IUpdateSiteLocationErrorAction,
  IUpdateSiteLocationRequestAction,
  IUpdateSiteLocationSuccessAction,
  ISetGeofenceRequestAction,
  IPollSiteDetailsWithGeofenceAction,
  ISiteDetailsMapIsLoadingAction,
  ISiteDetailsMapIsNotLoadingAction,
  ISetGeofenceAfterSiteLocationIsInitializedAction,
  ISetGeofenceAfterSiteLocationIsInitializedOptions,
  IAssignManagersToSiteSuccessAction,
  IAssignManagersToSiteRequestAction,
  IAssignManagersToSiteErrorAction,
  IUnassignManagerFromSiteRequestAction,
  IUnassignManagerFromSiteErrorAction,
  IUnassignManagerFromSiteSuccessAction,
  IGetSiteDetailsMachinePicturesSuccessAction,
  IUpdateSiteTagsErrorAction,
  IUpdateSiteTagsSuccessAction,
  IUpdateSiteTagsRequestAction,
} from './siteDetailsActions';
import * as siteDetailsSelectors from './siteDetailsSelectors';
import { IDependencies } from 'app/cross-cutting-concerns/dependency-injection/interfaces/IDependencies';
import { Optional } from 'lib/types/Optional';
import { POLL_INTERVAL, POLL_MAX_RETRIES } from 'config/constants';
import { GEOFENCE_DEFAULT_RADIUS } from 'app/components/Map/Map';

export function* getSiteDetailsSaga(
  action: IGetSiteDetailsRequestAction
): Generator<
  | GetContextEffect
  | ForkEffect<SiteDetails>
  | ForkEffect<SiteMachineVariantData>
  | JoinEffect
  | PutEffect<IGetSiteDetailsSuccessAction>
  | PutEffect<IGetSiteDetailsMachinePicturesSuccessAction>
  | PutEffect<IGetSiteDetailsErrorAction>,
  void,
  IDependencies
> {
  const { siteService } = yield* getContext<IDependencies>('dependencies');

  try {
    const siteResponseTask = yield* fork(siteService.get, action.payload);
    const siteMachinePicturesResponseTask = yield* fork(siteService.getMachinePictures, action.payload);

    const siteResponse = yield* join(siteResponseTask);
    yield* put(SiteDetailsActions.getSiteDetailsSuccess(siteResponse));

    const siteMachinePicturesResponse = yield* join(siteMachinePicturesResponseTask);
    yield* put(SiteDetailsActions.getSiteDetailsMachinePicturesSuccess(siteMachinePicturesResponse));
  } catch (error) {
    console.error(error);

    yield* put(
      SiteDetailsActions.getSiteDetailsError({
        error,
      })
    );
  }
}

export function* updateSiteSaga(
  action: IUpdateSiteRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<Optional<SiteUpdateResponse>>
  | PutEffect<IUpdateSiteSuccessAction>
  | PutEffect<IUpdateSiteErrorAction>
  | PutEffect<IEditSiteNameModalIsLoadingAction>
  | PutEffect<IEditSiteNameModalIsNotLoadingAction>
  | PutEffect<IHideEditSiteNameModalAction>,
  void,
  IDependencies
> {
  const { id, input } = action.payload;
  const { siteService } = yield* getContext<IDependencies>('dependencies');

  try {
    yield* put(SiteModalsActions.editSiteNameModalIsLoading());
    const response = yield* call(siteService.update, { id, input });

    yield* put(SiteDetailsActions.updateSiteSuccess(response));

    yield* put(SiteModalsActions.editSiteNameModalIsNotLoading());
    yield* put(SiteModalsActions.hideEditSiteNameModal());
  } catch (error) {
    console.error(error);

    yield* put(
      SiteDetailsActions.updateSiteError({
        error,
      })
    );

    yield* put(SiteModalsActions.editSiteNameModalIsNotLoading());
    yield* put(SiteModalsActions.hideEditSiteNameModal());
  }
}

export function* updateSiteLocationSaga(
  action: IUpdateSiteLocationRequestAction
): Generator<
  | GetContextEffect
  | SelectEffect
  | CallEffect<Optional<SiteUpdateResponse>>
  | CallEffect<Optional<SiteAddressCoordinateResponse>>
  | PutEffect<IUpdateSiteLocationSuccessAction>
  | PutEffect<IUpdateSiteLocationErrorAction>
  | PutEffect<IEditSiteLocationModalIsLoadingAction>
  | PutEffect<IEditSiteLocationModalIsNotLoadingAction>
  | PutEffect<IHideEditSiteLocationModalAction>,
  void,
  IDependencies
> {
  const site: Optional<Site> = yield* select(siteDetailsSelectors.selectData);
  const originalGeofenceRadius: number = site?.geofence?.radius ?? GEOFENCE_DEFAULT_RADIUS;
  const { id, input, currentSiteAddress } = action.payload;
  const { siteService } = yield* getContext<IDependencies>('dependencies');

  try {
    // just close modal if address has not changed
    if (currentSiteAddress === input.location.address) {
      return;
    }
    yield* put(SiteModalsActions.editSiteLocationModalIsLoading());
    const coordinate: {
      latitude: number | undefined;
      longitude: number | undefined;
    } = {
      latitude: undefined,
      longitude: undefined,
    };

    if (input.location.address) {
      const filter = { text: input.location.address };

      const getAddressCoordinateRes = yield* call(siteService.getAddressCoordinate, { filter });
      coordinate.latitude = getAddressCoordinateRes.siteAddressCoordinate.data?.latitude;
      coordinate.longitude = getAddressCoordinateRes.siteAddressCoordinate.data?.longitude;

      if (!coordinate?.latitude || !coordinate?.longitude) {
        throw new Error('can not get coordinate');
      }

      const setGeofenceOptions: ISetGeofenceAfterSiteLocationIsInitializedOptions = {
        siteId: id,
        geofence: {
          circle: {
            radius: originalGeofenceRadius,
            centerPoint: {
              latitude: coordinate.latitude,
              longitude: coordinate.longitude,
            },
          },
        },
      };

      yield* put(SiteDetailsActions.setGeofenceAfterSiteLocationIsInitialized(setGeofenceOptions));
    }

    const responseUpdate = yield* call(siteService.update, {
      id,
      input: {
        location: {
          address: input.location.address,
          ...coordinate,
        },
      },
    });
    yield* put(SiteDetailsActions.updateSiteLocationSuccess(responseUpdate));
  } catch (error) {
    console.error(error);

    yield* put(
      SiteDetailsActions.updateSiteLocationError({
        error,
      })
    );
  } finally {
    yield* put(SiteModalsActions.hideEditSiteLocationModal());
    yield* put(SiteModalsActions.editSiteLocationModalIsNotLoading());
  }
}

export function* updateSiteTagsSaga(
  action: IUpdateSiteTagsRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<Optional<SiteTagsUpdateResponse>>
  | PutEffect<IUpdateSiteTagsSuccessAction>
  | PutEffect<IUpdateSiteTagsErrorAction>
  | PutEffect<IEditSiteTagsModalIsLoadingAction>
  | PutEffect<IEditSiteTagsModalIsNotLoadingAction>
  | PutEffect<IHideEditSiteTagsModalAction>,
  void,
  IDependencies
> {
  const { id, input } = action.payload;
  const { siteService } = yield* getContext<IDependencies>('dependencies');

  try {
    yield* put(SiteModalsActions.editSiteTagsModalIsLoading());
    const response = yield* call(siteService.updateSiteTags, { id, input });

    yield* put(SiteDetailsActions.updateSiteTagsSuccess(response));

    yield* put(SiteModalsActions.editSiteTagsModalIsNotLoading());
    yield* put(SiteModalsActions.hideEditSiteTagsModal());
  } catch (error) {
    console.error(error);

    yield* put(
      SiteDetailsActions.updateSiteTagsError({
        error,
      })
    );

    yield* put(SiteModalsActions.editSiteTagsModalIsNotLoading());
    yield* put(SiteModalsActions.hideEditSiteTagsModal());
  }
}

export function* assignMachineToSiteSaga(
  action: IAssignMachineToSiteRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<Optional<SiteAssignMachineResponse>>
  | SelectEffect
  | PutEffect<IAssignMachineToSiteSuccessAction>
  | PutEffect<IAssignMachineToSiteErrorAction>
  | PutEffect<IHideAssignMachinesModalAction>,
  void,
  IDependencies
> {
  const { siteId, machineIds } = action.payload;
  const { siteService } = yield* getContext<IDependencies>('dependencies');

  try {
    yield* put(SiteModalsActions.assignMachinesModalIsLoading());
    const response = yield* call(siteService.assignMachine, { siteId, machineIds });

    yield* put(SiteDetailsActions.assignMachineToSiteSuccess(response));

    const page = yield* select(siteDetailsSelectors.selectMachinePage);
    const pageSize = yield* select(siteDetailsSelectors.selectMachinePageSize);
    const paginationTokens = yield* select(siteDetailsSelectors.selectMachinePaginationTokens);

    yield* put(
      SiteDetailsActions.getSiteDetailsRequest({
        id: siteId,
        machinePaginationOptions: {
          paginationToken: paginationTokens[page],
          limit: pageSize,
        },
      })
    );

    yield* put(SiteModalsActions.assignMachinesModalIsNotLoading());
    yield* put(SiteModalsActions.hideAssignMachinesModal());
  } catch (error) {
    console.error(error);

    yield* put(
      SiteDetailsActions.assignMachineToSiteError({
        error,
      })
    );

    yield* put(SiteModalsActions.assignMachinesModalIsNotLoading());
    yield* put(SiteModalsActions.hideAssignMachinesModal());
  }
}

export function* unassignMachineFromSiteSaga(
  action: IUnassignMachineFromSiteRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<Optional<SiteUnassignMachineResponse>>
  | SelectEffect
  | PutEffect<IUnassignMachineFromSiteSuccessAction>
  | PutEffect<IUnassignMachineFromSiteErrorAction>
  | PutEffect<IHideAssignMachinesModalAction>,
  void,
  IDependencies
> {
  const { siteId, machineId } = action.payload;
  const { siteService } = yield* getContext<IDependencies>('dependencies');

  try {
    yield* put(SiteModalsActions.unassignMachinesModalIsLoading());
    const response = yield* call(siteService.unassignMachine, { siteId, machineId });

    yield* put(SiteDetailsActions.unassignMachineFromSiteSuccess(response));

    const page = yield* select(siteDetailsSelectors.selectMachinePage);
    const pageSize = yield* select(siteDetailsSelectors.selectMachinePageSize);
    const paginationTokens = yield* select(siteDetailsSelectors.selectMachinePaginationTokens);

    yield* put(
      SiteDetailsActions.getSiteDetailsRequest({
        id: siteId,
        machinePaginationOptions: {
          paginationToken: paginationTokens[page],
          limit: pageSize,
        },
      })
    );

    yield* put(SiteModalsActions.unassignMachinesModalIsNotLoading());
    yield* put(SiteModalsActions.hideUnassignMachinesModal());
  } catch (error) {
    console.error(error);

    yield* put(
      SiteDetailsActions.unassignMachineFromSiteError({
        error,
      })
    );

    yield* put(SiteModalsActions.unassignMachinesModalIsNotLoading());
    yield* put(SiteModalsActions.hideUnassignMachinesModal());
  }
}

export function* deleteSiteSaga(
  action: IDeleteSiteRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<Optional<SiteDelete>>
  | PutEffect<IDeleteSiteSuccessAction>
  | PutEffect<IDeleteSiteErrorAction>
  | PutEffect<IDeleteSiteModalIsLoadingAction>
  | PutEffect<IDeleteSiteModalIsNotLoadingAction>
  | PutEffect<IHideDeleteSiteModalAction>,
  void,
  IDependencies
> {
  const { id } = action.payload;
  const { history, siteService } = yield* getContext<IDependencies>('dependencies');

  try {
    yield* put(SiteModalsActions.deleteSiteModalIsLoading());
    const response = yield* call(siteService.delete, { id });

    yield* put(SiteDetailsActions.deleteSiteSuccess(response));

    yield* put(SiteModalsActions.deleteSiteModalIsNotLoading());

    yield* put(SiteModalsActions.hideDeleteSiteModal());

    history.push(RoutePaths.SITES);
  } catch (error) {
    console.error(error);

    yield* put(
      SiteDetailsActions.deleteSiteError({
        error,
      })
    );

    yield* put(SiteModalsActions.deleteSiteModalIsNotLoading());
    yield* put(SiteModalsActions.hideDeleteSiteModal());
  }
}

export function* setSiteGeofenceSaga(
  action: ISetGeofenceRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<Optional<SiteGeofenceSetupResponse>>
  | SelectEffect
  | PutEffect<IDeleteSiteSuccessAction>
  | PutEffect<IDeleteSiteErrorAction>
  | PutEffect<IDeleteSiteModalIsLoadingAction>
  | PutEffect<IDeleteSiteModalIsNotLoadingAction>
  | PutEffect<IHideDeleteSiteModalAction>,
  void,
  IDependencies
> {
  const { siteId, geofence } = action.payload;
  const { siteService } = yield* getContext<IDependencies>('dependencies');

  try {
    yield* put(SiteModalsActions.changeGeofenceModalIsLoading());
    const response = yield* call(siteService.setGeofence, { siteId, geofence });

    yield* put(SiteDetailsActions.setGeofenceSuccess(response));

    const page = yield* select(siteDetailsSelectors.selectMachinePage);
    const pageSize = yield* select(siteDetailsSelectors.selectMachinePageSize);
    const paginationTokens = yield* select(siteDetailsSelectors.selectMachinePaginationTokens);

    yield* put(
      SiteDetailsActions.pollSiteDetailsWithGeofence({
        id: siteId,
        machinePaginationOptions: {
          paginationToken: paginationTokens[page],
          limit: pageSize,
        },
        geofence,
      })
    );

    yield* put(SiteModalsActions.changeGeofenceModalIsNotLoading());
    yield* put(SiteModalsActions.hideChangeGeofenceModal());
  } catch (error) {
    console.error(error);

    yield* put(
      SiteDetailsActions.setGeofenceError({
        error,
      })
    );

    yield* put(SiteModalsActions.changeGeofenceModalIsNotLoading());
    yield* put(SiteModalsActions.hideChangeGeofenceModal());
  }
}

export function* setGeofenceAfterSiteLocationIsInitializedSaga(
  action: ISetGeofenceAfterSiteLocationIsInitializedAction
): Generator<
  | GetContextEffect
  | CallEffect<Optional<SiteGeofenceSetupResponse>>
  | SelectEffect
  | PutEffect<IDeleteSiteSuccessAction>
  | PutEffect<IDeleteSiteErrorAction>
  | PutEffect<IDeleteSiteModalIsLoadingAction>
  | PutEffect<IDeleteSiteModalIsNotLoadingAction>
  | PutEffect<IHideDeleteSiteModalAction>,
  void,
  IDependencies
> {
  const { siteId, geofence } = action.payload;
  const { siteService } = yield* getContext<IDependencies>('dependencies');

  try {
    yield* call(siteService.setGeofence, { siteId, geofence });

    const page = yield* select(siteDetailsSelectors.selectMachinePage);
    const pageSize = yield* select(siteDetailsSelectors.selectMachinePageSize);
    const paginationTokens = yield* select(siteDetailsSelectors.selectMachinePaginationTokens);

    yield* put(
      SiteDetailsActions.pollSiteDetailsWithGeofence({
        id: siteId,
        machinePaginationOptions: {
          paginationToken: paginationTokens[page],
          limit: pageSize,
        },
        geofence,
      })
    );
  } catch (error) {
    console.error(error);
  }
}

export function* pollSiteDetailsWithGeofenceSaga(
  action: IPollSiteDetailsWithGeofenceAction
): Generator<
  | GetContextEffect
  | SagaGenerator<SiteDetails, CallEffect<SiteDetails>>
  | PutEffect<ISiteDetailsMapIsLoadingAction>
  | PutEffect<ISiteDetailsMapIsNotLoadingAction>
  | PutEffect<IGetSiteDetailsSuccessAction>
  | PutEffect<IGetSiteDetailsErrorAction>,
  void,
  any
> {
  const newGeofence = action.payload.geofence.circle;
  const { siteService } = yield* getContext<IDependencies>('dependencies');

  const getSiteDetailsWithUpdatedGeofence = async (): Promise<SiteDetails> => {
    const response = await siteService.get(action.payload);

    if (
      response?.site?.data?.geofence?.centerPoint.latitude !== newGeofence.centerPoint.latitude ||
      response?.site?.data?.geofence?.centerPoint.longitude !== newGeofence.centerPoint.longitude ||
      response?.site?.data?.geofence?.radius !== newGeofence.radius
    ) {
      throw new Error('Geofence not updated');
    }

    return response;
  };

  try {
    yield* put(SiteDetailsActions.mapIsLoading());
    const response: SiteDetails = yield retry(
      POLL_MAX_RETRIES,
      POLL_INTERVAL + 5000,
      getSiteDetailsWithUpdatedGeofence
    );
    yield* put(SiteDetailsActions.getSiteDetailsSuccess(response));
    yield* put(SiteDetailsActions.mapIsNotLoading());
  } catch (error) {
    console.error(error);

    yield* put(
      SiteDetailsActions.getSiteDetailsError({
        error,
      })
    );
    yield* put(SiteDetailsActions.mapIsNotLoading());
  }
}

export function* assignManagersToSiteSaga(
  action: IAssignManagersToSiteRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<Optional<SiteAssignManagersResponse>>
  | SelectEffect
  | PutEffect<IAssignManagersToSiteSuccessAction>
  | PutEffect<IAssignManagersToSiteErrorAction>
  | PutEffect<IHideAssignSiteManagerModalAction>,
  void,
  IDependencies
> {
  const { siteId, userIds } = action.payload;
  const { siteService } = yield* getContext<IDependencies>('dependencies');

  try {
    yield* put(SiteModalsActions.assignSiteManagerModalIsLoading());
    const response = yield* call(siteService.assignManagers, { siteId, userIds });

    yield* put(SiteDetailsActions.assignManagersToSiteSuccess(response));

    const page = yield* select(siteDetailsSelectors.selectMachinePage);
    const pageSize = yield* select(siteDetailsSelectors.selectMachinePageSize);
    const paginationTokens = yield* select(siteDetailsSelectors.selectMachinePaginationTokens);

    yield* put(
      SiteDetailsActions.getSiteDetailsRequest({
        id: siteId,
        machinePaginationOptions: {
          paginationToken: paginationTokens[page],
          limit: pageSize,
        },
      })
    );
  } catch (error) {
    console.error(error);

    yield* put(
      SiteDetailsActions.assignManagersToSiteError({
        error,
      })
    );
  } finally {
    yield* put(SiteModalsActions.assignSiteManagerModalIsNotLoading());
    yield* put(SiteModalsActions.hideAssignSiteManagerModal());
  }
}

export function* unassignManagerFromSiteSaga(
  action: IUnassignManagerFromSiteRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<Optional<SiteUnassignManagerResponse>>
  | SelectEffect
  | PutEffect<IUnassignManagerFromSiteSuccessAction>
  | PutEffect<IUnassignManagerFromSiteErrorAction>
  | PutEffect<IHideUnassignSiteManagerModalAction>,
  void,
  IDependencies
> {
  const { siteId, userId } = action.payload;
  const { siteService } = yield* getContext<IDependencies>('dependencies');

  try {
    yield* put(SiteModalsActions.unassignSiteManagerModalIsLoading());
    const response = yield* call(siteService.unassignManager, { siteId, userId });

    yield* put(SiteDetailsActions.unassignManagerFromSiteSuccess(response));

    const page = yield* select(siteDetailsSelectors.selectMachinePage);
    const pageSize = yield* select(siteDetailsSelectors.selectMachinePageSize);
    const paginationTokens = yield* select(siteDetailsSelectors.selectMachinePaginationTokens);

    yield* put(
      SiteDetailsActions.getSiteDetailsRequest({
        id: siteId,
        machinePaginationOptions: {
          paginationToken: paginationTokens[page],
          limit: pageSize,
        },
      })
    );
  } catch (error) {
    console.error(error);

    yield* put(
      SiteDetailsActions.unassignManagerFromSiteError({
        error,
      })
    );
  } finally {
    yield* put(SiteModalsActions.unassignSiteManagerModalIsNotLoading());
    yield* put(SiteModalsActions.hideUnassignSiteManagerModal());
  }
}

export function* siteDetailsSaga(): Generator<ForkEffect<never>, void> {
  yield* takeLatest(SiteDetailsActions.GET_SITE_DETAILS_REQUEST, getSiteDetailsSaga);
  yield* takeLatest(SiteDetailsActions.UPDATE_SITE_REQUEST, updateSiteSaga);
  yield* takeLatest(SiteDetailsActions.UPDATE_SITE_LOCATION_REQUEST, updateSiteLocationSaga);
  yield* takeLatest(SiteDetailsActions.UPDATE_SITE_TAGS_REQUEST, updateSiteTagsSaga);
  yield* takeLatest(SiteDetailsActions.DELETE_SITE_REQUEST, deleteSiteSaga);
  yield* takeLatest(SiteDetailsActions.ASSIGN_MACHINE_TO_SITE_REQUEST, assignMachineToSiteSaga);
  yield* takeLatest(SiteDetailsActions.UNASSIGN_MACHINE_FROM_SITE_REQUEST, unassignMachineFromSiteSaga);
  yield* takeLatest(SiteDetailsActions.ASSIGN_MANAGERS_TO_SITE_REQUEST, assignManagersToSiteSaga);
  yield* takeLatest(SiteDetailsActions.UNASSIGN_MANAGER_FROM_SITE_REQUEST, unassignManagerFromSiteSaga);
  yield* takeLatest(SiteDetailsActions.SET_GEOFENCE_REQUEST, setSiteGeofenceSaga);
  yield* takeLatest(SiteDetailsActions.POLL_SITE_DETAILS_WITH_GEOFENCE, pollSiteDetailsWithGeofenceSaga);
  yield* takeLatest(
    SiteDetailsActions.SET_GEOFENCE_AFTER_SITE_LOCATION_IS_INITIALIZED,
    setGeofenceAfterSiteLocationIsInitializedSaga
  );
}
