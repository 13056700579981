import { SiteCleaningStatisticDatum, SiteData, SiteMachinesDatum } from '../interfaces/Site.types';
import { MachineVariant, State } from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { Machine } from 'app/modules/machine-inventory/interfaces/Machine.types';

export class SiteUtils {
  public static mergeSiteWithCleaningStatisticData(
    siteWithoutCleaningStatistic: SiteData,
    siteCleaningStatistic: SiteCleaningStatisticDatum[]
  ): SiteData {
    const siteCleaningStatisticDatum = siteCleaningStatistic.find(
      (scs: SiteCleaningStatisticDatum) => scs.id === siteWithoutCleaningStatistic.id
    );

    if (!siteCleaningStatisticDatum?.cleaningStatistic) return siteWithoutCleaningStatistic;

    return {
      ...siteWithoutCleaningStatistic,
      cleaningStatistic: siteCleaningStatisticDatum.cleaningStatistic,
    };
  }

  public static mergeSiteWithMachinesData(
    siteWithoutMachines: SiteData,
    sitesMachinesData: SiteMachinesDatum[]
  ): SiteData {
    const machinesDatum = sitesMachinesData.find((md: SiteMachinesDatum) => md.id === siteWithoutMachines.id);

    if (!machinesDatum?.machines) return siteWithoutMachines;

    return {
      ...siteWithoutMachines,
      machines: {
        data: machinesDatum.machines.data || [],
      },
    };
  }

  public static mergeSiteWithMachinesPicturesData(
    siteMachinesWithoutVariant: SiteData,
    sitesMachinesVariantsData: SiteMachinesDatum[]
  ): SiteData {
    const machinesDatum = sitesMachinesVariantsData.find(
      (smv: SiteMachinesDatum) => smv.id === siteMachinesWithoutVariant.id
    );

    if (!machinesDatum?.machines) return siteMachinesWithoutVariant;

    const machinesData = siteMachinesWithoutVariant.machines.data.map((machine: Machine) => {
      const machineVariantDatum = machinesDatum.machines.data.find((mv: Machine) => mv.id === machine.id);

      if (!machineVariantDatum) return machine;

      return {
        ...machine,
        variant: machineVariantDatum.variant as MachineVariant,
      };
    });

    return {
      ...siteMachinesWithoutVariant,
      machines: {
        data: machinesData,
      },
    };
  }

  public static mergeSiteWithMachinesTelemetriesData(
    siteMachinesWithoutTelemetries: SiteData,
    sitesMachinesTelemetriesData: SiteMachinesDatum[]
  ): SiteData {
    const machinesDatum = sitesMachinesTelemetriesData.find(
      (smv: SiteMachinesDatum) => smv.id === siteMachinesWithoutTelemetries.id
    );

    if (!machinesDatum?.machines) return siteMachinesWithoutTelemetries;

    const machinesData = siteMachinesWithoutTelemetries.machines.data.map((machine: Machine) => {
      const machineTelemetryDatum = machinesDatum.machines.data.find((mt: Machine) => mt.id === machine.id);

      if (!machineTelemetryDatum) return machine;

      return {
        ...machine,
        states: machineTelemetryDatum.states as State[],
      };
    });

    return {
      ...siteMachinesWithoutTelemetries,
      machines: {
        data: machinesData,
      },
    };
  }

  public static mergeSiteWithMachinesLatestCtrData(
    siteMachinesWithoutLatestCtr: SiteData,
    sitesMachinesLatestCtrData: SiteMachinesDatum[]
  ): SiteData {
    const machinesDatum = sitesMachinesLatestCtrData.find(
      (smv: SiteMachinesDatum) => smv.id === siteMachinesWithoutLatestCtr.id
    );

    if (!machinesDatum?.machines) return siteMachinesWithoutLatestCtr;

    const machinesData = siteMachinesWithoutLatestCtr.machines.data.map((machine: Machine) => {
      const machineLatestCtrDatum = machinesDatum.machines.data.find((mlctr: Machine) => mlctr.id === machine.id);

      if (!machineLatestCtrDatum) return machine;

      return {
        ...machine,
        cleaningTaskReportLatestGet: machineLatestCtrDatum.cleaningTaskReportLatestGet,
      };
    });

    return {
      ...siteMachinesWithoutLatestCtr,
      machines: {
        data: machinesData,
      },
    };
  }

  public static mergeSiteWithMachinesLatestRoutineData(
    siteMachinesWithoutLatestRoutine: SiteData,
    sitesMachinesLatestRoutineData: SiteMachinesDatum[]
  ): SiteData {
    const machinesDatum = sitesMachinesLatestRoutineData.find(
      (smv: SiteMachinesDatum) => smv.id === siteMachinesWithoutLatestRoutine.id
    );

    if (!machinesDatum?.machines) return siteMachinesWithoutLatestRoutine;

    const machinesData = siteMachinesWithoutLatestRoutine.machines.data.map((machine: Machine) => {
      const machineLatestRoutineDatum = machinesDatum.machines.data.find((mlctr: Machine) => mlctr.id === machine.id);

      if (!machineLatestRoutineDatum) return machine;

      return {
        ...machine,
        latestRoutine: machineLatestRoutineDatum.latestRoutine,
      };
    });

    return {
      ...siteMachinesWithoutLatestRoutine,
      machines: {
        data: machinesData,
      },
    };
  }
}
