import styled from 'styled-components';

export const StyledSiteDetailsSiteManagerList = styled.div`
  border: 1px solid ${(props): string => props.theme.colors.brightGrey};
  padding: ${(props): string => props.theme.spacing.lg};

  .site-details-site-manager-list__head {
    margin-bottom: ${(props): string => props.theme.spacing.md};
  }

  .site-details-site-manager-list__title {
    ${(props): string => props.theme.fontStyles.redesign.xl.strong}
    text-transform: capitalize;
    margin: 0;
  }

  .site-details-site-manager-list__assign-new-manager-button-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: ${(props): string => props.theme.spacing.md};
  }

  .ant-table-row {
    td {
      .site-details-site-manager-list__unassign-btn {
        padding: 0;

        svg {
          width: 16px;
          height: 24px;
        }
      }
    }
  }
`;
