import {
  SelectEffect,
  CallEffect,
  ForkEffect,
  GetContextEffect,
  PutEffect,
  JoinEffect,
  AllEffect,
} from 'redux-saga/effects';
import { getContext, call, put, takeLatest, select, retry, SagaGenerator, fork, join, all } from 'typed-redux-saga';
import { Site, SortOrders } from '../../../../cross-cutting-concerns/communication/interfaces/am-api-graphql';
import {
  IDeleteSiteModalIsLoadingAction,
  IDeleteSiteModalIsNotLoadingAction,
  IHideDeleteSiteModalAction,
  IHideEditSiteNameModalAction,
  IHideAssignMachinesModalAction,
  SiteModalsActions,
  IEditSiteNameModalIsLoadingAction,
  IEditSiteNameModalIsNotLoadingAction,
  IEditSiteLocationModalIsLoadingAction,
  IEditSiteLocationModalIsNotLoadingAction,
  IHideEditSiteLocationModalAction,
  IHideAssignSiteManagerModalAction,
  IHideUnassignSiteManagerModalAction,
  IEditSiteTagsModalIsLoadingAction,
  IEditSiteTagsModalIsNotLoadingAction,
  IHideEditSiteTagsModalAction,
  IEditSiteInfoModalIsLoadingAction,
  IEditSiteInfoModalIsNotLoadingAction,
  IHideEditSiteInfoModalAction,
} from '../../modals/state/siteModalsActions';
import {
  SiteDelete,
  SiteAssignMachineResponse,
  SiteDetails,
  SiteUnassignMachineResponse,
  SiteUpdateResponse,
  SiteAddressCoordinateResponse,
  SiteGeofenceSetupResponse,
  SiteAssignManagersResponse,
  SiteUnassignManagerResponse,
  SiteMachineVariantData,
  SiteTagsUpdateResponse,
} from '../../interfaces/Site.types';
import { SiteListActions } from '../../site-list/state/siteListActions';
import {
  selectActiveIdsFilter,
  selectActiveSearchTextFilter,
  selectActiveTagsFilter,
} from '../../site-list/state/siteListSelectors';
import {
  IAssignMachineToSiteErrorAction,
  IAssignMachineToSiteRequestAction,
  IAssignMachineToSiteSuccessAction,
  IDeleteSiteErrorAction,
  IDeleteSiteRequestAction,
  IDeleteSiteSuccessAction,
  IGetSiteDetailsPanelErrorAction,
  IGetSiteDetailsPanelRequestAction,
  IGetSiteDetailsPanelSuccessAction,
  IUpdateSiteErrorAction,
  IUpdateSiteRequestAction,
  IUpdateSiteSuccessAction,
  IUnassignMachineFromSiteErrorAction,
  IUnassignMachineFromSiteRequestAction,
  IUnassignMachineFromSiteSuccessAction,
  SiteDetailsPanelActions,
  IUpdateSiteLocationErrorAction,
  IUpdateSiteLocationRequestAction,
  IUpdateSiteLocationSuccessAction,
  ISetGeofenceRequestAction,
  IPollSiteDetailsPanelWithGeofenceAction,
  ISiteDetailsPanelMapIsLoadingAction,
  ISiteDetailsPanelMapIsNotLoadingAction,
  ISetGeofenceAfterSiteLocationIsInitializedAction,
  ISetGeofenceAfterSiteLocationIsInitializedOptions,
  IAssignManagersToSiteSuccessAction,
  IAssignManagersToSiteRequestAction,
  IAssignManagersToSiteErrorAction,
  IUnassignManagerFromSiteRequestAction,
  IUnassignManagerFromSiteErrorAction,
  IUnassignManagerFromSiteSuccessAction,
  IGetSiteDetailsPanelMachinePicturesSuccessAction,
  IUpdateSiteTagsErrorAction,
  IUpdateSiteTagsSuccessAction,
  IUpdateSiteTagsRequestAction,
  IEditSiteInfoRequestAction,
} from './siteDetailsPanelActions';
import * as siteDetailsPanelSelectors from './siteDetailsPanelSelectors';
import { IDependencies } from 'app/cross-cutting-concerns/dependency-injection/interfaces/IDependencies';
import { Optional } from 'lib/types/Optional';
import { ALL_VALUE_SELECT_OPTION, InfiniteScrollConstants, POLL_INTERVAL, POLL_MAX_RETRIES } from 'config/constants';
import { GEOFENCE_DEFAULT_RADIUS } from 'app/components/Map/Map';
import { DrawersActions } from 'app/cross-cutting-concerns/drawers/state/drawersSlice';

export function* getSiteDetailsPanelSaga(
  action: IGetSiteDetailsPanelRequestAction
): Generator<
  | GetContextEffect
  | ForkEffect<SiteDetails>
  | ForkEffect<SiteMachineVariantData>
  | JoinEffect
  | PutEffect<IGetSiteDetailsPanelSuccessAction>
  | PutEffect<IGetSiteDetailsPanelMachinePicturesSuccessAction>
  | PutEffect<IGetSiteDetailsPanelErrorAction>,
  void,
  IDependencies
> {
  const { siteService } = yield* getContext<IDependencies>('dependencies');

  try {
    const siteResponseTask = yield* fork(siteService.get, action.payload);
    const siteMachinePicturesResponseTask = yield* fork(siteService.getMachinePictures, action.payload);

    const siteResponse = yield* join(siteResponseTask);
    yield* put(SiteDetailsPanelActions.getSiteDetailsPanelSuccess(siteResponse));

    const siteMachinePicturesResponse = yield* join(siteMachinePicturesResponseTask);
    yield* put(SiteDetailsPanelActions.getSiteDetailsPanelMachinePicturesSuccess(siteMachinePicturesResponse));
  } catch (error) {
    console.error(error);

    yield* put(
      SiteDetailsPanelActions.getSiteDetailsPanelError({
        error,
      })
    );
  }
}

export function* updateSiteSaga(
  action: IUpdateSiteRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<Optional<SiteUpdateResponse>>
  | PutEffect<IUpdateSiteSuccessAction>
  | PutEffect<IUpdateSiteErrorAction>
  | PutEffect<IEditSiteNameModalIsLoadingAction>
  | PutEffect<IEditSiteNameModalIsNotLoadingAction>
  | PutEffect<IHideEditSiteNameModalAction>,
  void,
  IDependencies
> {
  const { id, input } = action.payload;
  const { siteService } = yield* getContext<IDependencies>('dependencies');

  try {
    yield* put(SiteModalsActions.editSiteNameModalIsLoading());
    const response = yield* call(siteService.update, { id, input });

    yield* put(SiteDetailsPanelActions.updateSiteSuccess(response));

    yield* put(SiteModalsActions.editSiteNameModalIsNotLoading());
    yield* put(SiteModalsActions.hideEditSiteNameModal());
  } catch (error) {
    console.error(error);

    yield* put(
      SiteDetailsPanelActions.updateSiteError({
        error,
      })
    );

    yield* put(SiteModalsActions.editSiteNameModalIsNotLoading());
    yield* put(SiteModalsActions.hideEditSiteNameModal());
  }
}

export function* updateSiteLocationSaga(
  action: IUpdateSiteLocationRequestAction
): Generator<
  | GetContextEffect
  | SelectEffect
  | CallEffect<Optional<SiteUpdateResponse>>
  | CallEffect<Optional<SiteAddressCoordinateResponse>>
  | PutEffect<IUpdateSiteLocationSuccessAction>
  | PutEffect<IUpdateSiteLocationErrorAction>
  | PutEffect<IEditSiteLocationModalIsLoadingAction>
  | PutEffect<IEditSiteLocationModalIsNotLoadingAction>
  | PutEffect<IHideEditSiteLocationModalAction>,
  void,
  IDependencies
> {
  const site: Optional<Site> = yield* select(siteDetailsPanelSelectors.selectData);
  const originalGeofenceRadius: number = site?.geofence?.radius ?? GEOFENCE_DEFAULT_RADIUS;
  const { id, input, currentSiteAddress } = action.payload;
  const { siteService } = yield* getContext<IDependencies>('dependencies');

  try {
    // just close modal if address has not changed
    if (currentSiteAddress === input.location.address) {
      return;
    }
    yield* put(SiteModalsActions.editSiteLocationModalIsLoading());
    const coordinate: {
      latitude: number | undefined;
      longitude: number | undefined;
    } = {
      latitude: undefined,
      longitude: undefined,
    };

    if (input.location.address) {
      const filter = { text: input.location.address };

      const getAddressCoordinateRes = yield* call(siteService.getAddressCoordinate, { filter });
      coordinate.latitude = getAddressCoordinateRes.siteAddressCoordinate.data?.latitude;
      coordinate.longitude = getAddressCoordinateRes.siteAddressCoordinate.data?.longitude;

      if (!coordinate?.latitude || !coordinate?.longitude) {
        throw new Error('can not get coordinate');
      }

      const setGeofenceOptions: ISetGeofenceAfterSiteLocationIsInitializedOptions = {
        siteId: id,
        geofence: {
          circle: {
            radius: originalGeofenceRadius,
            centerPoint: {
              latitude: coordinate.latitude,
              longitude: coordinate.longitude,
            },
          },
        },
      };

      yield* put(SiteDetailsPanelActions.setGeofenceAfterSiteLocationIsInitialized(setGeofenceOptions));
    }

    const responseUpdate = yield* call(siteService.update, {
      id,
      input: {
        location: {
          address: input.location.address,
          ...coordinate,
        },
      },
    });
    yield* put(SiteDetailsPanelActions.updateSiteLocationSuccess(responseUpdate));
  } catch (error) {
    console.error(error);

    yield* put(
      SiteDetailsPanelActions.updateSiteLocationError({
        error,
      })
    );
  } finally {
    yield* put(SiteModalsActions.hideEditSiteLocationModal());
    yield* put(SiteModalsActions.editSiteLocationModalIsNotLoading());
  }
}

export function* updateSiteTagsSaga(
  action: IUpdateSiteTagsRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<Optional<SiteTagsUpdateResponse>>
  | PutEffect<IUpdateSiteTagsSuccessAction>
  | PutEffect<IUpdateSiteTagsErrorAction>
  | PutEffect<IEditSiteTagsModalIsLoadingAction>
  | PutEffect<IEditSiteTagsModalIsNotLoadingAction>
  | PutEffect<IHideEditSiteTagsModalAction>,
  void,
  IDependencies
> {
  const { id, input } = action.payload;
  const { siteService } = yield* getContext<IDependencies>('dependencies');

  try {
    yield* put(SiteModalsActions.editSiteTagsModalIsLoading());
    const response = yield* call(siteService.updateSiteTags, { id, input });

    yield* put(SiteDetailsPanelActions.updateSiteTagsSuccess(response));

    yield* put(SiteModalsActions.editSiteTagsModalIsNotLoading());
    yield* put(SiteModalsActions.hideEditSiteTagsModal());
  } catch (error) {
    console.error(error);

    yield* put(
      SiteDetailsPanelActions.updateSiteTagsError({
        error,
      })
    );

    yield* put(SiteModalsActions.editSiteTagsModalIsNotLoading());
    yield* put(SiteModalsActions.hideEditSiteTagsModal());
  }
}

export function* assignMachineToSiteSaga(
  action: IAssignMachineToSiteRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<Optional<SiteAssignMachineResponse>>
  | SelectEffect
  | PutEffect<IAssignMachineToSiteSuccessAction>
  | PutEffect<IAssignMachineToSiteErrorAction>
  | PutEffect<IHideAssignMachinesModalAction>,
  void,
  IDependencies
> {
  const { siteId, machineIds } = action.payload;
  const { siteService } = yield* getContext<IDependencies>('dependencies');

  try {
    yield* put(SiteModalsActions.assignMachinesModalIsLoading());
    const response = yield* call(siteService.assignMachine, { siteId, machineIds });

    yield* put(SiteDetailsPanelActions.assignMachineToSiteSuccess(response));

    const page = yield* select(siteDetailsPanelSelectors.selectMachinePage);
    const pageSize = yield* select(siteDetailsPanelSelectors.selectMachinePageSize);
    const paginationTokens = yield* select(siteDetailsPanelSelectors.selectMachinePaginationTokens);

    const activeSearchTextFilter = yield* select(selectActiveSearchTextFilter);
    const activeIdsFilter = yield* select(selectActiveIdsFilter);
    const activeTagsFilter = yield* select(selectActiveTagsFilter);

    yield* put(
      SiteDetailsPanelActions.getSiteDetailsPanelRequest({
        id: siteId,
        machinePaginationOptions: {
          paginationToken: paginationTokens[page],
          limit: pageSize,
        },
      })
    );

    yield* put(
      SiteListActions.getSiteListRequest({
        paginationOptions: {
          limit: InfiniteScrollConstants.MAX_ITEMS,
          paginationToken: '',
        },
        filter: {
          searchText: activeSearchTextFilter,
          ids: activeIdsFilter,
          tags: activeTagsFilter?.includes(ALL_VALUE_SELECT_OPTION) ? undefined : activeTagsFilter,
        },
        sortOptions: {
          field: 'name',
          order: SortOrders.Asc,
        },
      })
    );

    yield* put(SiteModalsActions.assignMachinesModalIsNotLoading());
    yield* put(SiteModalsActions.hideAssignMachinesModal());
  } catch (error) {
    console.error(error);

    yield* put(
      SiteDetailsPanelActions.assignMachineToSiteError({
        error,
      })
    );

    yield* put(SiteModalsActions.assignMachinesModalIsNotLoading());
    yield* put(SiteModalsActions.hideAssignMachinesModal());
  }
}

export function* unassignMachineFromSiteSaga(
  action: IUnassignMachineFromSiteRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<Optional<SiteUnassignMachineResponse>>
  | SelectEffect
  | PutEffect<IUnassignMachineFromSiteSuccessAction>
  | PutEffect<IUnassignMachineFromSiteErrorAction>
  | PutEffect<IHideAssignMachinesModalAction>,
  void,
  IDependencies
> {
  const { siteId, machineId } = action.payload;
  const { siteService } = yield* getContext<IDependencies>('dependencies');

  try {
    yield* put(SiteModalsActions.unassignMachinesModalIsLoading());
    const response = yield* call(siteService.unassignMachine, { siteId, machineId });

    yield* put(SiteDetailsPanelActions.unassignMachineFromSiteSuccess(response));

    const page = yield* select(siteDetailsPanelSelectors.selectMachinePage);
    const pageSize = yield* select(siteDetailsPanelSelectors.selectMachinePageSize);
    const paginationTokens = yield* select(siteDetailsPanelSelectors.selectMachinePaginationTokens);

    const activeSearchTextFilter = yield* select(selectActiveSearchTextFilter);
    const activeIdsFilter = yield* select(selectActiveIdsFilter);
    const activeTagsFilter = yield* select(selectActiveTagsFilter);

    yield* put(
      SiteDetailsPanelActions.getSiteDetailsPanelRequest({
        id: siteId,
        machinePaginationOptions: {
          paginationToken: paginationTokens[page],
          limit: pageSize,
        },
      })
    );

    yield* put(
      SiteListActions.getSiteListRequest({
        paginationOptions: {
          limit: InfiniteScrollConstants.MAX_ITEMS,
          paginationToken: '',
        },
        filter: {
          searchText: activeSearchTextFilter,
          ids: activeIdsFilter,
          tags: activeTagsFilter?.includes(ALL_VALUE_SELECT_OPTION) ? undefined : activeTagsFilter,
        },
        sortOptions: {
          field: 'name',
          order: SortOrders.Asc,
        },
      })
    );

    yield* put(SiteModalsActions.unassignMachinesModalIsNotLoading());
    yield* put(SiteModalsActions.hideUnassignMachinesModal());
  } catch (error) {
    console.error(error);

    yield* put(
      SiteDetailsPanelActions.unassignMachineFromSiteError({
        error,
      })
    );

    yield* put(SiteModalsActions.unassignMachinesModalIsNotLoading());
    yield* put(SiteModalsActions.hideUnassignMachinesModal());
  }
}

export function* deleteSiteSaga(
  action: IDeleteSiteRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<Optional<SiteDelete>>
  | PutEffect<IDeleteSiteSuccessAction>
  | PutEffect<IDeleteSiteErrorAction>
  | PutEffect<IDeleteSiteModalIsLoadingAction>
  | PutEffect<IDeleteSiteModalIsNotLoadingAction>
  | PutEffect<IHideDeleteSiteModalAction>
  | SelectEffect,
  void,
  IDependencies
> {
  const { id } = action.payload;
  const { siteService } = yield* getContext<IDependencies>('dependencies');

  const activeSearchTextFilter = yield* select(selectActiveSearchTextFilter);
  const activeIdsFilter = yield* select(selectActiveIdsFilter);
  const activeTagsFilter = yield* select(selectActiveTagsFilter);

  try {
    yield* put(SiteModalsActions.deleteSiteModalIsLoading());
    const response = yield* call(siteService.delete, { id });

    yield* put(SiteDetailsPanelActions.deleteSiteSuccess(response));

    yield* put(SiteModalsActions.deleteSiteModalIsNotLoading());

    yield* put(SiteModalsActions.hideDeleteSiteModal());

    yield* put(DrawersActions.hideSiteDetailsDrawer());

    yield* put(
      SiteListActions.getSiteListRequest({
        paginationOptions: {
          limit: InfiniteScrollConstants.MAX_ITEMS,
          paginationToken: '',
        },
        filter: {
          searchText: activeSearchTextFilter,
          ids: activeIdsFilter,
          tags: activeTagsFilter?.includes(ALL_VALUE_SELECT_OPTION) ? undefined : activeTagsFilter,
        },
        sortOptions: {
          field: 'name',
          order: SortOrders.Asc,
        },
      })
    );
  } catch (error) {
    console.error(error);

    yield* put(
      SiteDetailsPanelActions.deleteSiteError({
        error,
      })
    );

    yield* put(SiteModalsActions.deleteSiteModalIsNotLoading());
    yield* put(SiteModalsActions.hideDeleteSiteModal());
  }
}

export function* setSiteGeofenceSaga(
  action: ISetGeofenceRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<Optional<SiteGeofenceSetupResponse>>
  | SelectEffect
  | PutEffect<IDeleteSiteSuccessAction>
  | PutEffect<IDeleteSiteErrorAction>
  | PutEffect<IDeleteSiteModalIsLoadingAction>
  | PutEffect<IDeleteSiteModalIsNotLoadingAction>
  | PutEffect<IHideDeleteSiteModalAction>,
  void,
  IDependencies
> {
  const { siteId, geofence } = action.payload;
  const { siteService } = yield* getContext<IDependencies>('dependencies');

  try {
    yield* put(SiteModalsActions.changeGeofenceModalIsLoading());
    const response = yield* call(siteService.setGeofence, { siteId, geofence });

    yield* put(SiteDetailsPanelActions.setGeofenceSuccess(response));

    const page = yield* select(siteDetailsPanelSelectors.selectMachinePage);
    const pageSize = yield* select(siteDetailsPanelSelectors.selectMachinePageSize);
    const paginationTokens = yield* select(siteDetailsPanelSelectors.selectMachinePaginationTokens);

    yield* put(
      SiteDetailsPanelActions.pollSiteDetailsPanelWithGeofence({
        id: siteId,
        machinePaginationOptions: {
          paginationToken: paginationTokens[page],
          limit: pageSize,
        },
        geofence,
      })
    );

    yield* put(SiteModalsActions.changeGeofenceModalIsNotLoading());
    yield* put(SiteModalsActions.hideChangeGeofenceModal());
  } catch (error) {
    console.error(error);

    yield* put(
      SiteDetailsPanelActions.setGeofenceError({
        error,
      })
    );

    yield* put(SiteModalsActions.changeGeofenceModalIsNotLoading());
    yield* put(SiteModalsActions.hideChangeGeofenceModal());
  }
}

export function* setGeofenceAfterSiteLocationIsInitializedSaga(
  action: ISetGeofenceAfterSiteLocationIsInitializedAction
): Generator<
  | GetContextEffect
  | CallEffect<Optional<SiteGeofenceSetupResponse>>
  | SelectEffect
  | PutEffect<IDeleteSiteSuccessAction>
  | PutEffect<IDeleteSiteErrorAction>
  | PutEffect<IDeleteSiteModalIsLoadingAction>
  | PutEffect<IDeleteSiteModalIsNotLoadingAction>
  | PutEffect<IHideDeleteSiteModalAction>,
  void,
  IDependencies
> {
  const { siteId, geofence } = action.payload;
  const { siteService } = yield* getContext<IDependencies>('dependencies');

  try {
    yield* call(siteService.setGeofence, { siteId, geofence });

    const page = yield* select(siteDetailsPanelSelectors.selectMachinePage);
    const pageSize = yield* select(siteDetailsPanelSelectors.selectMachinePageSize);
    const paginationTokens = yield* select(siteDetailsPanelSelectors.selectMachinePaginationTokens);

    yield* put(
      SiteDetailsPanelActions.pollSiteDetailsPanelWithGeofence({
        id: siteId,
        machinePaginationOptions: {
          paginationToken: paginationTokens[page],
          limit: pageSize,
        },
        geofence,
      })
    );
  } catch (error) {
    console.error(error);
  }
}

export function* pollSiteDetailsPanelWithGeofenceSaga(
  action: IPollSiteDetailsPanelWithGeofenceAction
): Generator<
  | GetContextEffect
  | SagaGenerator<SiteDetails, CallEffect<SiteDetails>>
  | PutEffect<ISiteDetailsPanelMapIsLoadingAction>
  | PutEffect<ISiteDetailsPanelMapIsNotLoadingAction>
  | PutEffect<IGetSiteDetailsPanelSuccessAction>
  | PutEffect<IGetSiteDetailsPanelErrorAction>,
  void,
  any
> {
  const newGeofence = action.payload.geofence.circle;
  const { siteService } = yield* getContext<IDependencies>('dependencies');

  const getSiteDetailsPanelWithUpdatedGeofence = async (): Promise<SiteDetails> => {
    const response = await siteService.get(action.payload);

    if (
      response?.site?.data?.geofence?.centerPoint.latitude !== newGeofence.centerPoint.latitude ||
      response?.site?.data?.geofence?.centerPoint.longitude !== newGeofence.centerPoint.longitude ||
      response?.site?.data?.geofence?.radius !== newGeofence.radius
    ) {
      throw new Error('Geofence not updated');
    }

    return response;
  };

  try {
    yield* put(SiteDetailsPanelActions.mapIsLoading());
    const response: SiteDetails = yield retry(
      POLL_MAX_RETRIES,
      POLL_INTERVAL + 5000,
      getSiteDetailsPanelWithUpdatedGeofence
    );
    yield* put(SiteDetailsPanelActions.getSiteDetailsPanelSuccess(response));
    yield* put(SiteDetailsPanelActions.mapIsNotLoading());
  } catch (error) {
    console.error(error);

    yield* put(
      SiteDetailsPanelActions.getSiteDetailsPanelError({
        error,
      })
    );
    yield* put(SiteDetailsPanelActions.mapIsNotLoading());
  }
}

export function* assignManagersToSiteSaga(
  action: IAssignManagersToSiteRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<Optional<SiteAssignManagersResponse>>
  | SelectEffect
  | PutEffect<IAssignManagersToSiteSuccessAction>
  | PutEffect<IAssignManagersToSiteErrorAction>
  | PutEffect<IHideAssignSiteManagerModalAction>,
  void,
  IDependencies
> {
  const { siteId, userIds } = action.payload;
  const { siteService } = yield* getContext<IDependencies>('dependencies');

  try {
    yield* put(SiteModalsActions.assignSiteManagerModalIsLoading());
    const response = yield* call(siteService.assignManagers, { siteId, userIds });

    yield* put(SiteDetailsPanelActions.assignManagersToSiteSuccess(response));

    const page = yield* select(siteDetailsPanelSelectors.selectMachinePage);
    const pageSize = yield* select(siteDetailsPanelSelectors.selectMachinePageSize);
    const paginationTokens = yield* select(siteDetailsPanelSelectors.selectMachinePaginationTokens);

    yield* put(
      SiteDetailsPanelActions.getSiteDetailsPanelRequest({
        id: siteId,
        machinePaginationOptions: {
          paginationToken: paginationTokens[page],
          limit: pageSize,
        },
      })
    );
  } catch (error) {
    console.error(error);

    yield* put(
      SiteDetailsPanelActions.assignManagersToSiteError({
        error,
      })
    );
  } finally {
    yield* put(SiteModalsActions.assignSiteManagerModalIsNotLoading());
    yield* put(SiteModalsActions.hideAssignSiteManagerModal());
  }
}

export function* unassignManagerFromSiteSaga(
  action: IUnassignManagerFromSiteRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<Optional<SiteUnassignManagerResponse>>
  | SelectEffect
  | PutEffect<IUnassignManagerFromSiteSuccessAction>
  | PutEffect<IUnassignManagerFromSiteErrorAction>
  | PutEffect<IHideUnassignSiteManagerModalAction>,
  void,
  IDependencies
> {
  const { siteId, userId } = action.payload;
  const { siteService } = yield* getContext<IDependencies>('dependencies');

  try {
    yield* put(SiteModalsActions.unassignSiteManagerModalIsLoading());
    const response = yield* call(siteService.unassignManager, { siteId, userId });

    yield* put(SiteDetailsPanelActions.unassignManagerFromSiteSuccess(response));

    const page = yield* select(siteDetailsPanelSelectors.selectMachinePage);
    const pageSize = yield* select(siteDetailsPanelSelectors.selectMachinePageSize);
    const paginationTokens = yield* select(siteDetailsPanelSelectors.selectMachinePaginationTokens);

    yield* put(
      SiteDetailsPanelActions.getSiteDetailsPanelRequest({
        id: siteId,
        machinePaginationOptions: {
          paginationToken: paginationTokens[page],
          limit: pageSize,
        },
      })
    );
  } catch (error) {
    console.error(error);

    yield* put(
      SiteDetailsPanelActions.unassignManagerFromSiteError({
        error,
      })
    );
  } finally {
    yield* put(SiteModalsActions.unassignSiteManagerModalIsNotLoading());
    yield* put(SiteModalsActions.hideUnassignSiteManagerModal());
  }
}

export function* editSiteInfoSaga(
  action: IEditSiteInfoRequestAction
): Generator<
  | GetContextEffect
  | SelectEffect
  | AllEffect<any>
  | CallEffect<Optional<SiteUpdateResponse>>
  | CallEffect<Optional<SiteTagsUpdateResponse>>
  | CallEffect<Optional<SiteAddressCoordinateResponse>>
  | PutEffect<IUpdateSiteSuccessAction>
  | PutEffect<IUpdateSiteErrorAction>
  | PutEffect<IUpdateSiteTagsSuccessAction>
  | PutEffect<IUpdateSiteTagsErrorAction>
  | PutEffect<IEditSiteInfoModalIsLoadingAction>
  | PutEffect<IEditSiteInfoModalIsNotLoadingAction>
  | PutEffect<IHideEditSiteInfoModalAction>,
  void,
  IDependencies
> {
  const {
    id,
    input: { inputSite, inputTag },
  } = action.payload;

  const { siteService } = yield* getContext<IDependencies>('dependencies');

  const page = yield* select(siteDetailsPanelSelectors.selectMachinePage);
  const pageSize = yield* select(siteDetailsPanelSelectors.selectMachinePageSize);
  const paginationTokens = yield* select(siteDetailsPanelSelectors.selectMachinePaginationTokens);

  const activeSearchTextFilter = yield* select(selectActiveSearchTextFilter);
  const activeIdsFilter = yield* select(selectActiveIdsFilter);
  const activeTagsFilter = yield* select(selectActiveTagsFilter);

  const site: Optional<Site> = yield* select(siteDetailsPanelSelectors.selectData);
  const originalGeofenceRadius: number = site?.geofence?.radius ?? GEOFENCE_DEFAULT_RADIUS;

  try {
    yield* put(SiteModalsActions.editSiteInfoModalIsLoading());

    const coordinate: {
      latitude: number | undefined;
      longitude: number | undefined;
    } = {
      latitude: site?.location?.latitude || undefined,
      longitude: site?.location?.longitude || undefined,
    };

    if (inputSite?.location?.address) {
      const filter = { text: inputSite?.location?.address };

      const getAddressCoordinateRes = yield* call(siteService.getAddressCoordinate, { filter });
      coordinate.latitude = getAddressCoordinateRes.siteAddressCoordinate.data?.latitude;
      coordinate.longitude = getAddressCoordinateRes.siteAddressCoordinate.data?.longitude;

      if (!coordinate?.latitude || !coordinate?.longitude) {
        throw new Error('can not get coordinate');
      }

      const setGeofenceOptions: ISetGeofenceAfterSiteLocationIsInitializedOptions = {
        siteId: id,
        geofence: {
          circle: {
            radius: originalGeofenceRadius,
            centerPoint: {
              latitude: coordinate.latitude,
              longitude: coordinate.longitude,
            },
          },
        },
      };

      yield* put(SiteDetailsPanelActions.setGeofenceAfterSiteLocationIsInitialized(setGeofenceOptions));
    }

    const [updateSiteResponse, updateTagsResponse] = yield* all([
      call(siteService.update, {
        id,
        input: {
          name: inputSite?.name,
          location: {
            address: inputSite?.location?.address,
            ...coordinate,
          },
        },
      }),
      call(siteService.updateSiteTags, { id, input: inputTag }),
    ]);

    yield* put(SiteDetailsPanelActions.updateSiteSuccess(updateSiteResponse as SiteUpdateResponse));
    yield* put(SiteDetailsPanelActions.updateSiteTagsSuccess(updateTagsResponse as SiteTagsUpdateResponse));

    if (updateSiteResponse && updateTagsResponse) {
      yield* put(
        SiteDetailsPanelActions.getSiteDetailsPanelRequest({
          id,
          machinePaginationOptions: {
            paginationToken: paginationTokens[page],
            limit: pageSize,
          },
        })
      );

      yield* put(
        SiteListActions.getSiteListRequest({
          paginationOptions: {
            limit: InfiniteScrollConstants.MAX_ITEMS,
            paginationToken: '',
          },
          filter: {
            searchText: activeSearchTextFilter,
            ids: activeIdsFilter,
            tags: activeTagsFilter?.includes(ALL_VALUE_SELECT_OPTION) ? undefined : activeTagsFilter,
          },
          sortOptions: {
            field: 'name',
            order: SortOrders.Asc,
          },
        })
      );
    }
  } catch (error) {
    console.error(error);

    yield* put(
      SiteDetailsPanelActions.updateSiteError({
        error,
      })
    );

    yield* put(
      SiteDetailsPanelActions.updateSiteTagsError({
        error,
      })
    );
  } finally {
    yield* put(SiteModalsActions.editSiteInfoModalIsNotLoading());
    yield* put(SiteModalsActions.hideEditSiteInfoModal());
  }
}

export function* siteDetailsPanelSaga(): Generator<ForkEffect<never>, void> {
  yield* takeLatest(SiteDetailsPanelActions.GET_SITE_DETAILS_REQUEST, getSiteDetailsPanelSaga);
  yield* takeLatest(SiteDetailsPanelActions.UPDATE_SITE_REQUEST, updateSiteSaga);
  yield* takeLatest(SiteDetailsPanelActions.UPDATE_SITE_LOCATION_REQUEST, updateSiteLocationSaga);
  yield* takeLatest(SiteDetailsPanelActions.UPDATE_SITE_TAGS_REQUEST, updateSiteTagsSaga);
  yield* takeLatest(SiteDetailsPanelActions.DELETE_SITE_REQUEST, deleteSiteSaga);
  yield* takeLatest(SiteDetailsPanelActions.ASSIGN_MACHINE_TO_SITE_REQUEST, assignMachineToSiteSaga);
  yield* takeLatest(SiteDetailsPanelActions.UNASSIGN_MACHINE_FROM_SITE_REQUEST, unassignMachineFromSiteSaga);
  yield* takeLatest(SiteDetailsPanelActions.ASSIGN_MANAGERS_TO_SITE_REQUEST, assignManagersToSiteSaga);
  yield* takeLatest(SiteDetailsPanelActions.UNASSIGN_MANAGER_FROM_SITE_REQUEST, unassignManagerFromSiteSaga);
  yield* takeLatest(SiteDetailsPanelActions.SET_GEOFENCE_REQUEST, setSiteGeofenceSaga);
  yield* takeLatest(SiteDetailsPanelActions.POLL_SITE_DETAILS_WITH_GEOFENCE, pollSiteDetailsPanelWithGeofenceSaga);
  yield* takeLatest(
    SiteDetailsPanelActions.SET_GEOFENCE_AFTER_SITE_LOCATION_IS_INITIALIZED,
    setGeofenceAfterSiteLocationIsInitializedSaga
  );
  yield* takeLatest(SiteDetailsPanelActions.EDIT_SITE_INFO_REQUEST, editSiteInfoSaga);
}
