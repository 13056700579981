import React from 'react';
import { Row, Col, Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeConstants } from '../../../../../../config/theme';
import { selectPermissions } from '../../../../../cross-cutting-concerns/authentication/state/authenticationSelectors';
import { StyledSiteDetailsInfo } from './SiteDetailsInfo.styles';
import { Tags } from 'lib/components/Tags/Tags';
import { Site } from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { Permission } from 'config/permissions';
import { isUserPermitted } from 'app/cross-cutting-concerns/authentication/utils/isUserPermitted';
import { TextButton } from 'lib/components/Button/TextButton/TextButton';
import { SvgIcon } from 'lib/components/SvgIcon/SvgIcon';
import { PermissionGuard } from 'app/cross-cutting-concerns/authentication/components/PermissionGuard/PermissionGuard';
import { SiteModalsActions } from 'app/modules/site-management/modals/state/siteModalsActions';

export interface SiteDetailsInfoProps {
  site: Site;
  isLoading: boolean;
}

export const SiteDetailsInfo = ({ site, isLoading }: SiteDetailsInfoProps): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userPermissions = useSelector(selectPermissions);

  const showEditSiteInfoModal = (): void => {
    dispatch(SiteModalsActions.showEditSiteInfoModal());
  };

  if (isLoading) {
    return (
      <StyledSiteDetailsInfo
        title={t('common.informationData')}
        className="site-details-info"
        actions={[
          <Skeleton.Button
            size="large"
            className="site-details-info__delete-button"
            key="site-details-info__delete-button"
            active
          />,
        ]}
      >
        <Row>
          <Col span={12}>
            <Skeleton active title paragraph={{ rows: 1 }} />
          </Col>
        </Row>
      </StyledSiteDetailsInfo>
    );
  }

  return (
    <StyledSiteDetailsInfo className="site-details-info">
      <Row
        gutter={[ThemeConstants.BASE_GAP, ThemeConstants.BASE_GAP]}
        justify="space-between"
        align="middle"
        className="site-details-info__header-container"
      >
        <Col>
          <h3 tabIndex={0} className="site-details-info__header">
            {t('common.informationData')}
          </h3>
        </Col>
        <Col>
          {isUserPermitted(userPermissions, [Permission.Site.UPDATE]) && (
            <PermissionGuard requiredPermissions={[Permission.Site.UPDATE]}>
              <TextButton
                size="s"
                className="site-details-info__edit-button-tags"
                icon={<SvgIcon name="edit" />}
                onClick={showEditSiteInfoModal}
              />
            </PermissionGuard>
          )}
        </Col>
      </Row>
      <Row gutter={[ThemeConstants.BASE_GAP, ThemeConstants.BASE_GAP]} justify="space-between">
        <Col>
          <div className="site-details-info__heading">
            <h4 className="site-details-info__sub-title">{t('siteDetails.info.name')}</h4>
            <p>{site.name}</p>
          </div>
          <div className="site-details-info__heading">
            <h4 className="site-details-info__sub-title">{t('siteDetails.info.address')}</h4>
            <p>{site.location?.address || '-'}</p>
          </div>
          <div className="site-details-info__heading">
            <h4 className="site-details-info__sub-title">{t('siteDetails.info.tags')}</h4>
            <Tags tags={site.tags || []} />
          </div>
        </Col>
      </Row>
    </StyledSiteDetailsInfo>
  );
};
