import { Spin } from 'antd';
import { flatten } from 'lodash-es';
import React, { useEffect, useMemo, useState } from 'react';
import {
  Calendar,
  Components as RbcComponents,
  DateRangeFormatFunction,
  Event as RbcEvent,
  Formats as RbcFormats,
  dayjsLocalizer,
  View as RbcView,
  Views as RbcViews,
  ViewsProps as RbcViewsProps,
} from 'react-big-calendar';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { DATE_FORMAT_PATTERN, TimezoneType } from '../../../../../../lib/utils/date-handling/DateTime.types';
import {
  ICalendarEvent,
  ICleaningPlanEvent,
  IWorkIntervalLocal,
} from '../../../../cleaning/interfaces/CleaningPlan.types';
import { useWorkIntervalDataRequestGuard } from '../../../../cleaning/contexts/useWorkIntervalDataRequestGuard';
import { CalendarUtils } from '../../../../cleaning/utils/CalendarUtils';
import { CleaningPlanUtils } from '../../../../cleaning/utils/CleaningPlanUtils';
import * as siteDetailsSelectors from '../../state/siteDetailsPanelSelectors';
import { CleaningPlanEvent } from '../CleaningPlanEvent/CleaningPlanEvent';
import { StyledCleaningPlan } from './CleaningPlan.styles';
import { DateTime } from 'lib/utils/date-handling/DateTime';
import { SecondaryButton } from 'lib/components/Button/SecondaryButton/SecondaryButton';
import {
  IShowAddWorkIntervalModalAction,
  SiteModalsActions,
} from 'app/modules/site-management/modals/state/siteModalsActions';
import { Site } from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { PermissionGuard } from 'app/cross-cutting-concerns/authentication/components/PermissionGuard/PermissionGuard';
import { Permission } from 'config/permissions';

const localizer = dayjsLocalizer(dayjs);

const CALENDAR_OPTIONS: {
  views: RbcViewsProps<RbcEvent, Record<string, unknown>>;
  defaultView: RbcView;
  dayFormat: string;
} = {
  views: [RbcViews.WEEK],
  defaultView: RbcViews.WEEK,
  dayFormat: 'ddd',
};

interface CleaningPlanProps {
  site: Site;
}

export const CleaningPlan = ({ site: _site }: CleaningPlanProps): JSX.Element => {
  const { t, i18n } = useTranslation();
  const [defaultDate, setDefaultDate] = useState<Date>();
  const dispatch = useDispatch();
  const workIntervals = useSelector(siteDetailsSelectors.selectWorkIntervals) ?? [];
  const areCleaningPlansLoading = useSelector(siteDetailsSelectors.selectAreWorkIntervalsLoading);

  const cleaningPlanEvents: ICleaningPlanEvent[] = flatten(
    workIntervals.map((workIntervalLocal: IWorkIntervalLocal): ICleaningPlanEvent[] =>
      CleaningPlanUtils.convertWorkIntervalToCleaningPlanEvents(workIntervalLocal, TimezoneType.LOCAL)
    )
  );

  const calendarEvents: ICalendarEvent[] = cleaningPlanEvents.map(datum =>
    CalendarUtils.convertCleaningPlanEventToCalendarEvent(datum)
  );

  // Call hook to ensure that data needed by this component is loaded higher up in the component hierarchy
  useWorkIntervalDataRequestGuard();

  useEffect(() => {
    setDefaultDate(DateTime.today());
  }, []);

  const convertEventTimeRangeFormat: DateRangeFormatFunction = (range): string => {
    const start = DateTime.formatDateByLocale(
      i18n.language,
      range.start.toISOString(),
      DATE_FORMAT_PATTERN.TIME_WITHOUT_SECOND
    );

    const end = DateTime.formatDateByLocale(
      i18n.language,
      range.end.toISOString(),
      DATE_FORMAT_PATTERN.TIME_WITHOUT_SECOND
    );

    return `${start} - ${end}`;
  };

  const calendarI18nDateFormats: RbcFormats = {
    ...t('siteDetails.cleaningPlan.reactBigCalendarFormats', { returnObjects: true }),
    eventTimeRangeFormat: convertEventTimeRangeFormat,
  };

  const calendarComponents: RbcComponents<ICalendarEvent, Record<string, unknown>> = useMemo(
    () => ({
      eventWrapper: (props): React.ReactElement => <CleaningPlanEvent {...props} />,
    }),
    []
  );

  return (
    <StyledCleaningPlan className="cleaning-plan">
      <h3 tabIndex={0} className="cleaning-plan__title">
        {t('siteDetails.cleaningPlan.title')}
      </h3>
      <div className="cleaning-plan__calendar-wrapper">
        <Spin spinning={areCleaningPlansLoading} className="cleaning-plan__spin">
          <Calendar
            className="cleaning-plan__calendar"
            defaultDate={defaultDate}
            defaultView={CALENDAR_OPTIONS.defaultView}
            events={calendarEvents}
            localizer={localizer}
            views={CALENDAR_OPTIONS.views}
            components={calendarComponents}
            toolbar={false}
            dayLayoutAlgorithm="overlap"
            formats={{ ...calendarI18nDateFormats, dayFormat: CALENDAR_OPTIONS.dayFormat }}
            culture={i18n.language}
            scrollToTime={CalendarUtils.getInitialTimeFromCalendarEvents(calendarEvents)}
          />
        </Spin>

        <PermissionGuard requiredPermissions={[Permission.Site.WorkInterval.UPDATE]}>
          <div className="cleaning-plan__footer">
            <SecondaryButton
              size="s"
              onClick={(): IShowAddWorkIntervalModalAction => dispatch(SiteModalsActions.showAddWorkIntervalModal())}
              className="cleaning-plan__add-work-interval-button"
              key="cleaning-plan__add-work-interval-button"
            >
              {t('siteDetails.cleaningPlan.addWorkInterval')}
            </SecondaryButton>
          </div>
        </PermissionGuard>
      </div>
    </StyledCleaningPlan>
  );
};
