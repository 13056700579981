import styled from 'styled-components';
import { AutoComplete } from 'lib/components/AutoComplete/AutoComplete';

export const StyledAddressesSuggestionAutoComplete = styled(AutoComplete)`
  .auto-complete__custom-input {
    .ant-input-prefix svg {
      width: 20px;
      height: 20px;
      stroke-width: 30px;
      stroke: ${(props): string => props.theme.colors.white};
    }

    .ant-input-suffix {
      margin-right: 15px;
    }

    input {
      ${(props): string => props.theme.fontStyles.redesign.base.normal};

      &::placeholder {
        ${(props): string => props.theme.fontStyles.p1.medium};
        color: ${(props): string => props.theme.colors.darkGrey};
      }
    }
  }

  .ant-select-selector {
    border: 1px solid ${(props): string => props.theme.colors.darkGrey} !important;
  }

  .ant-select {
    height: 36px;

    :not(.ant-select-disabled):hover {
      .ant-select-selector {
        border-right-width: 1px !important;
      }
    }
  }

  .ant-select-clear {
    background: none;

    svg {
      height: 12px;
      width: 12px;
    }
  }
`;
