import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'antd';
import { debounce } from 'lodash-es';
import {
  OpenSearch,
  TAGS_MAX_LENGTH,
  TAGS_MAX_SIZE,
  TAGS_SEARCH_DELAY_IN_MS,
  TAGS_SEARCH_LIMIT,
} from '../../../../../../config/constants';
import { Permission } from '../../../../../../config/permissions';
import { SelectUtils } from '../../../../../../lib/components/Select/SelectUtils';
import { useAnalyticsLinkActivated } from '../../../../../cross-cutting-concerns/analytics/hooks/useAnalyticsLinkActivated';
import { useAnalyticsSetForm } from '../../../../../cross-cutting-concerns/analytics/hooks/useAnalyticsSetForm';
import {
  AnalyticsForm,
  AnalyticsLink,
} from '../../../../../cross-cutting-concerns/analytics/interfaces/Analytics.types';
import { PermissionGuard } from '../../../../../cross-cutting-concerns/authentication/components/PermissionGuard/PermissionGuard';
import { FeatureFlagSelectors } from '../../../../../cross-cutting-concerns/feature-flags/state/featureFlagSelectors';
import { SITE_MANAGER_LIST_ASSIGNABLE_ROLE } from '../../../site-details/components/SiteDetailsSiteManagerList/SiteDetailsSiteManagerList';
import { SiteModalsActions } from '../../state/siteModalsActions';
import {
  selectCreateSiteModalManagers,
  selectIsCreateSiteModalLoading,
  selectIsCreateSiteModalVisible,
  selectCreateSiteModalManagersLoading,
  selectCreateSiteModalTags,
  selectCreateSiteModalAreTagsLoading,
} from '../../state/siteModalsSelectors';
import { AddressesSuggestionAutoComplete } from '../AddressesSuggestionAutoComplete/AddressesSuggestionAutoComplete';
import { StyledCreateSiteModal } from './CreateSiteModal.styles';
import { SiteListActions } from 'app/modules/site-management/site-list/state/siteListActions';
import { Status } from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { PrimaryButton } from 'lib/components/Button/PrimaryButton/PrimaryButton';
import { Select } from 'lib/components/Select/Select';
import { Input } from 'lib/components/Input/Input';
import { TextButton } from 'lib/components/Button/TextButton/TextButton';

export const CreateSiteModal = (): JSX.Element => {
  const features = useSelector(FeatureFlagSelectors.selectFeatureFlagConfig);
  const analyticsLinkActivated = useAnalyticsLinkActivated();
  const [formInstance] = Form.useForm();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isVisible = useSelector(selectIsCreateSiteModalVisible);
  const isLoading = useSelector(selectIsCreateSiteModalLoading);
  const managers = useSelector(selectCreateSiteModalManagers) || [];
  const isManagersLoading = useSelector(selectCreateSiteModalManagersLoading);
  const tags = useSelector(selectCreateSiteModalTags) || [];
  const areTagsLoading = useSelector(selectCreateSiteModalAreTagsLoading);
  const isListManagersEnabled = features.LIST_MANAGERS;

  const [name, setName] = useState<string>('');
  const [location, setLocation] = useState<{ address: string } | undefined>(undefined);
  const [siteManagers, setSiteManagers] = useState<string[] | undefined>(undefined);
  const [selectedTags, setSelectedTags] = useState<string[] | undefined>(undefined);
  const [isSiteMangersOpen, setIsSiteMangersOpen] = useState<boolean>(false);
  const [isTagsSelectOpen, setIsTagsSelectOpen] = useState<boolean>(false);
  const [searchVal, setSearchValue] = useState('');

  useEffect(() => {
    if (!isVisible) {
      dispatch(SiteModalsActions.createSiteModalResetState());
      dispatch(SiteModalsActions.setSearchingAddressValue());
      formInstance.resetFields();
    }
  }, [isVisible, formInstance, dispatch]);

  const handleCancel = useCallback(() => {
    dispatch(SiteModalsActions.hideCreateSiteModal());
  }, [dispatch]);

  useEffect(() => {
    if (!isListManagersEnabled) return;

    if (isVisible) {
      dispatch(
        SiteModalsActions.getSiteManagerListRequest({
          filter: {
            status: Status.Active,
            role: SITE_MANAGER_LIST_ASSIGNABLE_ROLE,
          },
          paginationOptions: {
            limit: OpenSearch.MAX_RESULT_WINDOW,
          },
        })
      );
    }
  }, [dispatch, isListManagersEnabled, isVisible]);

  useEffect(() => {
    if (!isVisible) return;

    dispatch(
      SiteModalsActions.listTagsRequest({
        paginationOptions: {
          limit: TAGS_SEARCH_LIMIT,
        },
      })
    );
  }, [dispatch, isListManagersEnabled, isVisible]);

  const searchTags = debounce((searchValue: string): void => {
    dispatch(
      SiteModalsActions.listTagsRequest({
        filter: {
          textBegin: searchValue.trim(),
        },
        paginationOptions: {
          limit: TAGS_SEARCH_LIMIT,
        },
      })
    );
  }, TAGS_SEARCH_DELAY_IN_MS);

  const handleSearch = (value: string): void => {
    const truncatedValue = value.substring(0, TAGS_MAX_LENGTH);
    setSearchValue(truncatedValue);
    searchTags(truncatedValue);
  };

  const handleOk = useCallback(() => {
    formInstance.submit();
  }, [formInstance]);

  const onFinish = useCallback(
    (values: { name: string; location?: { address: string }; siteManagers?: string[]; tags?: string[] }) => {
      analyticsLinkActivated({
        linkName: AnalyticsLink.CREATE_SITE,
      });

      dispatch(
        SiteListActions.createSiteRequest({
          input: {
            name: values.name,
            userIds: values.siteManagers,
            tags: values.tags,
            ...(values?.location?.address && {
              location: {
                address: values.location.address,
                // Fetch and assign replace coordinate values inside saga with another query siteAddressCoordinate
                latitude: 0,
                longitude: 0,
              },
            }),
          },
        })
      );
    },
    [analyticsLinkActivated, dispatch]
  );

  useAnalyticsSetForm({
    name: AnalyticsForm.CREATE_SITE,
    fields: {
      name,
      location,
      siteManagers,
    },
    isVisible,
  });

  const handleValuesChange = useCallback((): void => {
    setName(formInstance.getFieldsValue().name);
    setLocation(formInstance.getFieldsValue().location);
    setSiteManagers(formInstance.getFieldsValue().siteManagers);
    setSelectedTags(formInstance.getFieldsValue().tags);
  }, [formInstance]);

  return (
    <StyledCreateSiteModal
      className="create-site-modal"
      title={t('siteList.createSiteModal.createSiteTitle')}
      open={isVisible}
      footer={[
        <TextButton size="m" onClick={handleCancel} className="cancel-button" key="cancel-button">
          {t('common.cancel')}
        </TextButton>,
        <PrimaryButton
          size="m"
          key="submit"
          className="submit-button"
          type="primary"
          onClick={handleOk}
          loading={isLoading}
        >
          {t('siteList.createSiteModal.create')}
        </PrimaryButton>,
      ]}
    >
      <Form
        form={formInstance}
        name="create-site"
        layout="vertical"
        onValuesChange={handleValuesChange}
        onFinish={onFinish}
        autoComplete="off"
        preserve={false}
        labelAlign="left"
      >
        <Form.Item
          name="name"
          label={t('siteList.createSiteModal.name')}
          required
          className="create-site-modal__input-group"
          rules={[{ required: true, message: t('siteList.createSiteModal.errors.nameRequired') }]}
        >
          <Input className="create-site-modal__input-site-name" />
        </Form.Item>

        <Form.Item
          name={['location', 'address']}
          label={t('siteList.createSiteModal.address')}
          className="create-site-modal__input-group"
        >
          <AddressesSuggestionAutoComplete />
        </Form.Item>
        <Form.Item
          name="tags"
          label={t('siteList.createSiteModal.form.tagsLabel')}
          rules={[
            {
              type: 'array',
              max: TAGS_MAX_SIZE,
              message: t('siteList.createSiteModal.errors.maxTagSize', { size: TAGS_MAX_SIZE }),
            },
          ]}
        >
          <Select
            showSearch={true}
            searchValue={searchVal}
            onReset={(): void => {
              setSearchValue('');
              searchTags('');
            }}
            onSearch={handleSearch}
            loading={areTagsLoading}
            maxTagTextLength={20}
            maxTagCount={3}
            {...SelectUtils.getMultiSelectionProps({
              mode: 'tags',
              options: [...new Set(tags)].map(tag => ({ label: tag, value: tag })),
              onDropdownVisibleChange: (isOpen: boolean): void => {
                setIsTagsSelectOpen(isOpen);
                setSearchValue('');
                searchTags('');
              },
              dropdownVisibleState: isTagsSelectOpen,
              valueArray: selectedTags,
              dropdownLabel: t('machineModals.editMachineInfo.form.placeholder.default'),
            })}
          />
        </Form.Item>
        {isListManagersEnabled && (
          <PermissionGuard requiredPermissions={[Permission.Site.StrategicManager.UPDATE]}>
            <Form.Item
              name="siteManagers"
              label={t('siteList.createSiteModal.siteManager')}
              className="create-site-modal__input-group"
            >
              <Select
                className="create-site-modal__select"
                loading={isManagersLoading}
                options={managers.map(manager => ({
                  label: manager.displayName,
                  value: manager.id,
                }))}
                onDropdownVisibleChange={(isOpen: boolean): void => setIsSiteMangersOpen(isOpen)}
                dropdownVisibleState={isSiteMangersOpen}
                value={siteManagers}
                placeholder={t('machineModals.editMachineInfo.form.placeholder.default')}
                mode="tags"
              />
            </Form.Item>
          </PermissionGuard>
        )}
      </Form>
    </StyledCreateSiteModal>
  );
};
