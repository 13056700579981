import { Action } from 'redux';
import {
  IActionCreatorErrorOptions,
  IErrorAction,
} from '../../../../cross-cutting-concerns/state-management/interfaces/StateManagement.types';
import { SiteList, SiteCreate, SiteTagsList } from '../../interfaces/Site.types';
import { SiteClientCreateOptions, SiteClientListOptions, SiteClientListTagsOptions } from '../../SiteClient';
import { SiteServiceListTagsOptions } from '../../SiteService';
import { Optional } from 'lib/types/Optional';
import { SortOrders } from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';

// GET_SITE_LIST
export type IGetSiteListRequestOptions = SiteClientListOptions;

export type IGetSiteListRequestAction = Action<typeof SiteListActions.GET_SITE_LIST_REQUEST> & {
  payload: SiteClientListOptions;
};

export type IGetSiteListSuccessOptions = SiteList;
export type IGetSiteListSuccessAction = Action<typeof SiteListActions.GET_SITE_LIST_SUCCESS> & {
  payload: SiteList;
};

export type IGetSiteListErrorAction = IErrorAction<typeof SiteListActions.GET_SITE_LIST_ERROR>;

// GET_SITE_LIST_MORE_DATA
export type IGetSiteListMoreDataRequestOptions = SiteClientListOptions;

export type IGetSiteListMoreDataRequestAction = Action<typeof SiteListActions.GET_SITE_LIST_MORE_DATA_REQUEST> & {
  payload: SiteClientListOptions;
};

export type IGetSiteListMoreDataSuccessOptions = SiteList;
export type IGetSiteListMoreDataSuccessAction = Action<typeof SiteListActions.GET_SITE_LIST_MORE_DATA_SUCCESS> & {
  payload: SiteList;
};

export type IGetSiteListMoreDataErrorAction = IErrorAction<typeof SiteListActions.GET_SITE_LIST_MORE_DATA_ERROR>;

export type ICreateSiteRequestOptions = SiteClientCreateOptions;

export type ICreateSiteRequestAction = Action<typeof SiteListActions.CREATE_SITE_REQUEST> & {
  payload: SiteClientCreateOptions;
};

export type ICreateSiteSuccessOptions = Optional<SiteCreate>;
export type ICreateSiteSuccessAction = Action<typeof SiteListActions.CREATE_SITE_REQUEST> & {
  payload: Optional<SiteCreate>;
};

export type ICreateSiteErrorAction = IErrorAction<typeof SiteListActions.CREATE_SITE_ERROR>;

export type IChangeSiteListTablePage = Action<typeof SiteListActions.CHANGE_TABLE_PAGE> & {
  payload: {
    page: number;
  };
};

export type IChangeSiteListTablePageSize = Action<typeof SiteListActions.CHANGE_TABLE_PAGE_SIZE> & {
  payload: {
    pageSize: number;
  };
};

export type ISetSearchTextFilter = Action<typeof SiteListActions.SET_SEARCH_TEXT_FILTER> & {
  payload: {
    searchText: Optional<string>;
  };
};

export type ISetActiveSortField = Action<typeof SiteListActions.SET_ACTIVE_SORT_FIELD> & {
  payload: {
    field: Optional<string>;
  };
};

export type ISetActiveSortOrder = Action<typeof SiteListActions.SET_ACTIVE_SORT_ORDER> & {
  payload: {
    order: Optional<SortOrders>;
  };
};

export type ISetActiveTagsFilter = Action<typeof SiteListActions.SET_ACTIVE_TAGS_FILTER> & {
  payload: {
    tags: Optional<string[]>;
  };
};

export type IListTagsRequestOptions = SiteServiceListTagsOptions;
export type IListTagsRequestAction = Action<typeof SiteListActions.LIST_TAGS_REQUEST> & {
  payload: SiteClientListTagsOptions;
};

export type IListTagsSuccessOptions = SiteTagsList;
export type IListTagsSuccessAction = Action<typeof SiteListActions.LIST_TAGS_SUCCESS> & {
  payload: SiteTagsList;
};

export type IListTagsErrorAction = IErrorAction<typeof SiteListActions.LIST_TAGS_ERROR>;

export class SiteListActions {
  public static GET_SITE_LIST_REQUEST = 'SITE_LIST::GET_SITE_LIST_REQUEST';
  public static GET_SITE_LIST_SUCCESS = 'SITE_LIST::GET_SITE_LIST_SUCCESS';
  public static GET_SITE_LIST_ERROR = 'SITE_LIST::GET_SITE_LIST_ERROR';

  public static GET_SITE_LIST_MORE_DATA_REQUEST = 'SITE_LIST::GET_SITE_LIST_MORE_DATA_REQUEST';
  public static GET_SITE_LIST_MORE_DATA_SUCCESS = 'SITE_LIST::GET_SITE_LIST_MORE_DATA_SUCCESS';
  public static GET_SITE_LIST_MORE_DATA_ERROR = 'SITE_LIST::GET_SITE_LIST_MORE_DATA_ERROR';

  public static CREATE_SITE_REQUEST = 'SITE_LIST::CREATE_SITE_REQUEST';
  public static CREATE_SITE_SUCCESS = 'SITE_LIST::CREATE_SITE_SUCCESS';
  public static CREATE_SITE_ERROR = 'SITE_LIST::CREATE_SITE_ERROR';

  public static CHANGE_TABLE_PAGE = 'SITE_LIST::CHANGE_TABLE_PAGE';
  public static CHANGE_TABLE_PAGE_SIZE = 'SITE_LIST::CHANGE_TABLE_PAGE_SIZE';
  public static RESET_STATE = 'SITE_LIST::RESET_STATE';

  public static SET_SEARCH_TEXT_FILTER = 'SITE_LIST::SET_SEARCH_TEXT_FILTER';

  public static SET_ACTIVE_SORT_FIELD = 'SITE_LIST::SET_ACTIVE_SORT_FIELD';
  public static SET_ACTIVE_SORT_ORDER = 'SITE_LIST::SET_ACTIVE_SORT_ORDER';

  public static SET_ACTIVE_TAGS_FILTER = 'SITE_LIST::SET_ACTIVE_TAGS_FILTER';

  public static LIST_TAGS_REQUEST = 'SITE_LIST::LIST_TAGS_REQUEST';
  public static LIST_TAGS_SUCCESS = 'SITE_LIST::LIST_TAGS_SUCCESS';
  public static LIST_TAGS_ERROR = 'SITE_LIST::LIST_TAGS_ERROR';

  public static getSiteListRequest({
    paginationOptions,
    filter,
    sortOptions,
  }: IGetSiteListRequestOptions): IGetSiteListRequestAction {
    return {
      type: SiteListActions.GET_SITE_LIST_REQUEST,
      payload: {
        paginationOptions,
        filter,
        sortOptions,
      },
    };
  }

  public static getSiteListSuccess(data: IGetSiteListSuccessOptions): IGetSiteListSuccessAction {
    return {
      type: SiteListActions.GET_SITE_LIST_SUCCESS,
      payload: data,
    };
  }

  public static getSiteListError({ error }: IActionCreatorErrorOptions): IGetSiteListErrorAction {
    return {
      type: SiteListActions.GET_SITE_LIST_ERROR,
      error,
    };
  }

  public static getSiteListMoreDataRequest({
    paginationOptions,
    filter,
    sortOptions,
  }: IGetSiteListMoreDataRequestOptions): IGetSiteListMoreDataRequestAction {
    return {
      type: SiteListActions.GET_SITE_LIST_MORE_DATA_REQUEST,
      payload: {
        paginationOptions,
        filter,
        sortOptions,
      },
    };
  }

  public static getSiteListMoreDataSuccess(
    data: IGetSiteListMoreDataSuccessOptions
  ): IGetSiteListMoreDataSuccessAction {
    return {
      type: SiteListActions.GET_SITE_LIST_MORE_DATA_SUCCESS,
      payload: data,
    };
  }

  public static getSiteListMoreDataError({ error }: IActionCreatorErrorOptions): IGetSiteListMoreDataErrorAction {
    return {
      type: SiteListActions.GET_SITE_LIST_MORE_DATA_ERROR,
      error,
    };
  }

  public static createSiteRequest({ input }: ICreateSiteRequestOptions): ICreateSiteRequestAction {
    return {
      type: SiteListActions.CREATE_SITE_REQUEST,
      payload: {
        input,
      },
    };
  }

  public static createSiteSuccess(data: ICreateSiteSuccessOptions): ICreateSiteSuccessAction {
    return {
      type: SiteListActions.CREATE_SITE_SUCCESS,
      payload: data,
    };
  }

  public static createSiteError({ error }: IActionCreatorErrorOptions): ICreateSiteErrorAction {
    return {
      type: SiteListActions.CREATE_SITE_ERROR,
      error,
    };
  }

  public static changeTablePage(data: { page: number }): IChangeSiteListTablePage {
    return {
      type: SiteListActions.CHANGE_TABLE_PAGE,
      payload: data,
    };
  }

  public static changeTablePageSize(data: { pageSize: number }): IChangeSiteListTablePageSize {
    return {
      type: SiteListActions.CHANGE_TABLE_PAGE_SIZE,
      payload: data,
    };
  }

  public static resetState(): Action<typeof SiteListActions.RESET_STATE> {
    return {
      type: SiteListActions.RESET_STATE,
    };
  }

  public static setSearchText(searchText: Optional<string>): ISetSearchTextFilter {
    return {
      type: SiteListActions.SET_SEARCH_TEXT_FILTER,
      payload: {
        searchText,
      },
    };
  }

  public static setActiveSortField(field: Optional<string>): ISetActiveSortField {
    return {
      type: SiteListActions.SET_ACTIVE_SORT_FIELD,
      payload: {
        field,
      },
    };
  }

  public static setActiveSortOrder(order: Optional<SortOrders>): ISetActiveSortOrder {
    return {
      type: SiteListActions.SET_ACTIVE_SORT_ORDER,
      payload: {
        order,
      },
    };
  }

  public static setActiveTagsFilter(tags: Optional<string[]>): ISetActiveTagsFilter {
    return {
      type: SiteListActions.SET_ACTIVE_TAGS_FILTER,
      payload: {
        tags,
      },
    };
  }

  public static listTagsRequest({ filter, paginationOptions }: IListTagsRequestOptions): IListTagsRequestAction {
    return {
      type: SiteListActions.LIST_TAGS_REQUEST,
      payload: {
        filter,
        paginationOptions,
      },
    };
  }

  public static listTagsSuccess(data: IListTagsSuccessOptions): IListTagsSuccessAction {
    return {
      type: SiteListActions.LIST_TAGS_SUCCESS,
      payload: data,
    };
  }

  public static listTagsError({ error }: IActionCreatorErrorOptions): IListTagsErrorAction {
    return {
      type: SiteListActions.LIST_TAGS_ERROR,
      error,
    };
  }
}
