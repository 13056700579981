import { Action } from 'redux';
import {
  InputCircle,
  InputSiteTagsOverride,
  InputSiteUpdate,
} from '../../../../cross-cutting-concerns/communication/interfaces/am-api-graphql';
import {
  IActionCreatorErrorOptions,
  IErrorAction,
} from '../../../../cross-cutting-concerns/state-management/interfaces/StateManagement.types';
import { SiteClientDeleteOptions } from '../../SiteClient';
import {
  SiteDelete,
  SiteAssignMachineResponse,
  SiteDetails,
  SiteUnassignMachineResponse,
  SiteUpdateResponse,
  SiteGeofenceSetupResponse,
  SiteAssignManagersResponse,
  SiteUnassignManagerResponse,
  SiteMachineVariantData,
  SiteTagsUpdateResponse,
} from '../../interfaces/Site.types';
import {
  SiteServiceAssignMachineOptions,
  SiteServiceAssignManagersOptions,
  SiteServiceDetailsOptions,
  SiteServiceSetGeofenceOptions,
  SiteServiceUnassignMachineOptions,
  SiteServiceUnassignManagerOptions,
  SiteServiceUpdateOptions,
  SiteServiceUpdateSiteTagsOptions,
} from '../../SiteService';
import { Optional } from 'lib/types/Optional';
import { CleaningActions } from 'app/modules/cleaning/state/cleaningActions';

export type IGetSiteDetailsPanelRequestOptions = SiteServiceDetailsOptions;
export type IGetSiteDetailsPanelRequestAction = Action<typeof SiteDetailsPanelActions.GET_SITE_DETAILS_REQUEST> & {
  payload: SiteServiceDetailsOptions;
};

export type IGetSiteDetailsPanelSuccessOptions = SiteDetails;
export type IGetSiteDetailsPanelSuccessAction = Action<typeof SiteDetailsPanelActions.GET_SITE_DETAILS_SUCCESS> & {
  payload: SiteDetails;
};

export type IGetSiteDetailsPanelMachinePicturesSuccessOptions = SiteMachineVariantData;
export type IGetSiteDetailsPanelMachinePicturesSuccessAction = Action<
  typeof SiteDetailsPanelActions.GET_SITE_DETAILS_MACHINE_PICTURES_SUCCESS
> & {
  payload: SiteMachineVariantData;
};

export type IGetSiteDetailsPanelErrorAction = IErrorAction<typeof SiteDetailsPanelActions.GET_SITE_DETAILS_ERROR>;

export type IUpdateSiteRequestOptions = SiteServiceUpdateOptions;
export type IUpdateSiteRequestAction = Action<typeof SiteDetailsPanelActions.UPDATE_SITE_REQUEST> & {
  payload: SiteServiceUpdateOptions;
};

export type IUpdateSiteSuccessOptions = Optional<SiteUpdateResponse>;
export type IUpdateSiteSuccessAction = Action<typeof SiteDetailsPanelActions.UPDATE_SITE_SUCCESS> & {
  payload: Optional<SiteUpdateResponse>;
};

export type IUpdateSiteErrorAction = IErrorAction<typeof SiteDetailsPanelActions.UPDATE_SITE_ERROR>;

export type IUpdateSiteLocationRequestOptions = Omit<SiteServiceUpdateOptions, 'input'> & {
  currentSiteAddress: string;
  input: {
    location: {
      address: string;
    };
  };
};
export type IUpdateSiteLocationRequestAction = Action<typeof SiteDetailsPanelActions.UPDATE_SITE_LOCATION_REQUEST> & {
  payload: IUpdateSiteLocationRequestOptions;
};

export type IUpdateSiteLocationSuccessOptions = Optional<SiteUpdateResponse>;
export type IUpdateSiteLocationSuccessAction = Action<typeof SiteDetailsPanelActions.UPDATE_SITE_LOCATION_SUCCESS> & {
  payload: Optional<SiteUpdateResponse>;
};

export type IUpdateSiteLocationErrorAction = IErrorAction<typeof SiteDetailsPanelActions.UPDATE_SITE_LOCATION_ERROR>;

export type IUpdateSiteTagsRequestOptions = SiteServiceUpdateSiteTagsOptions;
export type IUpdateSiteTagsRequestAction = Action<typeof SiteDetailsPanelActions.UPDATE_SITE_TAGS_REQUEST> & {
  payload: SiteServiceUpdateSiteTagsOptions;
};

export type IUpdateSiteTagsSuccessOptions = Optional<SiteTagsUpdateResponse>;
export type IUpdateSiteTagsSuccessAction = Action<typeof SiteDetailsPanelActions.UPDATE_SITE_TAGS_REQUEST> & {
  payload: Optional<SiteTagsUpdateResponse>;
};

export type IUpdateSiteTagsErrorAction = IErrorAction<typeof SiteDetailsPanelActions.UPDATE_SITE_TAGS_ERROR>;

export type IAssignMachineToSiteRequestOptions = SiteServiceAssignMachineOptions;
export type IAssignMachineToSiteRequestAction = Action<
  typeof SiteDetailsPanelActions.ASSIGN_MACHINE_TO_SITE_REQUEST
> & {
  payload: SiteServiceAssignMachineOptions;
};

export type IAssignMachineToSiteSuccessOptions = Optional<SiteAssignMachineResponse>;
export type IAssignMachineToSiteSuccessAction = Action<
  typeof SiteDetailsPanelActions.ASSIGN_MACHINE_TO_SITE_SUCCESS
> & {
  payload: Optional<SiteAssignMachineResponse>;
};

export type IAssignMachineToSiteErrorAction = IErrorAction<typeof SiteDetailsPanelActions.ASSIGN_MACHINE_TO_SITE_ERROR>;

export type IUnassignMachineFromSiteRequestOptions = SiteServiceUnassignMachineOptions;
export type IUnassignMachineFromSiteRequestAction = Action<
  typeof SiteDetailsPanelActions.UNASSIGN_MACHINE_FROM_SITE_REQUEST
> & {
  payload: SiteServiceUnassignMachineOptions;
};

export type IUnassignMachineFromSiteSuccessOptions = Optional<SiteUnassignMachineResponse>;
export type IUnassignMachineFromSiteSuccessAction = Action<
  typeof SiteDetailsPanelActions.UNASSIGN_MACHINE_FROM_SITE_SUCCESS
> & {
  payload: Optional<SiteUnassignMachineResponse>;
};

export type IUnassignMachineFromSiteErrorAction = IErrorAction<
  typeof SiteDetailsPanelActions.UNASSIGN_MACHINE_FROM_SITE_ERROR
>;

export type IChangeSiteMachineListTablePage = Action<typeof SiteDetailsPanelActions.CHANGE_TABLE_PAGE> & {
  payload: {
    page: number;
  };
};

export type IChangeSiteMachineListTablePageSize = Action<typeof SiteDetailsPanelActions.CHANGE_TABLE_PAGE_SIZE> & {
  payload: {
    pageSize: number;
  };
};

export type IDeleteSiteRequestOptions = SiteClientDeleteOptions;
export type IDeleteSiteRequestAction = Action<typeof SiteDetailsPanelActions.DELETE_SITE_REQUEST> & {
  payload: SiteClientDeleteOptions;
};

export type IDeleteSiteSuccessOptions = Optional<SiteDelete>;
export type IDeleteSiteSuccessAction = Action<typeof SiteDetailsPanelActions.DELETE_SITE_SUCCESS> & {
  payload: Optional<SiteDelete>;
};

export type IDeleteSiteErrorAction = IErrorAction<typeof SiteDetailsPanelActions.DELETE_SITE_ERROR>;

export type ISetGeofenceRequestOptions = SiteServiceSetGeofenceOptions;
export type ISetGeofenceRequestAction = Action<typeof SiteDetailsPanelActions.SET_GEOFENCE_REQUEST> & {
  payload: SiteServiceSetGeofenceOptions;
};

export type ISetGeofenceSuccessOptions = Optional<SiteGeofenceSetupResponse>;
export type ISetGeofenceSuccessAction = Action<typeof SiteDetailsPanelActions.SET_GEOFENCE_REQUEST> & {
  payload: Optional<SiteGeofenceSetupResponse>;
};

export type ISetGeofenceErrorAction = IErrorAction<typeof SiteDetailsPanelActions.SET_GEOFENCE_ERROR>;

export type IPollSiteDetailsPanelWithGeofenceActionOptions = SiteServiceDetailsOptions & {
  geofence: {
    circle: InputCircle;
  };
};
export type IPollSiteDetailsPanelWithGeofenceAction = Action<
  typeof SiteDetailsPanelActions.POLL_SITE_DETAILS_WITH_GEOFENCE
> & {
  payload: IPollSiteDetailsPanelWithGeofenceActionOptions;
};

export type ISiteDetailsPanelMapIsLoadingAction = Action<typeof SiteDetailsPanelActions.MAP_IS_LOADING>;
export type ISiteDetailsPanelMapIsNotLoadingAction = Action<typeof SiteDetailsPanelActions.MAP_IS_NOT_LOADING>;

export interface ISetGeofenceAfterSiteLocationIsInitializedOptions {
  siteId: string;
  geofence: {
    circle: InputCircle;
  };
}
export type ISetGeofenceAfterSiteLocationIsInitializedAction = Action<
  typeof SiteDetailsPanelActions.SET_GEOFENCE_AFTER_SITE_LOCATION_IS_INITIALIZED
> & {
  payload: ISetGeofenceAfterSiteLocationIsInitializedOptions;
};

// ASSIGN_MANAGERS

export type IAssignManagersToSiteRequestOptions = SiteServiceAssignManagersOptions;
export type IAssignManagersToSiteRequestAction = Action<
  typeof SiteDetailsPanelActions.ASSIGN_MANAGERS_TO_SITE_REQUEST
> & {
  payload: SiteServiceAssignManagersOptions;
};

export type IAssignManagersToSiteSuccessOptions = Optional<SiteAssignManagersResponse>;
export type IAssignManagersToSiteSuccessAction = Action<
  typeof SiteDetailsPanelActions.ASSIGN_MANAGERS_TO_SITE_SUCCESS
> & {
  payload: Optional<SiteAssignManagersResponse>;
};

export type IAssignManagersToSiteErrorAction = IErrorAction<
  typeof SiteDetailsPanelActions.ASSIGN_MANAGERS_TO_SITE_ERROR
>;

// UNASSIGN_MANAGER

export type IUnassignManagerFromSiteRequestOptions = SiteServiceUnassignManagerOptions;
export type IUnassignManagerFromSiteRequestAction = Action<
  typeof SiteDetailsPanelActions.UNASSIGN_MANAGER_FROM_SITE_REQUEST
> & {
  payload: SiteServiceUnassignManagerOptions;
};

export type IUnassignManagerFromSiteSuccessOptions = Optional<SiteUnassignManagerResponse>;
export type IUnassignManagerFromSiteSuccessAction = Action<
  typeof SiteDetailsPanelActions.UNASSIGN_MANAGER_FROM_SITE_SUCCESS
> & {
  payload: Optional<SiteUnassignManagerResponse>;
};

export type IUnassignManagerFromSiteErrorAction = IErrorAction<
  typeof SiteDetailsPanelActions.UNASSIGN_MANAGER_FROM_SITE_ERROR
>;

export interface IEditSiteInfoRequestOptions {
  id: string;
  input: {
    inputSite: InputSiteUpdate;
    inputTag: InputSiteTagsOverride;
  };
}

export type IEditSiteInfoRequestAction = Action<typeof SiteDetailsPanelActions.EDIT_SITE_INFO_REQUEST> & {
  payload: IEditSiteInfoRequestOptions;
};

export class SiteDetailsPanelActions {
  public static GET_SITE_DETAILS_REQUEST = 'SITE_DETAILS::GET_SITE_DETAILS_PANEL_REQUEST';
  public static GET_SITE_DETAILS_SUCCESS = 'SITE_DETAILS::GET_SITE_DETAILS_PANEL_SUCCESS';
  public static GET_SITE_DETAILS_MACHINE_PICTURES_SUCCESS =
    'SITE_DETAILS::GET_SITE_DETAILS_MACHINE_PICTURES_PANEL_SUCCESS';

  public static GET_SITE_DETAILS_ERROR = 'SITE_DETAILS::GET_SITE_DETAILS_PANEL_ERROR';

  public static GET_SITE_CLEANING_PLAN_REQUEST = 'SITE_DETAILS::GET_SITE_CLEANING_PLAN_PANEL_REQUEST';
  public static GET_SITE_CLEANING_PLAN_SUCCESS = 'SITE_DETAILS::GET_SITE_CLEANING_PLAN_PANEL_SUCCESS';
  public static GET_SITE_CLEANING_PLAN_ERROR = 'SITE_DETAILS::GET_SITE_CLEANING_PLAN_PANEL_ERROR';

  public static UPDATE_SITE_REQUEST = 'SITE_DETAILS::UPDATE_SITE_PANEL_REQUEST';
  public static UPDATE_SITE_SUCCESS = 'SITE_DETAILS::UPDATE_SITE_PANEL_SUCCESS';
  public static UPDATE_SITE_ERROR = 'SITE_DETAILS::UPDATE_SITE_PANEL_ERROR';

  public static UPDATE_SITE_LOCATION_REQUEST = 'SITE_DETAILS::UPDATE_SITE_LOCATION_PANEL_REQUEST';
  public static UPDATE_SITE_LOCATION_SUCCESS = 'SITE_DETAILS::UPDATE_SITE_LOCATION_PANEL_SUCCESS';
  public static UPDATE_SITE_LOCATION_ERROR = 'SITE_DETAILS::UPDATE_SITE_LOCATION_PANEL_ERROR';

  public static UPDATE_SITE_TAGS_REQUEST = 'SITE_DETAILS::UPDATE_SITE_TAGS_PANEL_REQUEST';
  public static UPDATE_SITE_TAGS_SUCCESS = 'SITE_DETAILS::UPDATE_SITE_TAGS_PANEL_SUCCESS';
  public static UPDATE_SITE_TAGS_ERROR = 'SITE_DETAILS::UPDATE_SITE_TAGS_PANEL_ERROR';

  public static ASSIGN_MACHINE_TO_SITE_REQUEST = 'SITE_DETAILS::ASSIGN_MACHINE_TO_SITE_PANEL_REQUEST';
  public static ASSIGN_MACHINE_TO_SITE_SUCCESS = 'SITE_DETAILS::ASSIGN_MACHINE_TO_SITE_PANEL_SUCCESS';
  public static ASSIGN_MACHINE_TO_SITE_ERROR = 'SITE_DETAILS::ASSIGN_MACHINE_TO_SITE_PANEL_ERROR';

  public static UNASSIGN_MACHINE_FROM_SITE_REQUEST = 'SITE_DETAILS::UNASSIGN_MACHINE_FROM_SITE_PANEL_REQUEST';
  public static UNASSIGN_MACHINE_FROM_SITE_SUCCESS = 'SITE_DETAILS::UNASSIGN_MACHINE_FROM_SITE_PANEL_SUCCESS';
  public static UNASSIGN_MACHINE_FROM_SITE_ERROR = 'SITE_DETAILS::UNASSIGN_MACHINE_FROM_SITE_PANEL_ERROR';

  public static CHANGE_TABLE_PAGE = 'SITE_DETAILS::CHANGE_TABLE_PAGE_PANEL';
  public static CHANGE_TABLE_PAGE_SIZE = 'SITE_DETAILS::CHANGE_TABLE_PAGE_SIZE_PANEL';
  public static RESET_STATE = 'SITE_DETAILS::RESET_STATE_PANEL';

  public static DELETE_SITE_REQUEST = 'SITE_DETAILS::DELETE_SITE_PANEL_REQUEST';
  public static DELETE_SITE_SUCCESS = 'SITE_DETAILS::DELETE_SITE_PANEL_SUCCESS';
  public static DELETE_SITE_ERROR = 'SITE_DETAILS::DELETE_SITE_PANEL_ERROR';

  public static SET_GEOFENCE_REQUEST = 'SITE_DETAILS::SET_GEOFENCE_PANEL_REQUEST';
  public static SET_GEOFENCE_SUCCESS = 'SITE_DETAILS::SET_GEOFENCE_PANEL_SUCCESS';
  public static SET_GEOFENCE_ERROR = 'SITE_DETAILS::SET_GEOFENCE_PANEL_ERROR';

  public static ASSIGN_MANAGERS_TO_SITE_REQUEST = 'SITE_DETAILS::ASSIGN_MANAGERS_TO_SITE_PANEL_REQUEST';
  public static ASSIGN_MANAGERS_TO_SITE_SUCCESS = 'SITE_DETAILS::ASSIGN_MANAGERS_TO_SITE_PANEL_SUCCESS';
  public static ASSIGN_MANAGERS_TO_SITE_ERROR = 'SITE_DETAILS::ASSIGN_MANAGERS_TO_SITE_PANEL_ERROR';

  public static UNASSIGN_MANAGER_FROM_SITE_REQUEST = 'SITE_DETAILS::UNASSIGN_MANAGER_FROM_SITE_PANEL_REQUEST';
  public static UNASSIGN_MANAGER_FROM_SITE_SUCCESS = 'SITE_DETAILS::UNASSIGN_MANAGER_FROM_SITE_PANEL_SUCCESS';
  public static UNASSIGN_MANAGER_FROM_SITE_ERROR = 'SITE_DETAILS::UNASSIGN_MANAGER_FROM_SITE_PANEL_ERROR';

  public static SET_GEOFENCE_AFTER_SITE_LOCATION_IS_INITIALIZED =
    'SITE_DETAILS::SET_GEOFENCE_AFTER_SITE_LOCATION_IS_INITIALIZED_PANEL';

  public static POLL_SITE_DETAILS_WITH_GEOFENCE = 'SITE_DETAILS::START_POLL_SITE_DETAILS_WITH_GEOFENCE__PANELREQUEST';

  public static MAP_IS_LOADING = 'SITE_DETAILS::MAP_IS_LOADING_PANEL';
  public static MAP_IS_NOT_LOADING = 'SITE_DETAILS::SET_MAP_IS_NOT_LOADING_PANEL';

  public static RESET_CREATE_WORK_INTERVAL_ERROR = 'SITE_DETAILS::RESET_CREATE_WORK_INTERVAL_PANEL_ERROR';
  public static RESET_UPDATE_WORK_INTERVAL_ERROR = 'SITE_DETAILS::RESET_UPDATE_WORK_INTERVAL_PANEL_ERROR';

  public static EDIT_SITE_INFO_REQUEST = 'SITE_DETAILS::EDIT_SITE_INFO_PANEL_REQUEST';

  public static getSiteDetailsPanelRequest({
    id,
    machinePaginationOptions,
  }: IGetSiteDetailsPanelRequestOptions): IGetSiteDetailsPanelRequestAction {
    return {
      type: SiteDetailsPanelActions.GET_SITE_DETAILS_REQUEST,
      payload: {
        id,
        machinePaginationOptions,
      },
    };
  }

  public static getSiteDetailsPanelSuccess(
    data: IGetSiteDetailsPanelSuccessOptions
  ): IGetSiteDetailsPanelSuccessAction {
    return {
      type: SiteDetailsPanelActions.GET_SITE_DETAILS_SUCCESS,
      payload: data,
    };
  }

  public static getSiteDetailsPanelMachinePicturesSuccess(
    data: IGetSiteDetailsPanelMachinePicturesSuccessOptions
  ): IGetSiteDetailsPanelMachinePicturesSuccessAction {
    return {
      type: SiteDetailsPanelActions.GET_SITE_DETAILS_MACHINE_PICTURES_SUCCESS,
      payload: data,
    };
  }

  public static getSiteDetailsPanelError({ error }: IActionCreatorErrorOptions): IGetSiteDetailsPanelErrorAction {
    return {
      type: SiteDetailsPanelActions.GET_SITE_DETAILS_ERROR,
      error,
    };
  }

  public static updateSiteRequest({ id, input }: IUpdateSiteRequestOptions): IUpdateSiteRequestAction {
    return {
      type: SiteDetailsPanelActions.UPDATE_SITE_REQUEST,
      payload: {
        id,
        input,
      },
    };
  }

  public static updateSiteSuccess(data: IUpdateSiteSuccessOptions): IUpdateSiteSuccessAction {
    return {
      type: SiteDetailsPanelActions.UPDATE_SITE_SUCCESS,
      payload: data,
    };
  }

  public static updateSiteError({ error }: IActionCreatorErrorOptions): IUpdateSiteErrorAction {
    return {
      type: SiteDetailsPanelActions.UPDATE_SITE_ERROR,
      error,
    };
  }

  public static updateSiteLocationRequest({
    id,
    input,
    currentSiteAddress,
  }: IUpdateSiteLocationRequestOptions): IUpdateSiteLocationRequestAction {
    return {
      type: SiteDetailsPanelActions.UPDATE_SITE_LOCATION_REQUEST,
      payload: {
        id,
        input,
        currentSiteAddress,
      },
    };
  }

  public static updateSiteLocationSuccess(data: IUpdateSiteLocationSuccessOptions): IUpdateSiteLocationSuccessAction {
    return {
      type: SiteDetailsPanelActions.UPDATE_SITE_LOCATION_SUCCESS,
      payload: data,
    };
  }

  public static updateSiteLocationError({ error }: IActionCreatorErrorOptions): IUpdateSiteLocationErrorAction {
    return {
      type: SiteDetailsPanelActions.UPDATE_SITE_LOCATION_ERROR,
      error,
    };
  }

  public static updateSiteTagsRequest({ id, input }: IUpdateSiteTagsRequestOptions): IUpdateSiteTagsRequestAction {
    return {
      type: SiteDetailsPanelActions.UPDATE_SITE_TAGS_REQUEST,
      payload: {
        id,
        input,
      },
    };
  }

  public static updateSiteTagsSuccess(data: IUpdateSiteTagsSuccessOptions): IUpdateSiteTagsSuccessAction {
    return {
      type: SiteDetailsPanelActions.UPDATE_SITE_TAGS_SUCCESS,
      payload: data,
    };
  }

  public static updateSiteTagsError({ error }: IActionCreatorErrorOptions): IUpdateSiteTagsErrorAction {
    return {
      type: SiteDetailsPanelActions.UPDATE_SITE_TAGS_ERROR,
      error,
    };
  }

  public static assignMachineToSiteRequest({
    siteId,
    machineIds,
  }: IAssignMachineToSiteRequestOptions): IAssignMachineToSiteRequestAction {
    return {
      type: SiteDetailsPanelActions.ASSIGN_MACHINE_TO_SITE_REQUEST,
      payload: {
        siteId,
        machineIds,
      },
    };
  }

  public static assignMachineToSiteSuccess(
    data: IAssignMachineToSiteSuccessOptions
  ): IAssignMachineToSiteSuccessAction {
    return {
      type: SiteDetailsPanelActions.ASSIGN_MACHINE_TO_SITE_SUCCESS,
      payload: data,
    };
  }

  public static assignMachineToSiteError({ error }: IActionCreatorErrorOptions): IAssignMachineToSiteErrorAction {
    return {
      type: SiteDetailsPanelActions.ASSIGN_MACHINE_TO_SITE_ERROR,
      error,
    };
  }

  public static unassignMachineFromSiteRequest({
    siteId,
    machineId,
  }: IUnassignMachineFromSiteRequestOptions): IUnassignMachineFromSiteRequestAction {
    return {
      type: SiteDetailsPanelActions.UNASSIGN_MACHINE_FROM_SITE_REQUEST,
      payload: {
        siteId,
        machineId,
      },
    };
  }

  public static unassignMachineFromSiteSuccess(
    data: IUnassignMachineFromSiteSuccessOptions
  ): IUnassignMachineFromSiteSuccessAction {
    return {
      type: SiteDetailsPanelActions.UNASSIGN_MACHINE_FROM_SITE_SUCCESS,
      payload: data,
    };
  }

  public static unassignMachineFromSiteError({
    error,
  }: IActionCreatorErrorOptions): IUnassignMachineFromSiteErrorAction {
    return {
      type: SiteDetailsPanelActions.UNASSIGN_MACHINE_FROM_SITE_ERROR,
      error,
    };
  }

  public static changeTablePage(data: { page: number }): IChangeSiteMachineListTablePage {
    return {
      type: SiteDetailsPanelActions.CHANGE_TABLE_PAGE,
      payload: data,
    };
  }

  public static changeTablePageSize(data: { pageSize: number }): IChangeSiteMachineListTablePageSize {
    return {
      type: SiteDetailsPanelActions.CHANGE_TABLE_PAGE_SIZE,
      payload: data,
    };
  }

  public static resetState(): Action<typeof SiteDetailsPanelActions.RESET_STATE> {
    return {
      type: SiteDetailsPanelActions.RESET_STATE,
    };
  }

  public static deleteSiteRequest({ id }: IDeleteSiteRequestOptions): IDeleteSiteRequestAction {
    return {
      type: SiteDetailsPanelActions.DELETE_SITE_REQUEST,
      payload: {
        id,
      },
    };
  }

  public static deleteSiteSuccess(data: IDeleteSiteSuccessOptions): IDeleteSiteSuccessAction {
    return {
      type: SiteDetailsPanelActions.DELETE_SITE_SUCCESS,
      payload: data,
    };
  }

  public static deleteSiteError({ error }: IActionCreatorErrorOptions): IDeleteSiteErrorAction {
    return {
      type: SiteDetailsPanelActions.DELETE_SITE_ERROR,
      error,
    };
  }

  public static setGeofenceRequest({ siteId, geofence }: ISetGeofenceRequestOptions): ISetGeofenceRequestAction {
    return {
      type: SiteDetailsPanelActions.SET_GEOFENCE_REQUEST,
      payload: {
        siteId,
        geofence,
      },
    };
  }

  public static setGeofenceSuccess(data: ISetGeofenceSuccessOptions): ISetGeofenceSuccessAction {
    return {
      type: SiteDetailsPanelActions.SET_GEOFENCE_SUCCESS,
      payload: data,
    };
  }

  public static setGeofenceError({ error }: IActionCreatorErrorOptions): ISetGeofenceErrorAction {
    return {
      type: SiteDetailsPanelActions.SET_GEOFENCE_ERROR,
      error,
    };
  }

  public static pollSiteDetailsPanelWithGeofence({
    id,
    machinePaginationOptions,
    geofence,
  }: IPollSiteDetailsPanelWithGeofenceActionOptions): IPollSiteDetailsPanelWithGeofenceAction {
    return {
      type: SiteDetailsPanelActions.POLL_SITE_DETAILS_WITH_GEOFENCE,
      payload: {
        id,
        machinePaginationOptions,
        geofence,
      },
    };
  }

  public static mapIsLoading(): ISiteDetailsPanelMapIsLoadingAction {
    return {
      type: SiteDetailsPanelActions.MAP_IS_LOADING,
    };
  }

  public static mapIsNotLoading(): ISiteDetailsPanelMapIsNotLoadingAction {
    return {
      type: SiteDetailsPanelActions.MAP_IS_NOT_LOADING,
    };
  }

  public static setGeofenceAfterSiteLocationIsInitialized({
    siteId,
    geofence,
  }: ISetGeofenceAfterSiteLocationIsInitializedOptions): ISetGeofenceAfterSiteLocationIsInitializedAction {
    return {
      type: SiteDetailsPanelActions.SET_GEOFENCE_AFTER_SITE_LOCATION_IS_INITIALIZED,
      payload: {
        siteId,
        geofence,
      },
    };
  }

  public static assignManagersToSiteRequest({
    siteId,
    userIds,
  }: IAssignManagersToSiteRequestOptions): IAssignManagersToSiteRequestAction {
    return {
      type: SiteDetailsPanelActions.ASSIGN_MANAGERS_TO_SITE_REQUEST,
      payload: {
        siteId,
        userIds,
      },
    };
  }

  public static assignManagersToSiteSuccess(
    data: IAssignManagersToSiteSuccessOptions
  ): IAssignManagersToSiteSuccessAction {
    return {
      type: SiteDetailsPanelActions.ASSIGN_MANAGERS_TO_SITE_SUCCESS,
      payload: data,
    };
  }

  public static assignManagersToSiteError({ error }: IActionCreatorErrorOptions): IAssignManagersToSiteErrorAction {
    return {
      type: SiteDetailsPanelActions.ASSIGN_MANAGERS_TO_SITE_ERROR,
      error,
    };
  }

  public static unassignManagerFromSiteRequest({
    siteId,
    userId,
  }: IUnassignManagerFromSiteRequestOptions): IUnassignManagerFromSiteRequestAction {
    return {
      type: SiteDetailsPanelActions.UNASSIGN_MANAGER_FROM_SITE_REQUEST,
      payload: {
        siteId,
        userId,
      },
    };
  }

  public static unassignManagerFromSiteSuccess(
    data: IUnassignManagerFromSiteSuccessOptions
  ): IUnassignManagerFromSiteSuccessAction {
    return {
      type: SiteDetailsPanelActions.UNASSIGN_MANAGER_FROM_SITE_SUCCESS,
      payload: data,
    };
  }

  public static unassignManagerFromSiteError({
    error,
  }: IActionCreatorErrorOptions): IUnassignManagerFromSiteErrorAction {
    return {
      type: SiteDetailsPanelActions.UNASSIGN_MANAGER_FROM_SITE_ERROR,
      error,
    };
  }

  public static resetCreateWorkIntervalError(): Action<typeof CleaningActions.RESET_CREATE_WORK_INTERVAL_ERROR> {
    return {
      type: CleaningActions.RESET_CREATE_WORK_INTERVAL_ERROR,
    };
  }

  public static resetUpdateWorkIntervalError(): Action<typeof CleaningActions.RESET_UPDATE_WORK_INTERVAL_ERROR> {
    return {
      type: CleaningActions.RESET_UPDATE_WORK_INTERVAL_ERROR,
    };
  }

  public static editSiteInfoRequest({ id, input }: IEditSiteInfoRequestOptions): IEditSiteInfoRequestAction {
    return {
      type: SiteDetailsPanelActions.EDIT_SITE_INFO_REQUEST,
      payload: {
        id,
        input,
      },
    };
  }
}
