import { Checkbox, CheckboxOptionType, Form } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import dayjs, { Dayjs } from 'dayjs';
import { TimeRangeValidator } from '../../../../../../lib/utils/date-handling/TimeRangeValidator';
import { useAnalyticsSetForm } from '../../../../../cross-cutting-concerns/analytics/hooks/useAnalyticsSetForm';
import { InCreation, IWorkIntervalLocal, IWorkIntervalUtc } from '../../../../cleaning/interfaces/CleaningPlan.types';
import { CleaningActions } from '../../../../cleaning/state/cleaningActions';
import { CALENDAR_EVENT_TIME_FORMAT } from '../../../../cleaning/utils/CalendarUtils';
import { SiteModalsActions } from '../../state/siteModalsActions';
import * as siteModalsSelectors from '../../state/siteModalsSelectors';
import * as siteDetailsPanelSelectors from '../../../site-details-panel/state/siteDetailsPanelSelectors';
import { StyledAddWorkIntervalModal } from './AddWorkIntervalModal.styles';
import { TimeRangePicker } from 'lib/components/TimeRangePicker/TimeRangePicker';
import { GRAPHQL_MAX_INT_VALUE } from 'config/constants';
import { CleaningPlanUtils } from 'app/modules/cleaning/utils/CleaningPlanUtils';
import { MachineClassification, Site } from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { ToastService } from 'app/cross-cutting-concerns/toasts/ToastService';
import { SiteDetailsValidationError } from 'app/modules/site-management/site-details/ValidationError';
import { AnalyticsForm, AnalyticsLink } from 'app/cross-cutting-concerns/analytics/interfaces/Analytics.types';
import { useAnalyticsLinkActivated } from 'app/cross-cutting-concerns/analytics/hooks/useAnalyticsLinkActivated';
import { Optional } from 'lib/types/Optional';
import { TextButton } from 'lib/components/Button/TextButton/TextButton';
import { PrimaryButton } from 'lib/components/Button/PrimaryButton/PrimaryButton';
import { Select } from 'lib/components/Select/Select';

export interface AddWorkIntervalFormValues {
  daysOfWeekLocal: number[];
  timeSpanLocal: [Dayjs, Dayjs];
  machine: string;
}

export interface AddWorkIntervalModalProps {
  site: Site;
}

const toastService = new ToastService();

export const AddWorkIntervalModal = ({ site }: AddWorkIntervalModalProps): JSX.Element => {
  const analyticsLinkActivated = useAnalyticsLinkActivated();
  const [formInstance] = Form.useForm();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isVisible = useSelector(siteModalsSelectors.selectIsAddWorkIntervalModalVisible);
  const isLoading = useSelector(siteModalsSelectors.selectIsAddWorkIntervalModalLoading);
  const availableSiteMachines = useSelector(siteModalsSelectors.selectAvailableSiteMachines);
  const areAvailableSiteMachinesLoading = useSelector(siteModalsSelectors.selectAreAvailableSiteMachinesLoading);
  const createError = useSelector(siteDetailsPanelSelectors.selectCreateWorkIntervalError);

  const [fields, setFields] = useState<Optional<AddWorkIntervalFormValues>>(undefined);
  const [isWorkIntervalOpen, setIsWorkIntervalOpen] = useState<boolean>(false);

  const daysOfWeekOptions = t('siteDetails.addWorkIntervalModal.daysOfWeekOptions', {
    returnObjects: true,
  }) as CheckboxOptionType[];

  useEffect(() => {
    formInstance.resetFields();
  }, [formInstance, isVisible]);

  useEffect(() => {
    if (isVisible) {
      dispatch(
        SiteModalsActions.availableSiteMachinesRequest({
          id: site.id,
          filter: {
            classifications: [MachineClassification.Gcd, MachineClassification.Robot],
          },
          machinePaginationOptions: {
            limit: GRAPHQL_MAX_INT_VALUE,
          },
        })
      );
    }
  }, [dispatch, isVisible, site.id]);

  useEffect(() => {
    if (createError) {
      toastService.error({
        message: t('toasts.validationError.title') as string,
        description: t(SiteDetailsValidationError[createError.message as keyof typeof SiteDetailsValidationError], {
          machineName:
            availableSiteMachines.find(machine => machine.id === formInstance.getFieldValue('machine'))?.name || '',
        }),
      });
    }
  }, [availableSiteMachines, createError, formInstance, t]);

  const handleCancel = useCallback(() => {
    dispatch(SiteModalsActions.hideAddWorkIntervalModal());
  }, [dispatch]);

  const handleOk = useCallback(() => {
    setFields(formInstance.getFieldsValue());
    formInstance.submit();
  }, [formInstance]);

  const handleValuesChange = (): void => {
    setFields(formInstance.getFieldsValue());
  };

  const onFinish = useCallback(
    ({ machine, timeSpanLocal, daysOfWeekLocal }: AddWorkIntervalFormValues) => {
      const workIntervalLocal: InCreation<IWorkIntervalLocal> = CleaningPlanUtils.createLocalWorkIntervalInCreation(
        machine,
        timeSpanLocal,
        daysOfWeekLocal
      );

      const workIntervalUtc: InCreation<IWorkIntervalUtc> =
        CleaningPlanUtils.convertLocalWorkIntervalToUtc(workIntervalLocal);

      analyticsLinkActivated({
        linkName: AnalyticsLink.ADD_WORK_INTERVAL,
      });

      dispatch(
        CleaningActions.createWorkIntervalRequest({
          siteId: site.id,
          workIntervalUtc,
        })
      );
    },
    [analyticsLinkActivated, dispatch, site.id]
  );

  useAnalyticsSetForm({
    name: AnalyticsForm.ADD_WORK_INTERVAL,
    // serializable timeSpanLocal before saving to redux since it's dayjs type currently
    fields: (fields?.timeSpanLocal?.[0] && fields?.timeSpanLocal?.[1]
      ? {
          ...fields,
          timeSpanLocal: [dayjs(fields.timeSpanLocal[0]).toISOString(), dayjs(fields.timeSpanLocal[1]).toISOString()],
        }
      : fields) as Record<string, any>,
    isVisible,
  });

  return (
    <StyledAddWorkIntervalModal
      className="add-work-interval-modal"
      title={t('siteDetails.addWorkIntervalModal.modalTitle')}
      open={isVisible}
      width={600}
      footer={[
        <TextButton size="m" onClick={handleCancel} className="cancel-button" key="cancel-button">
          {t('common.cancel')}
        </TextButton>,
        <PrimaryButton
          size="m"
          key="submit"
          className="submit-button"
          type="primary"
          onClick={handleOk}
          loading={isLoading}
        >
          {t('common.add')}
        </PrimaryButton>,
      ]}
    >
      <Form
        form={formInstance}
        name="add-work-interval"
        layout="vertical"
        onValuesChange={handleValuesChange}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          name="daysOfWeekLocal"
          required
          rules={[{ required: true, message: t('siteDetails.addWorkIntervalModal.errors.daysOfWeekRequired') }]}
        >
          <Checkbox.Group className="add-work-interval-modal__days-of-week" options={daysOfWeekOptions} />
        </Form.Item>

        <Form.Item
          name="timeSpanLocal"
          label={t('siteDetails.addWorkIntervalModal.timeSpan')}
          required
          rules={[
            { required: true, message: t('timeRangeValidator.errors.timeSpanRequired') },
            {
              validator: TimeRangeValidator.validateStartTimeEndTimeNotIdentical,
            },
          ]}
        >
          <TimeRangePicker format={CALENDAR_EVENT_TIME_FORMAT} className="add-work-interval-modal__time-range-picker" />
        </Form.Item>

        <Form.Item
          name="machine"
          label={t('siteDetails.addWorkIntervalModal.machine')}
          required
          rules={[{ required: true, message: t('siteDetails.addWorkIntervalModal.errors.machineRequired') }]}
        >
          <Select
            className="add-work-interval-modal__dropdown"
            optionLabelProp="name"
            maxTagCount="responsive"
            loading={areAvailableSiteMachinesLoading}
            filterOption={(input, option): boolean => option?.name.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            placeholder={t('machineModals.editMachineInfo.form.placeholder.default')}
            options={[
              ...(availableSiteMachines.map(siteMachine => ({
                label: [siteMachine.name, siteMachine.type?.name].filter(Boolean).join(', '),
                value: siteMachine.id,
                name: siteMachine.name,
              })) || []),
            ]}
            dropdownVisibleState={isWorkIntervalOpen}
            onDropdownVisibleChange={(isOpen: boolean): void => setIsWorkIntervalOpen(isOpen)}
          />
        </Form.Item>
      </Form>
    </StyledAddWorkIntervalModal>
  );
};
