import { Action } from 'redux';
import {
  IActionCreatorErrorOptions,
  IErrorAction,
} from '../../../../../cross-cutting-concerns/state-management/interfaces/StateManagement.types';
import { SiteClientListSitesWithOperatingTimeOptions } from 'app/modules/site-management/SiteClient';
import { SiteListWithOperatingTime } from 'app/modules/site-management/interfaces/Site.types';

export type IGetSiteListWithOperatingTimeRequestOptions = SiteClientListSitesWithOperatingTimeOptions;

export type IGetSiteListWithOperatingTimeRequestAction = Action<
  typeof SiteWidgetsFleetDashboardActions.GET_SITE_LIST_WITH_OPERATING_TIME_REQUEST
> & {
  payload: SiteClientListSitesWithOperatingTimeOptions;
};

export type IGetSiteListWithOperatingTimeSuccessOptions = SiteListWithOperatingTime;
export type IGetSiteListWithOperatingTimeSuccessAction = Action<
  typeof SiteWidgetsFleetDashboardActions.GET_SITE_LIST_WITH_OPERATING_TIME_SUCCESS
> & {
  payload: SiteListWithOperatingTime;
};

export type IGetSiteListWithOperatingTimeErrorAction = IErrorAction<
  typeof SiteWidgetsFleetDashboardActions.GET_SITE_LIST_WITH_OPERATING_TIME_ERROR
>;

export class SiteWidgetsFleetDashboardActions {
  public static GET_SITE_LIST_WITH_OPERATING_TIME_REQUEST =
    'SITE_WIDGETS_FLEET_DASHBOARD::GET_SITE_LIST_WITH_OPERATING_TIME_REQUEST';

  public static GET_SITE_LIST_WITH_OPERATING_TIME_SUCCESS =
    'SITE_WIDGETS_FLEET_DASHBOARD::GET_SITE_LIST_WITH_OPERATING_TIME_SUCCESS';

  public static GET_SITE_LIST_WITH_OPERATING_TIME_ERROR =
    'SITE_WIDGETS_FLEET_DASHBOARD::GET_SITE_LIST_WITH_OPERATING_TIME_ERROR';

  public static RESET_STATE = 'SITE_WIDGETS_FLEET_DASHBOARD::RESET_STATE';

  public static getSiteListWithOperatingTimeRequest({
    paginationOptions,
    sortOptions,
    period,
  }: IGetSiteListWithOperatingTimeRequestOptions): IGetSiteListWithOperatingTimeRequestAction {
    return {
      type: SiteWidgetsFleetDashboardActions.GET_SITE_LIST_WITH_OPERATING_TIME_REQUEST,
      payload: {
        paginationOptions,
        sortOptions,
        period,
      },
    };
  }

  public static getSiteListWithOperatingTimeSuccess(
    data: IGetSiteListWithOperatingTimeSuccessOptions
  ): IGetSiteListWithOperatingTimeSuccessAction {
    return {
      type: SiteWidgetsFleetDashboardActions.GET_SITE_LIST_WITH_OPERATING_TIME_SUCCESS,
      payload: data,
    };
  }

  public static getSiteListWithOperatingTimeError({
    error,
  }: IActionCreatorErrorOptions): IGetSiteListWithOperatingTimeErrorAction {
    return {
      type: SiteWidgetsFleetDashboardActions.GET_SITE_LIST_WITH_OPERATING_TIME_ERROR,
      error,
    };
  }

  public static resetState(): Action<typeof SiteWidgetsFleetDashboardActions.RESET_STATE> {
    return {
      type: SiteWidgetsFleetDashboardActions.RESET_STATE,
    };
  }
}
