import styled from 'styled-components';

export const StyledSiteList = styled.div`
  ${(props): string => props.theme.mixins.layout.addHeaderContentClass('site-list')}
  ${(props): string => props.theme.mixins.layout.addBodyContentClass('site-list')}
  ${(props): string => props.theme.mixins.layout.addContainerClasses('site-list')}

  min-height: inherit;

  .site-list__header {
    background-color: transparent;
  }

  .add-new-site-wrapper {
    text-align: right;
    display: flex;
    align-items: end;
  }

  .site-list__page-info {
    display: flex;
    justify-content: space-between;
    align-items: start;
  }

  .site-list__title {
    ${(props): string => props.theme.fontStyles.redesign.heading1};
    color: ${(props): string => props.theme.colors.black};
    text-transform: uppercase;
    margin-bottom: 7px;
  }

  .site-list__description {
    font-family: ${(props): string => props.theme.fonts.p0};
    font-size: ${(props): string => props.theme.fontSizes.p0};
    line-height: ${(props): string => props.theme.lineHeights.p0};
    font-weight: ${(props): number => props.theme.fontWeights.p0};
    color: ${(props): string => props.theme.colors.black};
    overflow: unset;
    margin-bottom: 15px;
  }

  .site-list__body-controls {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 25px;
  }

  .site-list__search-bar {
    min-width: 270px;
    margin-left: auto;
  }

  .site-list__search-bar-icon {
    width: 16px;
    height: 16px;

    i,
    svg {
      width: 16px;
      height: 16px;
    }
  }

  .site-list__search-input {
    display: flex;
    flex-direction: row-reverse;
    width: 50%;
    padding: 0px;
    padding-left: 14px !important;
    padding: 0px;

    .ant-input-prefix {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 32px;
      border-left: 1px solid ${(props): string => props.theme.colors.darkGrey};
      padding: 14px;
    }

    input {
      top: 0px !important;
    }
  }

  .site-list__table {
    .ant-table-row {
      cursor: pointer;
    }
  }

  .site-list__filter {
    display: flex;
    justify-content: space-between;
    gap: 24px;
    padding-top: 24px;

    .ant-select {
      width: 100%;

      .ant-select-selector {
        background-color: ${(props): string => props.theme.colors.white};
      }
    }
  }

  .site-list__filter-label {
    ${(props): string => props.theme.fontStyles.caption.bold}
    color: ${(props): string => props.theme.colors.black};
    text-transform: uppercase;
  }

  .site-list__select-wrapper {
    &.tag-filter-wrapper {
      min-width: 110px;
    }

    .ant-form-item {
      margin-bottom: 0;
    }
  }

  .site-list__tags-filter {
    .ant-select-selection-placeholder {
      color: ${(props): string => props.theme.colors.black};
    }
  }

  @media screen and (max-width: 1440px) {
    .site-list__search-input {
      width: 100%;
    }
  }
`;
