import styled from 'styled-components';

export const StyledCleaningPlan = styled.div`
  .cleaning-plan__title {
    margin-bottom: ${(props): string => props.theme.spacing.md};
    ${(props): string => props.theme.fontStyles.redesign.xl.strong}
    text-transform: capitalize;
  }

  .cleaning-plan__calendar-wrapper {
    .cleaning-plan__spin {
      max-height: 100%;
    }
  }

  .cleaning-plan__calendar {
    height: 512px;
    border: 1px solid ${(props): string => props.theme.colors.brightGrey};

    .rbc-header {
      display: flex;
      justify-content: center;
      border: 0;
      padding: 0;
    }

    .rbc-time-view,
    .rbc-timeslot-group,
    .rbc-events-container,
    .rbc-day-slot .rbc-time-slot,
    .rbc-time-header,
    .rbc-time-header-content,
    .rbc-time-content {
      border: 0;
    }

    .rbc-day-slot .rbc-time-slot:first-child {
      border-top: 1px solid ${(props): string => props.theme.colors.brightGrey};
    }

    .rbc-time-header-cell {
      min-height: 55px;
    }

    .rbc-allday-cell {
      display: none;
    }

    .rbc-time-gutter {
      .rbc-label {
        ${(props): string => props.theme.fontStyles.caption.news}
        color: 1px solid ${(props): string => props.theme.colors.darkGrey};
        opacity: 50%;
      }
    }

    .rbc-time-header-content {
      .rbc-today {
        background-color: transparent;
      }

      .rbc-header {
        button {
          ${(props): string => props.theme.fontStyles.redesign.sm.strong}
          color: ${(props): string => props.theme.colors.grey400};
          text-transform: uppercase;
        }
      }
    }

    .rbc-time-content {
      .rbc-time-slot {
        span {
          ${(props): string => props.theme.fontStyles.redesign.sm.normal}
        }
      }

      .rbc-today,
      .rbc-day-slot {
        background-color: ${(props): string => props.theme.colors.brighterGrey};
        margin-left: 1.5px;
        margin-right: 1.5px;

        .rbc-current-time-indicator {
          display: none;
        }
      }
    }

    .rbc-events-container {
      margin-right: 0;
    }

    .rbc-timeslot-group {
      min-height: 53px;
    }

    .rbc-day-slot .rbc-event,
    .rbc-day-slot .rbc-background-event {
      min-height: 22px;
    }
  }

  .cleaning-plan__footer {
    text-align: right;

    .cleaning-plan__add-work-interval-button {
      margin-top: ${(props): string => props.theme.spacing.md};
    }
  }
`;
