import styled from 'styled-components';
import { Modal } from 'lib/components/Modal/Modal';

export const StyledAssignSiteManagerModal = styled(Modal)`
  .ant-form-item
    .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    content: '';
    margin-inline-end: 0;
  }

  .ant-form-item .ant-form-item-label > .ant-form-item-required::after {
    display: inline-block;
    margin-inline-end: 4px;
    visibility: visible;
    color: ${(props): string => props.theme.colors.semanticError};
    ${(props): string => props.theme.fontStyles.redesign.sm.delete};
    line-height: 1;
    content: '*';
  }
`;
