import { Image } from 'antd';
import { SelectValue } from 'antd/es/select';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { SearchOutlined } from '@ant-design/icons';
import { useAnalyticsSetForm } from '../../../../../cross-cutting-concerns/analytics/hooks/useAnalyticsSetForm';
import { Site } from '../../../../../cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { SiteDetailsPanelActions } from '../../../site-details-panel/state/siteDetailsPanelActions';
import * as siteModalsSelectors from '../../state/siteModalsSelectors';
import { SiteModalsActions } from '../../state/siteModalsActions';
import { StyledAssignMachineToSiteModal } from './AssignMachineToSiteModal.styles';
import { MachinePictureLoader } from 'app/modules/machine-inventory/components/MachinePictureLoader/MachinePictureLoader';
import { AnalyticsForm, AnalyticsLink } from 'app/cross-cutting-concerns/analytics/interfaces/Analytics.types';
import { useAnalyticsLinkActivated } from 'app/cross-cutting-concerns/analytics/hooks/useAnalyticsLinkActivated';
import { TextButton } from 'lib/components/Button/TextButton/TextButton';
import { PrimaryButton } from 'lib/components/Button/PrimaryButton/PrimaryButton';
import { Select } from 'lib/components/Select/Select';
import { Input } from 'lib/components/Input/Input';

const { Option } = Select;

export interface AssignMachineToSiteModalProps {
  site: Site;
}

export const AssignMachineToSiteModal = ({ site }: AssignMachineToSiteModalProps): JSX.Element => {
  const analyticsLinkActivated = useAnalyticsLinkActivated();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const visible = useSelector(siteModalsSelectors.selectIsAssignMachinesModalVisible);
  const isLoading = useSelector(siteModalsSelectors.selectIsAssignMachinesModalLoading);
  const availableMachines = useSelector(siteModalsSelectors.selectAvailableMachinesForAssignment);
  const areAvailableMachinesLoading = useSelector(siteModalsSelectors.selectAreAvailableMachinesLoading);

  const machineMapRef = useRef(new Map());
  const [selectedMachines, setSelectedMachines] = useState<string[]>([]);
  const [assignedMachineSearchInputValue, setAssignedMachineSearchInputValue] = useState('');

  useEffect(() => {
    if (visible) {
      setSelectedMachines([]);
      dispatch(SiteModalsActions.availableMachinesRequest());
    }
  }, [setSelectedMachines, dispatch, visible]);

  const handleChange = useCallback(
    (machines: SelectValue) => {
      setSelectedMachines(machines as string[]);
    },
    [setSelectedMachines]
  );

  useEffect(() => {
    if (availableMachines?.length) {
      machineMapRef.current = new Map(
        availableMachines.map(machine => [machine.id, machine.name === '-' ? machine.type?.name : machine.name])
      );
    }
  }, [availableMachines]);

  const handleCancel = useCallback(() => dispatch(SiteModalsActions.hideAssignMachinesModal()), [dispatch]);

  const handleOk = useCallback(() => {
    if (!selectedMachines.length) return;

    analyticsLinkActivated({
      linkName: AnalyticsLink.ASSIGN_NEW_MACHINE,
    });

    dispatch(
      SiteDetailsPanelActions.assignMachineToSiteRequest({
        siteId: site.id,
        machineIds: selectedMachines,
      })
    );
  }, [analyticsLinkActivated, dispatch, selectedMachines, site.id]);

  const handleAssignedMachineSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setAssignedMachineSearchInputValue(event.target.value);
  };

  useAnalyticsSetForm({
    name: AnalyticsForm.ASSIGN_MACHINE,
    fields: {
      site,
      selectedMachines,
    },
    isVisible: visible,
  });

  return (
    <StyledAssignMachineToSiteModal
      className="assign-machine-to-site-modal"
      title={t('siteDetails.assignMachineModal.assignMachineToSite')}
      open={visible}
      footer={[
        <TextButton size="m" onClick={handleCancel} className="cancel-button" key="cancel-button">
          {t('siteDetails.assignMachineModal.cancel')}
        </TextButton>,
        <PrimaryButton
          size="m"
          key="submit"
          className="submit-button"
          type="primary"
          loading={isLoading}
          onClick={handleOk}
        >
          {t('siteDetails.assignMachineModal.assignToSite')}
        </PrimaryButton>,
      ]}
    >
      <p className="modal__body">{t('siteDetails.assignMachineModal.modalBody')}</p>
      <h3 className="input-label">{t('siteDetails.assignMachineModal.machineName')}</h3>
      <Select
        className="assign-machine-to-site-modal__dropdown"
        maxTagCount="responsive"
        loading={areAvailableMachinesLoading}
        value={selectedMachines}
        onChange={handleChange}
        mode="multiple"
        placeholder={t('machineModals.editMachineInfo.form.placeholder.default')}
        defaultValue={selectedMachines}
        showSearch={false}
        searchValue={assignedMachineSearchInputValue}
        optionFilterProp="label"
        tagRender={({ label, value }): React.ReactElement => (
          <div>
            {machineMapRef.current.get(value) || label}
            {selectedMachines[selectedMachines.length - 1] === value ? null : ','}
            &nbsp;
          </div>
        )}
        onDropdownVisibleChange={(): void => setAssignedMachineSearchInputValue('')}
        dropdownRender={(menu): React.ReactElement => (
          <>
            <div className="assign-machine-to-site-modal__search-input-wrapper">
              <Input
                value={assignedMachineSearchInputValue}
                className="assign-machine-to-site-modal__search-input"
                placeholder={t('siteDetails.assignMachineModal.searchForAMachine')}
                prefix={<SearchOutlined />}
                onChange={handleAssignedMachineSearchInputChange}
              />
            </div>
            {menu}
          </>
        )}
      >
        {availableMachines.map(machine => {
          const machineImage = machine.variant?.picture.thumbnail;

          return (
            <Option
              key={machine.id}
              value={machine.id}
              label={
                machine.name === '-'
                  ? machine.type?.name
                  : [machine.name, machine.type?.name].filter(Boolean).join(', ')
              }
            >
              <div className="machine-option">
                {machineImage ? (
                  <div className="machine-option__image" role="img" aria-label={machine.type?.name || ''}>
                    <Image src={machineImage} width={20} alt={machine.type?.name || ''} preview={false} />
                  </div>
                ) : (
                  <div
                    className="machine-option__image machine-option__image--loading"
                    role="img"
                    aria-label={machine.type?.name || ''}
                  >
                    <MachinePictureLoader className="machine-option__image-loader" />
                  </div>
                )}
                <div className="machine-option__label">
                  {machine.name === '-'
                    ? machine.type?.name
                    : [machine.name, machine.type?.name].filter(Boolean).join(', ')}
                </div>
              </div>
            </Option>
          );
        })}
      </Select>
    </StyledAssignMachineToSiteModal>
  );
};
