import { produce } from 'immer';
import { AnyAction } from 'redux';
import { Optional } from '../../../../../lib/types/Optional';
import { PaginationTokens } from '../../../../cross-cutting-concerns/state-management/interfaces/StateManagement.types';
import { SiteTagsItems, SiteTagsList } from '../../interfaces/Site.types';
import { SiteListActions } from './siteListActions';
import { DEFAULT_PAGE_SIZE_VALUE, DEFAULT_PAGE_VALUE } from 'config/constants';
import { Maybe, Site, SortOrders } from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { getNextPaginationToken } from 'app/utils/infinite-scroll/infiniteScroll';

export interface SiteListState {
  data: Optional<Site[]>;
  totalCount: Optional<number>;
  isLoading: Optional<boolean>;
  isLoadingMoreData: Optional<boolean>;
  paginationTokens: PaginationTokens;
  nextPaginationToken: string | null;
  page: number;
  pageSize: number;
  tags: {
    suggestions: {
      isLoading: boolean;
      data: string[];
    };
  };
  filters: {
    searchText: Optional<string>;
    ids: Optional<string[]>;
    active: {
      tags: Optional<string[]>;
    };
    tagsItems: Optional<SiteTagsItems[]>;
  };
  sortOptions: {
    field: Maybe<string>;
    order: Maybe<SortOrders>;
  };
}

export const initialState: SiteListState = {
  data: null,
  totalCount: null,
  isLoading: null,
  isLoadingMoreData: null,
  paginationTokens: {},
  nextPaginationToken: '',
  page: DEFAULT_PAGE_VALUE,
  pageSize: DEFAULT_PAGE_SIZE_VALUE,
  tags: {
    suggestions: {
      isLoading: false,
      data: [],
    },
  },
  filters: {
    searchText: null,
    ids: null,
    active: {
      tags: [],
    },
    tagsItems: null,
  },
  sortOptions: {
    field: null,
    order: null,
  },
};

export const siteListReducer = (state = initialState, action: AnyAction): SiteListState =>
  produce(state, draft => {
    switch (action.type) {
      case SiteListActions.GET_SITE_LIST_REQUEST: {
        draft.isLoading = true;
        return draft;
      }
      case SiteListActions.GET_SITE_LIST_SUCCESS: {
        const {
          sites: {
            data,
            metadata: { totalCount, paginationToken },
          },
        } = action.payload;
        draft.isLoading = false;
        draft.data = data;
        draft.totalCount = totalCount;
        draft.nextPaginationToken = getNextPaginationToken(paginationToken);

        if (paginationToken) {
          draft.paginationTokens[draft.page + 1] = paginationToken;
        }
        return draft;
      }
      case SiteListActions.GET_SITE_LIST_ERROR: {
        draft.isLoading = false;
        return draft;
      }

      case SiteListActions.GET_SITE_LIST_MORE_DATA_REQUEST: {
        draft.isLoadingMoreData = true;
        return draft;
      }
      case SiteListActions.GET_SITE_LIST_MORE_DATA_SUCCESS: {
        const {
          sites: {
            data,
            metadata: { paginationToken },
          },
        } = action.payload;
        draft.isLoadingMoreData = false;
        draft.data = (draft.data || []).concat(data);
        draft.nextPaginationToken = getNextPaginationToken(paginationToken);

        return draft;
      }
      case SiteListActions.GET_SITE_LIST_MORE_DATA_ERROR: {
        draft.isLoadingMoreData = false;
        return draft;
      }
      case SiteListActions.CREATE_SITE_REQUEST: {
        draft.isLoading = true;
        return draft;
      }
      case SiteListActions.CREATE_SITE_SUCCESS: {
        draft.isLoading = false;
        draft.page = initialState.page;
        draft.paginationTokens = initialState.paginationTokens;
        draft.filters = initialState.filters;
        draft.sortOptions = initialState.sortOptions;

        return draft;
      }
      case SiteListActions.CREATE_SITE_ERROR: {
        draft.isLoading = false;
        return draft;
      }
      case SiteListActions.CHANGE_TABLE_PAGE: {
        const { page } = action.payload;

        draft.page = page;
        return draft;
      }
      case SiteListActions.CHANGE_TABLE_PAGE_SIZE: {
        const { pageSize } = action.payload;

        draft.paginationTokens = {};
        draft.page = DEFAULT_PAGE_VALUE;
        draft.pageSize = pageSize;
        return draft;
      }
      case SiteListActions.RESET_STATE: {
        return initialState;
      }
      case SiteListActions.SET_SEARCH_TEXT_FILTER: {
        const { searchText } = action.payload;

        draft.filters.searchText = searchText;
        draft.page = DEFAULT_PAGE_VALUE;
        draft.paginationTokens = {};
        return draft;
      }
      case SiteListActions.SET_ACTIVE_SORT_FIELD: {
        const { field } = action.payload;
        draft.sortOptions.field = field;
        return draft;
      }
      case SiteListActions.SET_ACTIVE_SORT_ORDER: {
        const { order } = action.payload;
        draft.sortOptions.order = order;
        draft.paginationTokens = {};
        draft.page = DEFAULT_PAGE_VALUE;
        return draft;
      }

      case SiteListActions.SET_ACTIVE_TAGS_FILTER: {
        const { tags } = action.payload;

        draft.page = DEFAULT_PAGE_VALUE;
        draft.paginationTokens = {};
        draft.filters.active.tags = tags;
        return draft;
      }

      case SiteListActions.LIST_TAGS_REQUEST: {
        draft.tags.suggestions.isLoading = true;
        return draft;
      }

      case SiteListActions.LIST_TAGS_SUCCESS: {
        const {
          tags: { data },
        } = action.payload as SiteTagsList;
        const tags = data.map((datum: { tag: string }) => datum.tag);

        draft.tags.suggestions.data = tags;
        draft.tags.suggestions.isLoading = false;
        return draft;
      }

      case SiteListActions.LIST_TAGS_ERROR: {
        draft.tags.suggestions.isLoading = false;
        return draft;
      }

      default:
        return draft;
    }
  });
