import { CallEffect, ForkEffect, GetContextEffect, PutEffect } from 'redux-saga/effects';
import { getContext, call, put, takeLatest } from 'typed-redux-saga';
import { SiteList, SiteCreate, SiteAddressCoordinateResponse, SiteTagsList } from '../../interfaces/Site.types';
import {
  ICreateSiteModalIsLoadingAction,
  ICreateSiteModalIsNotLoadingAction,
  IHideCreateSiteModalAction,
  IListTagsErrorAction,
  IListTagsRequestAction,
  IListTagsSuccessAction,
  SiteModalsActions,
} from '../../modals/state/siteModalsActions';
import {
  ISetGeofenceAfterSiteLocationIsInitializedAction,
  ISetGeofenceAfterSiteLocationIsInitializedOptions,
  SiteDetailsPanelActions,
} from '../../site-details-panel/state/siteDetailsPanelActions';
import {
  IGetSiteListErrorAction,
  IGetSiteListRequestAction,
  IGetSiteListSuccessAction,
  ICreateSiteErrorAction,
  ICreateSiteRequestAction,
  ICreateSiteSuccessAction,
  SiteListActions,
  IGetSiteListMoreDataErrorAction,
  IGetSiteListMoreDataRequestAction,
  IGetSiteListMoreDataSuccessAction,
} from './siteListActions';
import { IDependencies } from 'app/cross-cutting-concerns/dependency-injection/interfaces/IDependencies';
import { Optional } from 'lib/types/Optional';
import { GEOFENCE_DEFAULT_RADIUS } from 'app/components/Map/Map';
import { InfiniteScrollConstants, TAGS_SEARCH_LIMIT } from 'config/constants';
import { SortOrders } from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';

export function* getSiteListSaga(
  action: IGetSiteListRequestAction
): Generator<
  GetContextEffect | CallEffect<SiteList> | PutEffect<IGetSiteListSuccessAction> | PutEffect<IGetSiteListErrorAction>,
  void,
  IDependencies
> {
  const { siteService } = yield* getContext<IDependencies>('dependencies');

  try {
    const response = yield* call(siteService.list, action.payload);
    yield* put(SiteListActions.getSiteListSuccess(response));
  } catch (error) {
    yield* put(
      SiteListActions.getSiteListError({
        error,
      })
    );
  }
}

export function* getSiteListMoreDataSaga(
  action: IGetSiteListMoreDataRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<SiteList>
  | PutEffect<IGetSiteListMoreDataSuccessAction>
  | PutEffect<IGetSiteListMoreDataErrorAction>,
  void,
  IDependencies
> {
  const { siteService } = yield* getContext<IDependencies>('dependencies');

  try {
    const response = yield* call(siteService.list, action.payload);
    yield* put(SiteListActions.getSiteListMoreDataSuccess(response));
  } catch (error) {
    yield* put(
      SiteListActions.getSiteListMoreDataError({
        error,
      })
    );
  }
}

export function* createSiteSaga(
  action: ICreateSiteRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<Optional<SiteCreate>>
  | CallEffect<Optional<SiteAddressCoordinateResponse>>
  | PutEffect<ICreateSiteModalIsLoadingAction>
  | PutEffect<IGetSiteListRequestAction>
  | PutEffect<ICreateSiteSuccessAction>
  | PutEffect<ISetGeofenceAfterSiteLocationIsInitializedAction>
  | PutEffect<ICreateSiteModalIsNotLoadingAction>
  | PutEffect<IHideCreateSiteModalAction>
  | PutEffect<ICreateSiteErrorAction>,
  void,
  IDependencies
> {
  const { siteService } = yield* getContext<IDependencies>('dependencies');

  try {
    yield* put(SiteModalsActions.createSiteModalIsLoading());
    let location;
    if (action.payload.input.location?.address) {
      const filter = { text: action.payload.input.location?.address };
      const getAddressCoordinateRes = yield* call(siteService.getAddressCoordinate, { filter });
      const coordinate = getAddressCoordinateRes.siteAddressCoordinate.data;
      if (!coordinate?.latitude || !coordinate?.longitude) {
        throw new Error('Can not get coordinate');
      }

      location = {
        address: action.payload.input.location.address,
        latitude: coordinate.latitude,
        longitude: coordinate.longitude,
      };
    }

    const response = yield* call(siteService.create, {
      input: {
        ...action.payload.input,
        location,
      },
    });

    yield* put(SiteListActions.createSiteSuccess(response));

    // Load site list after create
    // TODO: Remove after handle site list subscription
    yield* put(
      SiteListActions.getSiteListRequest({
        paginationOptions: {
          limit: InfiniteScrollConstants.MAX_ITEMS,
          paginationToken: '',
        },
        sortOptions: {
          field: 'name',
          order: SortOrders.Asc,
        },
      })
    );
    yield* put(
      SiteListActions.listTagsRequest({
        filter: { textBegin: '' },
        paginationOptions: { limit: TAGS_SEARCH_LIMIT },
      })
    );

    yield* put(SiteModalsActions.createSiteModalIsNotLoading());
    yield* put(SiteModalsActions.hideCreateSiteModal());

    // Set default site circle geofence if creating new site with location address
    if (response?.siteCreate.data.id && location) {
      const setGeofenceOptions: ISetGeofenceAfterSiteLocationIsInitializedOptions = {
        siteId: response.siteCreate.data.id,
        geofence: {
          circle: {
            radius: GEOFENCE_DEFAULT_RADIUS,
            centerPoint: {
              latitude: location.latitude,
              longitude: location.longitude,
            },
          },
        },
      };

      yield* put(SiteDetailsPanelActions.setGeofenceAfterSiteLocationIsInitialized(setGeofenceOptions));
    }
  } catch (error) {
    console.error(error);
    yield* put(
      SiteListActions.createSiteError({
        error,
      })
    );

    yield* put(SiteModalsActions.createSiteModalIsNotLoading());
    yield* put(SiteModalsActions.hideCreateSiteModal());
  }
}

export function* listTagsSaga(
  action: IListTagsRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<SiteTagsList>
  | PutEffect<IListTagsRequestAction>
  | PutEffect<IListTagsSuccessAction>
  | PutEffect<IListTagsErrorAction>,
  void,
  IDependencies
> {
  const { siteService } = yield* getContext<IDependencies>('dependencies');

  try {
    const { tags } = yield* call(siteService.listTags, action.payload);

    yield* put(
      SiteListActions.listTagsSuccess({
        tags,
      })
    );
  } catch (error) {
    console.error(error);

    yield* put(
      SiteListActions.listTagsError({
        error,
      })
    );
  }
}

export function* siteListSaga(): Generator<ForkEffect<never>, void> {
  yield* takeLatest(SiteListActions.GET_SITE_LIST_REQUEST, getSiteListSaga);
  yield* takeLatest(SiteListActions.GET_SITE_LIST_MORE_DATA_REQUEST, getSiteListMoreDataSaga);
  yield* takeLatest(SiteListActions.CREATE_SITE_REQUEST, createSiteSaga);
  yield* takeLatest(SiteListActions.LIST_TAGS_REQUEST, listTagsSaga);
}
