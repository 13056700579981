import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import React, { useMemo } from 'react';
import { Permission } from '../../../../../../config/permissions';
import { PermissionGuard } from '../../../../../cross-cutting-concerns/authentication/components/PermissionGuard/PermissionGuard';
import { selectPermissions } from '../../../../../cross-cutting-concerns/authentication/state/authenticationSelectors';
import { isUserPermitted } from '../../../../../cross-cutting-concerns/authentication/utils/isUserPermitted';
import { StyledSiteDetailsSiteManagerList } from './SiteDetailsSiteManagerList.styles';
import { getSiteDetailsSiteManagerListColumns } from './columns/SiteDetailsSiteManagerList.columns';
import { Site, Role, User } from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { LegacyTable } from 'lib/components/LegacyTable/LegacyTable';
import { LegacySecondaryButton } from 'lib/components/LegacyButton/SecondaryButton/LegacySecondaryButton';
import {
  IShowAssignSiteManagerModalAction,
  SiteModalsActions,
} from 'app/modules/site-management/modals/state/siteModalsActions';

interface SiteDetailsSiteManagerListProps {
  site: Site;
}

export const SITE_MANAGER_LIST_ASSIGNABLE_ROLE: Role = Role.CustomerStrategicManager;

export const SiteDetailsSiteManagerList: React.FC<SiteDetailsSiteManagerListProps> = ({ site }): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const siteManagers: User[] = site.managers ?? [];
  const isLoading = false;

  const userPermissions = useSelector(selectPermissions);

  const hasPermissionSiteStrategicManagerUpdate = useMemo(
    () => isUserPermitted(userPermissions, [Permission.Site.StrategicManager.UPDATE]),
    [userPermissions]
  );

  // Re-enable when site managers can be queried with sorting
  //
  // const onTableChange = (
  //   _pagination: TablePaginationConfig,
  //   _filters: Record<string, FilterValue | null>,
  //   sorter: SorterResult<Notification> | SorterResult<Notification>[]
  // ): void => {
  //   if (Array.isArray(sorter)) return;
  //   const sorterField = sorter.field;
  //   const sorterOrder = sorter.order === 'descend' ? SortOrders.Desc : SortOrders.Asc;
  //
  //   noop(sorterField, sorterOrder);
  // };

  const handleUnassign = (user: User): void => {
    dispatch(
      SiteModalsActions.showUnassignSiteManagerModal({
        siteManagerToUnassign: user,
      })
    );
  };

  const tableColumns = getSiteDetailsSiteManagerListColumns({
    t,
    handleUnassign,
    hasPermissionSiteStrategicManagerUpdate,
  });

  return (
    <StyledSiteDetailsSiteManagerList className="site-details-site-manager-list">
      <div className="site-details-site-manager-list__header">
        <p className="site-details-site-manager-list__title">{t('siteDetails.siteManagerList.title')}</p>
      </div>

      <div className="site-details-site-manager-list__body">
        <LegacyTable
          dataSource={siteManagers}
          loading={isLoading}
          className="site-details-site-manager-list__table"
          columns={tableColumns}
          rowKey="id"
          // Re-enable when site managers can be queried with sorting
          // onChange={onTableChange}
          sortDirections={['ascend', 'descend', 'ascend']}
          footerComponents={
            <PermissionGuard requiredPermissions={[Permission.Site.StrategicManager.UPDATE]} fallback={undefined}>
              <div className="site-details-site-manager-list__assign-new-manager-button">
                <LegacySecondaryButton
                  size="s"
                  onClick={(): IShowAssignSiteManagerModalAction =>
                    dispatch(SiteModalsActions.showAssignSiteManagerModal())
                  }
                  className="site-details-site-manager-list__assign-new-manager-button"
                  key="site-details-site-manager-list__assign-new-manager-button"
                >
                  {t('siteDetails.siteManagerList.assignNewManager')}
                </LegacySecondaryButton>
              </div>
            </PermissionGuard>
          }
        />
      </div>
    </StyledSiteDetailsSiteManagerList>
  );
};
