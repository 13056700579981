import { PaginationTokens } from '../../../../cross-cutting-concerns/state-management/interfaces/StateManagement.types';
import { SiteTagsItems } from '../../interfaces/Site.types';
import { IState } from 'app/cross-cutting-concerns/state-management/interfaces/IState';
import { Maybe, Site, SortOrders } from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { Optional } from 'lib/types/Optional';

export const selectData = (state: IState): Optional<Site[]> => state.modules['site-management']['site-list'].data;

export const selectTotalCount = (state: IState): Optional<number> =>
  state.modules['site-management']['site-list'].totalCount;

export const selectIsLoading = (state: IState): Optional<boolean> =>
  state.modules['site-management']['site-list'].isLoading;

export const selectIsLoadingMoreData = (state: IState): Optional<boolean> =>
  state.modules['site-management']['site-list'].isLoadingMoreData;

export const selectPaginationTokens = (state: IState): PaginationTokens =>
  state.modules['site-management']['site-list'].paginationTokens;

export const selectNextPaginationToken = (state: IState): string | null =>
  state.modules['site-management']['site-list'].nextPaginationToken;

export const selectPage = (state: IState): number => state.modules['site-management']['site-list'].page;

export const selectPageSize = (state: IState): number => state.modules['site-management']['site-list'].pageSize;

export const selectActiveSearchTextFilter = (state: IState): Optional<string> =>
  state.modules['site-management']['site-list'].filters.searchText || undefined;

export const selectSortField = (state: IState): Maybe<string> =>
  state.modules['site-management']['site-list'].sortOptions.field;

export const selectSortOrder = (state: IState): Maybe<SortOrders> =>
  state.modules['site-management']['site-list'].sortOptions.order;

export const selectActiveTagsFilter = (state: IState): Optional<string[]> =>
  state.modules['site-management']['site-list'].filters.active.tags || undefined;

export const selectActiveIdsFilter = (state: IState): Optional<string[]> =>
  state.modules['site-management']['site-list'].filters.ids || undefined;

export const selectTagSuggestions = (state: IState): string[] =>
  state.modules['site-management']['site-list'].tags.suggestions.data;

export const selectAreTagSuggestionsLoading = (state: IState): boolean =>
  state.modules['site-management']['site-list'].tags.suggestions.isLoading;

export const selectTagsItems = (state: IState): Optional<SiteTagsItems[]> =>
  state.modules['site-management']['site-list'].filters.tagsItems;
