import { IWorkIntervalLocal } from '../../../cleaning/interfaces/CleaningPlan.types';
import { Machine } from '../../../machine-inventory/interfaces/Machine.types';
import { User } from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { IState } from 'app/cross-cutting-concerns/state-management/interfaces/IState';
import { Optional } from 'lib/types/Optional';

export const selectIsEditSiteNameModalVisible = (state: IState): boolean =>
  state.modules['site-management'].modals.editSiteName.visible;

export const selectIsEditSiteNameModalLoading = (state: IState): boolean =>
  state.modules['site-management'].modals.editSiteName.isLoading;

export const selectIsEditSiteLocationModalVisible = (state: IState): boolean =>
  state.modules['site-management'].modals.editSiteLocation.visible;

export const selectIsEditSiteLocationModalLoading = (state: IState): boolean =>
  state.modules['site-management'].modals.editSiteLocation.isLoading;

export const selectIsDeleteSiteModalVisible = (state: IState): boolean =>
  state.modules['site-management'].modals.deleteSite.visible;

export const selectIsDeleteSiteModalLoading = (state: IState): boolean =>
  state.modules['site-management'].modals.deleteSite.isLoading;

export const selectIsCreateSiteModalVisible = (state: IState): boolean =>
  state.modules['site-management'].modals.createSite.visible;

export const selectIsCreateSiteModalLoading = (state: IState): boolean =>
  state.modules['site-management'].modals.createSite.isLoading;

export const selectIsAssignMachinesModalVisible = (state: IState): boolean =>
  state.modules['site-management'].modals.assignMachines.visible;

export const selectIsAssignMachinesModalLoading = (state: IState): boolean =>
  state.modules['site-management'].modals.assignMachines.isLoading;

export const selectIsUnassignMachinesModalVisible = (state: IState): boolean =>
  state.modules['site-management'].modals.unassignMachines.visible;

export const selectIsUnassignMachinesModalLoading = (state: IState): boolean =>
  state.modules['site-management'].modals.unassignMachines.isLoading;

export const selectUnAssignMachinesModalMachineToUnassign = (state: IState): Optional<Machine> =>
  state.modules['site-management'].modals.unassignMachines.machineToUnassign;

export const selectAreAvailableMachinesLoading = (state: IState): boolean =>
  state.modules['site-management'].modals.assignMachines.machines.isLoading;

export const selectAvailableMachinesForAssignment = (state: IState): Machine[] =>
  state.modules['site-management'].modals.assignMachines.machines.data;

export const selectSearchingAddressValue = (state: IState): Optional<string> =>
  state.modules['site-management'].modals.addressesSuggestion.searchingAddressValue;

export const selectAddressesSuggestion = (
  state: IState
): {
  [key: string]: {
    isLoading: boolean;
    addresses: Optional<string[]>;
  };
} => state.modules['site-management'].modals.addressesSuggestion.data;

export const selectIsChangeGeofenceModalVisible = (state: IState): boolean =>
  state.modules['site-management'].modals.changeGeofence.visible;

export const selectIsChangeGeofenceModalLoading = (state: IState): boolean =>
  state.modules['site-management'].modals.changeGeofence.isLoading;

export const selectIsAddWorkIntervalModalVisible = (state: IState): boolean =>
  state.modules['site-management'].modals.addWorkInterval.visible;

export const selectIsAddWorkIntervalModalLoading = (state: IState): boolean =>
  state.modules['site-management'].modals.addWorkInterval.isLoading;

export const selectIsDeleteWorkIntervalModalVisible = (state: IState): boolean =>
  state.modules['site-management'].modals.deleteWorkInterval.visible;

export const selectIsDeleteWorkIntervalModalLoading = (state: IState): boolean =>
  state.modules['site-management'].modals.deleteWorkInterval.isLoading;

export const selectDeleteWorkIntervalModalWorkIntervalToDelete = (state: IState): Optional<IWorkIntervalLocal> =>
  state.modules['site-management'].modals.deleteWorkInterval.workIntervalToDelete;

export const selectIsEditWorkIntervalModalVisible = (state: IState): boolean =>
  state.modules['site-management'].modals.editWorkInterval.visible;

export const selectIsEditWorkIntervalModalLoading = (state: IState): boolean =>
  state.modules['site-management'].modals.editWorkInterval.isLoading;

export const selectEditWorkIntervalModalWorkIntervalToEdit = (state: IState): Optional<IWorkIntervalLocal> =>
  state.modules['site-management'].modals.editWorkInterval.workIntervalToEdit;

export const selectAreAvailableSiteMachinesLoading = (state: IState): boolean =>
  state.modules['site-management'].modals.siteMachines.isLoading;

export const selectAvailableSiteMachines = (state: IState): Machine[] =>
  state.modules['site-management'].modals.siteMachines.data;

export const selectCreateSiteModalManagers = (state: IState): Optional<User[]> =>
  state.modules['site-management'].modals.createSite.assignManagers.managers;

export const selectCreateSiteModalManagersLoading = (state: IState): boolean =>
  state.modules['site-management'].modals.createSite.assignManagers.isLoading;

export const selectCreateSiteModalTags = (state: IState): string[] =>
  state.modules['site-management'].modals.createSite.tags.data;

export const selectCreateSiteModalAreTagsLoading = (state: IState): boolean =>
  state.modules['site-management'].modals.createSite.tags.isLoading;

export const selectAssignSiteManagerModalManagers = (state: IState): User[] =>
  state.modules['site-management'].modals.assignSiteManager.assignManagers.managers;

export const selectAssignSiteManagerModalManagersLoading = (state: IState): boolean =>
  state.modules['site-management'].modals.assignSiteManager.assignManagers.isLoading;

export const selectIsAssignSiteManagerModalVisible = (state: IState): boolean =>
  state.modules['site-management'].modals.assignSiteManager.visible;

export const selectIsAssignSiteManagerModalLoading = (state: IState): boolean =>
  state.modules['site-management'].modals.assignSiteManager.isLoading;

export const selectIsUnassignSiteManagerModalVisible = (state: IState): boolean =>
  state.modules['site-management'].modals.unassignSiteManager.visible;

export const selectIsUnassignSiteManagerModalLoading = (state: IState): boolean =>
  state.modules['site-management'].modals.unassignSiteManager.isLoading;

export const selectUnassignSiteManagerModalSiteManagerToUnassign = (state: IState): Optional<User> =>
  state.modules['site-management'].modals.unassignSiteManager.siteManagerToUnassign;

export const selectEditSiteTagsModalVisible = (state: IState): boolean =>
  state.modules['site-management'].modals.editSiteTags.visible;

export const selectEditSiteTagsModalIsLoading = (state: IState): boolean =>
  state.modules['site-management'].modals.editSiteTags.isLoading;

export const selectEditSiteTagsModalTagsIsLoading = (state: IState): boolean =>
  state.modules['site-management'].modals.editSiteTags.tags.isLoading;

export const selectEditSiteTagsModalTagsData = (state: IState): string[] =>
  state.modules['site-management'].modals.editSiteTags.tags.data;

export const selectIsEditSiteInfoModalVisible = (state: IState): boolean =>
  state.modules['site-management'].modals.editSiteInfo.visible;

export const selectIsEditSiteInfoModalLoading = (state: IState): boolean =>
  state.modules['site-management'].modals.editSiteInfo.isLoading;
