import { PaginationTokens } from '../../../../cross-cutting-concerns/state-management/interfaces/StateManagement.types';
import { IWorkIntervalLocal } from '../../../cleaning/interfaces/CleaningPlan.types';
import { Machine } from '../../../machine-inventory/interfaces/Machine.types';
import { IState } from 'app/cross-cutting-concerns/state-management/interfaces/IState';
import { Site } from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { Optional } from 'lib/types/Optional';

export const selectData = (state: IState): Optional<Site> => state.modules['site-management']['site-details'].data;

export const selectIsLoading = (state: IState): Optional<boolean> =>
  state.modules['site-management']['site-details'].isLoading;

export const selectMachineListData = (state: IState): Optional<Machine[]> =>
  state.modules['site-management']['site-details'].machines.data;

export const selectMachineTotalCount = (state: IState): Optional<number> =>
  state.modules['site-management']['site-details'].machines.totalCount;

export const selectIsMachineLoading = (state: IState): Optional<boolean> =>
  state.modules['site-management']['site-details'].machines.isLoading;

export const selectMachinePaginationTokens = (state: IState): PaginationTokens =>
  state.modules['site-management']['site-details'].machines.paginationTokens;

export const selectMachinePage = (state: IState): number =>
  state.modules['site-management']['site-details'].machines.page;

export const selectMachinePageSize = (state: IState): number =>
  state.modules['site-management']['site-details'].machines.pageSize;

export const selectIsMapLoading = (state: IState): Optional<boolean> =>
  state.modules['site-management']['site-details'].isMapLoading;

export const selectWorkIntervals = (state: IState): Optional<IWorkIntervalLocal[]> =>
  state.modules['site-management']['site-details'].workIntervals.data;

export const selectAreWorkIntervalsLoading = (state: IState): boolean =>
  state.modules['site-management']['site-details'].workIntervals.isLoading;

export const selectCreateWorkIntervalError = (state: IState): Optional<Error> =>
  state.modules['site-management']['site-details'].createWorkInterval.error;

export const selectUpdateWorkIntervalError = (state: IState): Optional<Error> =>
  state.modules['site-management']['site-details'].updateWorkInterval.error;
