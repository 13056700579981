import { SiteWithOperatingTime } from '../interfaces/Site.types';
import { ReactChartUtils } from 'app/utils/react-charts/ReactChartUtils';

export interface SitePerformanceChartData {
  label: string;
  data: SitePerformanceChartDatum[];
}

export interface SitePerformanceChartDatum {
  primary: string;
  secondary: number;
}

export const SITE_PERFORMANCE_CHART_COLUMN_LABEL_ONE = 'operatingHoursComparisonChart.actual';
export const SITE_PERFORMANCE_CHART_COLUMN_LABEL_TWO = 'operatingHoursComparisonChart.planned';

export class SitePerformanceChartUtils {
  public static convertSitesToChartData(sites: SiteWithOperatingTime[]): SitePerformanceChartData[] {
    const data: SitePerformanceChartData[] = [
      { label: SITE_PERFORMANCE_CHART_COLUMN_LABEL_ONE, data: [] },
      { label: SITE_PERFORMANCE_CHART_COLUMN_LABEL_TWO, data: [] },
    ];

    sites.forEach(({ id, name, operatingTimeForPeriod }) => {
      const { actualTotalOperatingTimeMs, plannedTotalOperatingTimeMs } = operatingTimeForPeriod;

      data[0].data.push({
        primary: ReactChartUtils.combineColumnName(name, id),
        secondary: actualTotalOperatingTimeMs,
      });

      data[1].data.push({
        primary: ReactChartUtils.combineColumnName(name, id),
        secondary: plannedTotalOperatingTimeMs,
      });
    });

    return data;
  }
}
