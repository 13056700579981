import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { isNil, isNumber } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Coords } from 'google-map-react';
import { useAnalyticsSetForm } from '../../../../../cross-cutting-concerns/analytics/hooks/useAnalyticsSetForm';
import {
  AnalyticsForm,
  AnalyticsLink,
} from '../../../../../cross-cutting-concerns/analytics/interfaces/Analytics.types';
import { SiteDetailsPanelActions } from '../../../site-details-panel/state/siteDetailsPanelActions';
import { SiteModalsActions } from '../../state/siteModalsActions';
import {
  selectIsChangeGeofenceModalVisible,
  selectIsChangeGeofenceModalLoading,
} from '../../state/siteModalsSelectors';
import { StyledChangeGeofenceModal } from './ChangeGeofenceModal.styles';
import { Site } from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { GEOFENCE_DEFAULT_RADIUS } from 'app/components/Map/Map';
import { SiteMapPinProps } from 'app/modules/site-management/components/SiteMapPin/SiteMapPin';
import { SiteMap } from 'app/modules/site-management/site-details/components/SiteMap/SiteMap';
import { useAnalyticsLinkActivated } from 'app/cross-cutting-concerns/analytics/hooks/useAnalyticsLinkActivated';
import { TextButton } from 'lib/components/Button/TextButton/TextButton';
import { PrimaryButton } from 'lib/components/Button/PrimaryButton/PrimaryButton';

export interface EditSiteNameModalProps {
  site: Site;
}

export const ChangeGeofenceModal = ({ site }: EditSiteNameModalProps): JSX.Element | null => {
  const analyticsLinkActivated = useAnalyticsLinkActivated();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const mapInstanceRef = useRef<any>(null);
  const mapApisRef = useRef<any>(null);
  const circleGeofenceInstanceRef = useRef<any>(null);
  const [circleGeofenceInitialized, setCircleGeofenceInitialized] = useState(false);

  const circleGeofenceCenter = circleGeofenceInstanceRef.current?.getCenter();
  const circleGeofenceRadius = circleGeofenceInstanceRef.current?.getRadius();

  const isVisible = useSelector(selectIsChangeGeofenceModalVisible);
  const isLoading = useSelector(selectIsChangeGeofenceModalLoading);

  useEffect(() => {
    setCircleGeofenceInitialized(false);
  }, [isVisible]);

  useEffect(() => {
    if (!circleGeofenceInitialized) return;

    circleGeofenceInstanceRef.current.setOptions({
      editable: true,
      draggable: true,
    });
  }, [circleGeofenceInitialized]);

  const handleGoogleApisInitialized = ({ mapInstance, mapApis }: { mapInstance: any; mapApis: any }): void => {
    mapInstanceRef.current = mapInstance;
    mapApisRef.current = mapApis;
  };

  const handleCircleGeofenceInitialized = (circleInstance: any): void => {
    circleGeofenceInstanceRef.current = circleInstance;
    setCircleGeofenceInitialized(true);
  };

  const handleCancel = useCallback(() => dispatch(SiteModalsActions.hideChangeGeofenceModal()), [dispatch]);

  const handleOk = useCallback(() => {
    if (isNil(mapApisRef.current)) return;
    const longitude = circleGeofenceInstanceRef.current?.getCenter()?.lng();
    const latitude = circleGeofenceInstanceRef.current?.getCenter()?.lat();
    const radius = circleGeofenceInstanceRef.current?.getRadius();

    analyticsLinkActivated({
      linkName: AnalyticsLink.CHANGE_GEOFENCE,
    });

    dispatch(
      SiteDetailsPanelActions.setGeofenceRequest({
        siteId: site.id,
        geofence: {
          circle: {
            radius,
            centerPoint: {
              longitude,
              latitude,
            },
          },
        },
      })
    );
  }, [analyticsLinkActivated, circleGeofenceInstanceRef, dispatch, site.id]);

  const siteMapPinProps: SiteMapPinProps = {
    lat: site.location?.latitude,
    lng: site.location?.longitude,
  };

  // Caching geofence position in case editing before saved in DB
  const calculatedGeofence = useMemo(():
    | {
        center: Coords;
        radius: number;
        onCircleGeofenceInitialized?: (circleGeofenceInstance: any) => void;
      }
    | undefined => {
    const hasGeofence =
      !isNil(site.geofence?.centerPoint?.latitude) &&
      !isNil(site.geofence?.centerPoint?.longitude) &&
      isNumber(site.geofence?.radius);
    const hasSiteGeolocation = siteMapPinProps.lat && siteMapPinProps.lng;

    if (hasGeofence) {
      return {
        center: {
          lat: site.geofence?.centerPoint?.latitude ?? 0,
          lng: site.geofence?.centerPoint?.longitude ?? 0,
        },
        radius: site.geofence?.radius ?? 0,
        onCircleGeofenceInitialized: handleCircleGeofenceInitialized,
      };
    }

    if (hasSiteGeolocation) {
      return {
        center: {
          lat: siteMapPinProps?.lat ?? 0,
          lng: siteMapPinProps?.lng ?? 0,
        },
        radius: GEOFENCE_DEFAULT_RADIUS,
        onCircleGeofenceInitialized: handleCircleGeofenceInitialized,
      };
    }

    return undefined;
  }, [
    site.geofence?.centerPoint?.latitude,
    site.geofence?.centerPoint?.longitude,
    site.geofence?.radius,
    siteMapPinProps.lat,
    siteMapPinProps.lng,
  ]);

  useAnalyticsSetForm({
    name: AnalyticsForm.CHANGE_GEOFENCE,
    fields: {
      siteId: site.id,
      geofence: {
        circle: {
          radius: circleGeofenceRadius,
          centerPoint: {
            longitude: circleGeofenceCenter?.lng(),
            latitude: circleGeofenceCenter?.lat(),
          },
        },
      },
    },
    isVisible,
  });

  return (
    <StyledChangeGeofenceModal
      className="change-geofence-modal"
      title={t('siteModals.changeGeofence.title')}
      open={isVisible}
      destroyOnClose={true}
      width={'70vw'}
      footer={[
        <TextButton size="m" onClick={handleCancel} className="cancel-button" key="cancel-button">
          {t('common.discardChanges')}
        </TextButton>,
        <PrimaryButton
          key="submit"
          className="submit-button"
          type="primary"
          disabled={!circleGeofenceInitialized}
          loading={isLoading}
          onClick={handleOk}
        >
          {t('common.save')}
        </PrimaryButton>,
      ]}
    >
      <p>{t('siteModals.changeGeofence.description')}</p>

      <div className="change-geofence-modal__map">
        <SiteMap
          site={site}
          circleGeofence={calculatedGeofence}
          onGoogleApisInitialized={handleGoogleApisInitialized}
        />
      </div>
    </StyledChangeGeofenceModal>
  );
};
