import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as authenticationSelectors from 'app/cross-cutting-concerns/authentication/state/authenticationSelectors';

export const SitePerformanceChartFilter = (): JSX.Element => {
  const { t } = useTranslation();
  const isGcd = useSelector(authenticationSelectors.selectHasAccessToGCD);

  return (
    <div className="site-performance-chart__filter">
      <div className="site-performance-chart__range-picker">
        <div className="site-performance-chart__chart-note">
          <div className="site-performance-chart__chart-note-left">
            <div className="site-performance-chart__chart-note-square-left" />
            <p className="site-performance-chart__chart-note-text">
              {t('operatingHoursComparisonChart.legend.actual')}
            </p>
          </div>
          {isGcd && (
            <div className="site-performance-chart__chart-note-right">
              <div className="site-performance-chart__chart-note-square-right" />
              <p className="site-performance-chart__chart-note-text">
                {t('operatingHoursComparisonChart.legend.planned')}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
