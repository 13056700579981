import styled from 'styled-components';
import { Modal } from 'lib/components/Modal/Modal';

export const StyledDeleteWorkIntervalModal = styled(Modal)`
  .ant-list-items {
    display: flex;
    margin-bottom: 16px;

    .ant-list-item {
      margin-right: 8px;
      border-bottom: unset;
      ${(props): string => props.theme.fontStyles.p1.news};
    }

    .ant-list-item-selected {
      background-color: ${(props): string => props.theme.colors.black};
      color: ${(props): string => props.theme.colors.white};
    }
  }
`;
