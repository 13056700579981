import styled from 'styled-components';

export const StyledCleaningPlanEventPopover = styled.div`
  position: relative;
  width: 200px;
  padding-top: 22px;
  padding-bottom: 15px;

  .cleaning-plan-event-popover__machine-name {
    font-family: ${(props): string => props.theme.fonts.p1Medium};
    font-size: ${(props): string => props.theme.fontSizes.p1};
    line-height: ${(props): string => props.theme.lineHeights.p1};
    font-weight: ${(props): number => props.theme.fontWeights.p1Medium};
    color: ${(props): string => props.theme.colors.black};
    margin-bottom: 0;
  }

  .cleaning-plan-event-popover__device-variant-name {
    font-family: ${(props): string => props.theme.fonts.p1News};
    font-size: ${(props): string => props.theme.fontSizes.p1};
    line-height: ${(props): string => props.theme.lineHeights.p1};
    font-weight: ${(props): number => props.theme.fontWeights.p1News};
    color: ${(props): string => props.theme.colors.black};
    margin-bottom: 0;
  }

  .cleaning-plan-event-popover__time-span {
    font-family: ${(props): string => props.theme.fonts.p1News};
    font-size: ${(props): string => props.theme.fontSizes.p1};
    line-height: ${(props): string => props.theme.lineHeights.p1};
    font-weight: ${(props): number => props.theme.fontWeights.p1News};
    color: ${(props): string => props.theme.colors.grey200};
    margin-top: 8px;
    margin-bottom: 0;
  }

  .cleaning-plan-event-popover__buttons {
    position: absolute;
    right: 0;
    top: 0;
  }

  .cleaning-plan-event-popover__edit-button {
    padding: 0;
    height: 14px;
    width: 14px;

    svg {
      height: 100%;
      width: 100%;
    }
  }

  .cleaning-plan-event-popover__delete-button {
    padding: 0;
    margin-left: 10px;
    height: 14px;
    width: 14px;

    svg {
      height: 100%;
      width: 100%;
    }
  }
`;
