import { produce } from 'immer';
import { AnyAction } from 'redux';
import { IWorkIntervalLocal } from '../../../cleaning/interfaces/CleaningPlan.types';
import { Machine } from '../../../machine-inventory/interfaces/Machine.types';
import { MachineUtils } from '../../../machine-inventory/utils/MachineUtils';
import { SiteTagsList } from '../../interfaces/Site.types';
import { SiteModalsActions } from './siteModalsActions';
import { User } from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { Optional } from 'lib/types/Optional';

export interface SiteModalsState {
  editSiteName: {
    visible: boolean;
    isLoading: boolean;
  };
  editSiteTags: {
    visible: boolean;
    isLoading: boolean;
    tags: {
      data: string[];
      isLoading: boolean;
    };
  };
  editSiteLocation: {
    visible: boolean;
    isLoading: boolean;
  };
  deleteSite: {
    visible: boolean;
    isLoading: boolean;
  };
  assignMachines: {
    visible: boolean;
    isLoading: boolean;
    machines: {
      data: Machine[];
      isLoading: boolean;
      arePicturesLoading: boolean;
    };
  };
  unassignMachines: {
    visible: boolean;
    isLoading: boolean;
    machineToUnassign: Optional<Machine>;
  };
  createSite: {
    visible: boolean;
    isLoading: boolean;
    assignManagers: {
      isLoading: boolean;
      managers: Optional<User[]>;
    };
    tags: {
      isLoading: boolean;
      data: string[];
    };
  };
  addressesSuggestion: {
    searchingAddressValue: Optional<string>;
    data: {
      [key: string]: {
        isLoading: boolean;
        addresses: Optional<string[]>;
      };
    };
  };
  changeGeofence: {
    visible: boolean;
    isLoading: boolean;
  };
  addWorkInterval: {
    visible: boolean;
    isLoading: boolean;
  };
  deleteWorkInterval: {
    visible: boolean;
    isLoading: boolean;
    workIntervalToDelete: Optional<IWorkIntervalLocal>;
  };
  editWorkInterval: {
    visible: boolean;
    isLoading: boolean;
    workIntervalToEdit: Optional<IWorkIntervalLocal>;
  };
  siteMachines: {
    data: Machine[];
    isLoading: boolean;
  };
  assignSiteManager: {
    visible: boolean;
    isLoading: boolean;
    assignManagers: {
      isLoading: boolean;
      managers: User[];
    };
  };
  unassignSiteManager: {
    visible: boolean;
    isLoading: boolean;
    siteManagerToUnassign: Optional<User>;
  };
  editSiteInfo: {
    visible: boolean;
    isLoading: boolean;
  };
}

export const initialState: SiteModalsState = {
  editSiteName: {
    visible: false,
    isLoading: false,
  },
  editSiteTags: {
    visible: false,
    isLoading: false,
    tags: {
      data: [],
      isLoading: false,
    },
  },
  editSiteLocation: {
    visible: false,
    isLoading: false,
  },
  deleteSite: {
    visible: false,
    isLoading: false,
  },
  createSite: {
    visible: false,
    isLoading: false,
    assignManagers: {
      isLoading: false,
      managers: null,
    },
    tags: {
      isLoading: false,
      data: [],
    },
  },
  assignMachines: {
    visible: false,
    isLoading: false,
    machines: {
      data: [],
      isLoading: false,
      arePicturesLoading: true,
    },
  },
  unassignMachines: {
    visible: false,
    isLoading: false,
    machineToUnassign: null,
  },
  addressesSuggestion: {
    searchingAddressValue: null,
    data: {},
  },
  changeGeofence: {
    visible: false,
    isLoading: false,
  },
  addWorkInterval: {
    visible: false,
    isLoading: false,
  },
  deleteWorkInterval: {
    visible: false,
    isLoading: false,
    workIntervalToDelete: null,
  },
  editWorkInterval: {
    visible: false,
    isLoading: false,
    workIntervalToEdit: null,
  },
  siteMachines: {
    data: [],
    isLoading: false,
  },
  assignSiteManager: {
    visible: false,
    isLoading: false,
    assignManagers: {
      isLoading: false,
      managers: [],
    },
  },
  unassignSiteManager: {
    visible: false,
    isLoading: false,
    siteManagerToUnassign: null,
  },
  editSiteInfo: {
    visible: false,
    isLoading: false,
  },
};

export const siteModalsReducer = (state = initialState, action: AnyAction): SiteModalsState =>
  produce(state, draft => {
    switch (action.type) {
      case SiteModalsActions.SHOW_EDIT_SITE_NAME_MODAL: {
        draft.editSiteName.visible = true;
        return draft;
      }

      case SiteModalsActions.HIDE_EDIT_SITE_NAME_MODAL: {
        draft.editSiteName.visible = false;
        return draft;
      }

      case SiteModalsActions.EDIT_SITE_NAME_MODAL_IS_LOADING: {
        draft.editSiteName.isLoading = true;
        return draft;
      }

      case SiteModalsActions.EDIT_SITE_NAME_MODAL_IS_NOT_LOADING: {
        draft.editSiteName.isLoading = false;
        return draft;
      }

      case SiteModalsActions.SHOW_EDIT_SITE_LOCATION_MODAL: {
        draft.editSiteLocation.visible = true;
        return draft;
      }

      case SiteModalsActions.HIDE_EDIT_SITE_LOCATION_MODAL: {
        draft.editSiteLocation.visible = false;
        return draft;
      }

      case SiteModalsActions.EDIT_SITE_LOCATION_MODAL_IS_LOADING: {
        draft.editSiteLocation.isLoading = true;
        return draft;
      }

      case SiteModalsActions.EDIT_SITE_LOCATION_MODAL_IS_NOT_LOADING: {
        draft.editSiteLocation.isLoading = false;
        return draft;
      }

      case SiteModalsActions.SHOW_DELETE_SITE_MODAL: {
        draft.deleteSite.visible = true;
        return draft;
      }

      case SiteModalsActions.HIDE_DELETE_SITE_MODAL: {
        draft.deleteSite.visible = false;
        draft.addressesSuggestion.searchingAddressValue = null;
        return draft;
      }

      case SiteModalsActions.DELETE_SITE_MODAL_IS_LOADING: {
        draft.deleteSite.isLoading = true;
        return draft;
      }

      case SiteModalsActions.DELETE_SITE_MODAL_IS_NOT_LOADING: {
        draft.deleteSite.isLoading = false;
        return draft;
      }

      case SiteModalsActions.SHOW_CREATE_SITE_MODAL: {
        draft.createSite.visible = true;
        return draft;
      }

      case SiteModalsActions.HIDE_CREATE_SITE_MODAL: {
        draft.createSite.visible = false;
        return draft;
      }

      case SiteModalsActions.CREATE_SITE_MODAL_IS_LOADING: {
        draft.createSite.isLoading = true;
        return draft;
      }

      case SiteModalsActions.CREATE_SITE_MODAL_IS_NOT_LOADING: {
        draft.createSite.isLoading = false;
        return draft;
      }

      case SiteModalsActions.CREATE_SITE_MODAL_RESET_STATE: {
        draft.createSite = initialState.createSite;
        return draft;
      }

      case SiteModalsActions.SHOW_ASSIGN_MACHINES_MODAL: {
        draft.assignMachines.visible = true;
        return draft;
      }

      case SiteModalsActions.HIDE_ASSIGN_MACHINES_MODAL: {
        draft.assignMachines.visible = false;
        return draft;
      }

      case SiteModalsActions.ASSIGN_MACHINES_MODAL_IS_LOADING: {
        draft.assignMachines.isLoading = true;
        return draft;
      }

      case SiteModalsActions.ASSIGN_MACHINES_MODAL_IS_NOT_LOADING: {
        draft.assignMachines.isLoading = false;
        return draft;
      }

      case SiteModalsActions.SHOW_UNASSIGN_MACHINES_MODAL: {
        const { machineToUnassign } = action.payload;

        draft.unassignMachines.visible = true;
        draft.unassignMachines.machineToUnassign = machineToUnassign;
        return draft;
      }

      case SiteModalsActions.HIDE_UNASSIGN_MACHINES_MODAL: {
        draft.unassignMachines.visible = false;
        draft.unassignMachines.machineToUnassign = null;
        return draft;
      }

      case SiteModalsActions.UNASSIGN_MACHINES_MODAL_IS_LOADING: {
        draft.unassignMachines.isLoading = true;
        return draft;
      }

      case SiteModalsActions.UNASSIGN_MACHINES_MODAL_IS_NOT_LOADING: {
        draft.unassignMachines.isLoading = false;
        return draft;
      }

      case SiteModalsActions.AVAILABLE_MACHINES_REQUEST: {
        draft.assignMachines.machines.isLoading = true;
        draft.assignMachines.machines.arePicturesLoading = true;
        return draft;
      }

      case SiteModalsActions.AVAILABLE_MACHINES_SUCCESS: {
        const {
          machines: { data },
        } = action.payload;

        draft.assignMachines.machines.data = data;
        draft.assignMachines.machines.isLoading = false;
        return draft;
      }

      case SiteModalsActions.AVAILABLE_MACHINES_PICTURES_SUCCESS: {
        const {
          machines: { data: machineVariantData },
        } = action.payload;

        draft.assignMachines.machines.arePicturesLoading = false;
        draft.assignMachines.machines.data = draft.assignMachines.machines.data?.map(machineWithoutVariantData =>
          MachineUtils.mergeMachineWithVariantData(machineWithoutVariantData, machineVariantData)
        );
        return draft;
      }

      case SiteModalsActions.AVAILABLE_MACHINES_ERROR: {
        draft.assignMachines.machines.isLoading = false;
        return draft;
      }

      case SiteModalsActions.GET_ADDRESSES_SUGGESTION_REQUEST: {
        const {
          filter: { text },
        } = action.payload;

        draft.addressesSuggestion.data[text] = {
          isLoading: true,
          addresses: undefined,
        };

        return draft;
      }

      case SiteModalsActions.GET_ADDRESSES_SUGGESTION_SUCCESS: {
        const {
          filter: { text },
          siteAddressesSuggestion: { data: addresses },
        } = action.payload;

        draft.addressesSuggestion.data[text] = {
          addresses,
          isLoading: false,
        };

        return draft;
      }

      case SiteModalsActions.GET_ADDRESSES_SUGGESTION_ERROR: {
        const {
          filter: { text },
        } = action.payload;

        draft.addressesSuggestion.data[text] = {
          addresses: undefined,
          isLoading: false,
        };
        return draft;
      }

      case SiteModalsActions.SET_SEARCHING_ADDRESS_VALUE: {
        const { value } = action.payload;

        draft.addressesSuggestion.searchingAddressValue = value;
        return draft;
      }

      case SiteModalsActions.SHOW_CHANGE_GEOFENCE_MODAL: {
        draft.changeGeofence.visible = true;
        return draft;
      }

      case SiteModalsActions.HIDE_CHANGE_GEOFENCE_MODAL: {
        draft.changeGeofence.visible = false;
        return draft;
      }

      case SiteModalsActions.CHANGE_GEOFENCE_MODAL_IS_LOADING: {
        draft.changeGeofence.isLoading = true;
        return draft;
      }

      case SiteModalsActions.CHANGE_GEOFENCE_MODAL_IS_NOT_LOADING: {
        draft.changeGeofence.isLoading = false;
        return draft;
      }

      case SiteModalsActions.SHOW_ADD_WORK_INTERVAL_MODAL: {
        draft.addWorkInterval.visible = true;
        return draft;
      }

      case SiteModalsActions.HIDE_ADD_WORK_INTERVAL_MODAL: {
        draft.addWorkInterval.visible = false;
        return draft;
      }

      case SiteModalsActions.ADD_WORK_INTERVAL_MODAL_IS_LOADING: {
        draft.addWorkInterval.isLoading = true;
        return draft;
      }

      case SiteModalsActions.ADD_WORK_INTERVAL_MODAL_IS_NOT_LOADING: {
        draft.addWorkInterval.isLoading = false;
        return draft;
      }

      case SiteModalsActions.SHOW_DELETE_WORK_INTERVAL_MODAL: {
        const { workIntervalToDelete } = action.payload;

        draft.deleteWorkInterval.visible = true;
        draft.deleteWorkInterval.workIntervalToDelete = workIntervalToDelete;
        return draft;
      }

      case SiteModalsActions.HIDE_DELETE_WORK_INTERVAL_MODAL: {
        draft.deleteWorkInterval.visible = false;
        draft.deleteWorkInterval.workIntervalToDelete = null;
        return draft;
      }

      case SiteModalsActions.DELETE_WORK_INTERVAL_MODAL_IS_LOADING: {
        draft.deleteWorkInterval.isLoading = true;
        return draft;
      }

      case SiteModalsActions.DELETE_WORK_INTERVAL_MODAL_IS_NOT_LOADING: {
        draft.deleteWorkInterval.isLoading = false;
        return draft;
      }

      case SiteModalsActions.SHOW_EDIT_WORK_INTERVAL_MODAL: {
        const {
          payload: { workIntervalToEdit },
        } = action;

        draft.editWorkInterval.visible = true;
        draft.editWorkInterval.workIntervalToEdit = workIntervalToEdit;
        return draft;
      }

      case SiteModalsActions.HIDE_EDIT_WORK_INTERVAL_MODAL: {
        draft.editWorkInterval.visible = false;
        draft.editWorkInterval.workIntervalToEdit = null;
        return draft;
      }

      case SiteModalsActions.EDIT_WORK_INTERVAL_MODAL_IS_LOADING: {
        draft.editWorkInterval.isLoading = true;
        return draft;
      }

      case SiteModalsActions.EDIT_WORK_INTERVAL_MODAL_IS_NOT_LOADING: {
        draft.editWorkInterval.isLoading = false;
        return draft;
      }

      case SiteModalsActions.AVAILABLE_SITE_MACHINES_REQUEST: {
        draft.siteMachines.data = [];
        draft.siteMachines.isLoading = true;
        return draft;
      }

      case SiteModalsActions.AVAILABLE_SITE_MACHINES_SUCCESS: {
        const {
          site: {
            data: {
              machines: { data },
            },
          },
        } = action.payload;

        draft.siteMachines.data = data;
        draft.siteMachines.isLoading = false;
        return draft;
      }

      case SiteModalsActions.AVAILABLE_SITE_MACHINES_ERROR: {
        draft.siteMachines.isLoading = false;
        return draft;
      }

      case SiteModalsActions.GET_SITE_MANAGER_LIST_REQUEST: {
        draft.assignSiteManager.assignManagers.isLoading = true;
        draft.createSite.assignManagers.isLoading = true;
        return draft;
      }

      case SiteModalsActions.GET_SITE_MANAGER_LIST_SUCCESS: {
        const {
          users: { data },
        } = action.payload;

        draft.assignSiteManager.assignManagers.isLoading = false;
        draft.assignSiteManager.assignManagers.managers = data;
        draft.createSite.assignManagers.isLoading = false;
        draft.createSite.assignManagers.managers = data;
        return draft;
      }

      case SiteModalsActions.GET_SITE_MANAGER_LIST_ERROR: {
        draft.assignSiteManager.assignManagers.isLoading = false;
        draft.createSite.assignManagers.isLoading = false;
        return draft;
      }

      case SiteModalsActions.SHOW_ASSIGN_SITE_MANAGER_MODAL: {
        draft.assignSiteManager.visible = true;
        return draft;
      }

      case SiteModalsActions.HIDE_ASSIGN_SITE_MANAGER_MODAL: {
        draft.assignSiteManager.visible = false;
        return draft;
      }

      case SiteModalsActions.ASSIGN_SITE_MANAGER_IS_LOADING: {
        draft.assignSiteManager.isLoading = true;
        return draft;
      }

      case SiteModalsActions.ASSIGN_SITE_MANAGER_IS_NOT_LOADING: {
        draft.assignSiteManager.isLoading = false;
        return draft;
      }

      case SiteModalsActions.SHOW_UNASSIGN_SITE_MANAGER_MODAL: {
        const { siteManagerToUnassign } = action.payload;

        draft.unassignSiteManager.visible = true;
        draft.unassignSiteManager.siteManagerToUnassign = siteManagerToUnassign;
        return draft;
      }

      case SiteModalsActions.HIDE_UNASSIGN_SITE_MANAGER_MODAL: {
        draft.unassignSiteManager.visible = false;
        draft.unassignSiteManager.siteManagerToUnassign = null;
        return draft;
      }

      case SiteModalsActions.UNASSIGN_SITE_MANAGER_IS_LOADING: {
        draft.unassignSiteManager.isLoading = true;
        return draft;
      }

      case SiteModalsActions.UNASSIGN_SITE_MANAGER_IS_NOT_LOADING: {
        draft.unassignSiteManager.isLoading = false;
        return draft;
      }

      case SiteModalsActions.SHOW_EDIT_SITE_TAGS_MODAL: {
        draft.editSiteTags.visible = true;
        return draft;
      }

      case SiteModalsActions.HIDE_EDIT_SITE_TAGS_MODAL: {
        draft.editSiteTags.visible = false;
        draft.editSiteTags.tags = initialState.editSiteTags.tags;
        return draft;
      }

      case SiteModalsActions.EDIT_SITE_TAGS_MODAL_IS_LOADING: {
        draft.editSiteTags.isLoading = true;
        return draft;
      }

      case SiteModalsActions.EDIT_SITE_TAGS_MODAL_IS_NOT_LOADING: {
        draft.editSiteTags.isLoading = false;
        return draft;
      }

      case SiteModalsActions.EDIT_SITE_TAGS_MODAL_RESET_STATE: {
        draft.editSiteTags = initialState.editSiteTags;
        return draft;
      }

      case SiteModalsActions.LIST_TAGS_REQUEST: {
        draft.createSite.tags.isLoading = true;
        draft.editSiteTags.tags.isLoading = true;
        return draft;
      }

      case SiteModalsActions.LIST_TAGS_SUCCESS: {
        const {
          tags: { data },
        } = action.payload as SiteTagsList;
        const tags = data.map((datum: { tag: string }) => datum.tag);

        draft.createSite.tags.data = tags;
        draft.createSite.tags.isLoading = false;
        draft.editSiteTags.tags.data = tags;
        draft.editSiteTags.tags.isLoading = false;
        return draft;
      }

      case SiteModalsActions.LIST_TAGS_ERROR: {
        draft.createSite.tags.isLoading = false;
        draft.editSiteTags.tags.isLoading = false;
        return draft;
      }

      case SiteModalsActions.SHOW_EDIT_SITE_INFO_MODAL: {
        draft.editSiteInfo.visible = true;
        return draft;
      }

      case SiteModalsActions.HIDE_EDIT_SITE_INFO_MODAL: {
        draft.editSiteInfo.visible = false;
        return draft;
      }

      case SiteModalsActions.EDIT_SITE_INFO_MODAL_IS_LOADING: {
        draft.editSiteInfo.isLoading = true;
        return draft;
      }

      case SiteModalsActions.EDIT_SITE_INFO_MODAL_IS_NOT_LOADING: {
        draft.editSiteInfo.isLoading = false;
        return draft;
      }

      default:
        return draft;
    }
  });
