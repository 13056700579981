import { ApolloQueryResult, FetchResult, gql } from '@apollo/client';
import { inject, injectable } from 'tsyringe';
import {
  SiteList,
  SiteDetails,
  SiteUpdateResponse,
  SiteDelete,
  SiteCreate,
  SiteAssignMachineResponse,
  SiteUnassignMachineResponse,
  SiteAddressCoordinateResponse,
  SiteAddressesSuggestionResponse,
  SiteGeofenceSetupResponse,
  SiteMachinesAvailable,
  SiteAssignManagersResponse,
  SiteUnassignManagerResponse,
  SiteListWithOperatingTime,
  SiteMachineVariantData,
  SiteTagsList,
  SiteTagsUpdateResponse,
  SiteMachinesList,
  SiteMachinesListWithVariantData,
} from './interfaces/Site.types';
import { GraphQlClient } from 'app/cross-cutting-concerns/communication/clients/GraphQlClient';
import {
  QuerySitesArgs,
  QuerySiteArgs,
  MutationSiteDeleteArgs,
  MutationSiteCreateArgs,
  MutationSiteUpdateArgs,
  InputFilterSiteAddressCoordinateGet,
  QuerySiteAddressesSuggestionArgs,
  QuerySiteAddressCoordinateArgs,
  InputCircle,
  QueryUsersArgs,
  InputPagingOptions,
  InputPeriod,
  InputFilterSiteMachinesList,
  QueryTagsArgs,
  MutationSiteTagsOverrideArgs,
  InputFilterSiteCleaningStatistic,
  SiteMachinesArgs,
} from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';

export type SiteClientListOptions = Omit<QuerySitesArgs, 'customerId'>;

export type SiteClientListSitesWithOperatingTimeOptions = SiteClientListOptions & {
  period: InputPeriod;
};

export type SiteClientDetailsOptions = QuerySiteArgs & {
  machinePaginationOptions: InputPagingOptions;
};

export type SiteClientGetAddressesSuggestionOptions = QuerySiteAddressesSuggestionArgs;
export type SiteClientGetAddressCoordinateOptions = QuerySiteAddressCoordinateArgs;

export type SiteClientUpdateOptions = MutationSiteUpdateArgs;
export type SiteClientUpdateSiteTagsOptions = MutationSiteTagsOverrideArgs;

export type SiteClientDeleteOptions = MutationSiteDeleteArgs;

export type SiteClientCreateOptions = MutationSiteCreateArgs;

export type SiteClientListAvailableSiteMachinesOptions = QuerySiteArgs & {
  filter: InputFilterSiteMachinesList;
  machinePaginationOptions: InputPagingOptions;
};

export interface SiteClientAssignMachineOptions {
  siteId: string;
  machineIds: string[];
}

export interface SiteClientUnassignMachineOptions {
  siteId: string;
  machineId: string;
}

export type SiteAddressCoordinateOptions = InputFilterSiteAddressCoordinateGet;

export interface SiteClientSetGeofenceOptions {
  siteId: string;
  geofence: {
    circle: InputCircle;
  };
}

export type SiteClientManagerListOptions = QueryUsersArgs;

export interface SiteClientAssignManagersOptions {
  siteId: string;
  userIds: string[];
}

export interface SiteClientUnassignManagerOptions {
  siteId: string;
  userId: string;
}
export type SiteClientListTagsOptions = QueryTagsArgs;

export type SiteClientListWithRobotDataOptions = SiteClientListOptions & {
  machinesOptions?: SiteMachinesArgs;
  cleaningStatisticFilter?: InputFilterSiteCleaningStatistic;
};

@injectable()
export class SiteClient {
  constructor(@inject('GraphQlClient') private client: GraphQlClient) {}

  public list = async ({
    paginationOptions,
    filter,
    sortOptions,
  }: SiteClientListOptions): Promise<ApolloQueryResult<SiteList>> =>
    this.client.query({
      query: gql`
        query Sites(
          $paginationOptions: InputPagingOptions!
          $filter: InputFilterSitesList
          $sortOptions: InputSortOptions
        ) {
          sites(paginationOptions: $paginationOptions, filter: $filter, sortOptions: $sortOptions) {
            metadata {
              totalCount
              paginationToken
            }
            data {
              numberMachinesAssigned
              name
              id
              customerId
              tags
              location {
                address
                latitude
                longitude
              }
            }
          }
        }
      `,
      variables: {
        paginationOptions,
        filter,
        sortOptions,
      },
    });

  public listForSelect = async ({
    paginationOptions,
    filter,
  }: SiteClientListOptions): Promise<ApolloQueryResult<SiteList>> =>
    this.client.query({
      query: gql`
        query Sites($paginationOptions: InputPagingOptions!, $filter: InputFilterSitesList) {
          sites(paginationOptions: $paginationOptions, filter: $filter) {
            data {
              id
              name
            }
            metadata {
              totalCount
              paginationToken
            }
          }
        }
      `,
      variables: {
        paginationOptions,
        filter,
      },
    });

  public get = async ({
    id,
    machinePaginationOptions,
  }: SiteClientDetailsOptions): Promise<ApolloQueryResult<SiteDetails>> =>
    this.client.query({
      query: gql`
        query Site($id: ID!, $machinePaginationOptions: InputPagingOptions!) {
          site(id: $id) {
            data {
              machines(paginationOptions: $machinePaginationOptions) {
                data {
                  serialNumber
                  name
                  materialNumber
                  id
                  lastActivityAt
                  type {
                    name
                  }
                }
                metadata {
                  paginationToken
                  totalCount
                }
              }
              name
              id
              tags
              location {
                address
                latitude
                longitude
              }
              geofence {
                centerPoint {
                  latitude
                  longitude
                }
                id
                radius
              }
              managers {
                role
                id
                displayName
              }
              tags
            }
          }
        }
      `,
      variables: {
        id,
        machinePaginationOptions,
      },
    });

  public getMachinePictures = async ({
    id,
    machinePaginationOptions,
  }: SiteClientDetailsOptions): Promise<ApolloQueryResult<SiteMachineVariantData>> =>
    this.client.query({
      query: gql`
        query SiteMachinePictures($id: ID!, $machinePaginationOptions: InputPagingOptions!) {
          site(id: $id) {
            data {
              id
              machines(paginationOptions: $machinePaginationOptions) {
                data {
                  id
                  variant {
                    picture {
                      overview
                      product
                      thumbnail
                    }
                  }
                }
                metadata {
                  paginationToken
                  totalCount
                }
              }
            }
          }
        }
      `,
      variables: {
        id,
        machinePaginationOptions,
      },
    });

  public update = async ({ id, input }: SiteClientUpdateOptions): Promise<FetchResult<SiteUpdateResponse>> =>
    this.client.mutate({
      mutation: gql`
        mutation SiteUpdate($id: ID!, $input: InputSiteUpdate!) {
          siteUpdate(id: $id, input: $input) {
            data {
              numberMachinesAssigned
              name
              id
              customerId
              location {
                address
                latitude
                longitude
              }
            }
          }
        }
      `,
      variables: {
        id,
        input,
      },
    });

  public updateSiteTags = async ({
    id,
    input,
  }: SiteClientUpdateSiteTagsOptions): Promise<FetchResult<SiteTagsUpdateResponse>> =>
    this.client.mutate({
      mutation: gql`
        mutation SiteTagsOverride($id: ID!, $input: InputSiteTagsOverride!) {
          siteTagsOverride(id: $id, input: $input) {
            data {
              customerId
              siteId
              tags
            }
          }
        }
      `,
      variables: {
        id,
        input,
      },
    });

  public delete = async ({ id }: SiteClientDeleteOptions): Promise<FetchResult<SiteDelete>> =>
    this.client.mutate({
      mutation: gql`
        mutation SiteDelete($id: ID!) {
          siteDelete(id: $id) {
            data
          }
        }
      `,
      variables: {
        id,
      },
    });

  public assignMachine = async ({
    siteId,
    machineIds,
  }: SiteClientAssignMachineOptions): Promise<FetchResult<SiteAssignMachineResponse>> =>
    this.client.mutate({
      mutation: gql`
        mutation SiteAssignMachine($input: InputSiteAssignMachine!) {
          siteAssignMachine(input: $input) {
            data {
              id
            }
          }
        }
      `,
      variables: {
        input: {
          siteId,
          machineIds,
        },
      },
    });

  public unassignMachine = async ({
    siteId,
    machineId,
  }: SiteClientUnassignMachineOptions): Promise<FetchResult<SiteUnassignMachineResponse>> =>
    this.client.mutate({
      mutation: gql`
        mutation SiteUnassignMachine($input: InputSiteUnassignMachine!) {
          siteUnassignMachine(input: $input) {
            data {
              id
            }
          }
        }
      `,
      variables: {
        input: {
          siteId,
          machineId,
        },
      },
    });

  public create = async ({ input }: SiteClientCreateOptions): Promise<FetchResult<SiteCreate>> =>
    this.client.mutate({
      mutation: gql`
        mutation SiteCreate($input: InputSiteCreate!) {
          siteCreate(input: $input) {
            data {
              customerId
              id
              name
              numberMachinesAssigned
            }
          }
        }
      `,
      variables: {
        input,
      },
    });

  public getAddressesSuggestion = async ({
    filter,
    paginationOptions,
  }: SiteClientGetAddressesSuggestionOptions): Promise<ApolloQueryResult<SiteAddressesSuggestionResponse>> =>
    this.client.query({
      query: gql`
        query SiteAddressesSuggestion(
          $filter: InputFilterSiteAddressesSuggestionList!
          $paginationOptions: InputSiteAddressesSuggestionListPagingOptions!
        ) {
          siteAddressesSuggestion(filter: $filter, paginationOptions: $paginationOptions) {
            data
          }
        }
      `,
      variables: {
        filter,
        paginationOptions,
      },
    });

  public getAddressCoordinate = async ({
    filter,
  }: SiteClientGetAddressCoordinateOptions): Promise<ApolloQueryResult<SiteAddressCoordinateResponse>> =>
    this.client.query({
      query: gql`
        query SiteAddressCoordinate($filter: InputFilterSiteAddressCoordinateGet!) {
          siteAddressCoordinate(filter: $filter) {
            data {
              latitude
              longitude
            }
          }
        }
      `,
      variables: {
        filter,
      },
    });

  public setGeofence = async ({
    siteId,
    geofence,
  }: SiteClientSetGeofenceOptions): Promise<FetchResult<SiteGeofenceSetupResponse>> =>
    this.client.mutate({
      mutation: gql`
        mutation SiteGeofenceSetup($id: ID!, $input: InputSiteGeofenceSetup!) {
          siteGeofenceSetup(id: $id, input: $input) {
            data
          }
        }
      `,
      variables: {
        id: siteId,
        input: geofence,
      },
    });

  public listAvailableSiteMachines = async ({
    id,
    filter,
    machinePaginationOptions,
  }: SiteClientListAvailableSiteMachinesOptions): Promise<ApolloQueryResult<SiteMachinesAvailable>> =>
    this.client.query({
      query: gql`
        query Site($id: ID!, $filter: InputFilterSiteMachinesList, $machinePaginationOptions: InputPagingOptions!) {
          site(id: $id) {
            data {
              machines(filter: $filter, paginationOptions: $machinePaginationOptions) {
                data {
                  id
                  name
                }
              }
            }
          }
        }
      `,
      variables: {
        id,
        filter,
        machinePaginationOptions,
      },
    });

  public assignManagers = async ({
    siteId,
    userIds,
  }: SiteClientAssignManagersOptions): Promise<FetchResult<SiteAssignManagersResponse>> =>
    this.client.mutate({
      mutation: gql`
        mutation SiteAssignManagers($input: InputSiteAssignManagers!) {
          siteAssignManagers(input: $input) {
            data {
              userId
            }
          }
        }
      `,
      variables: {
        input: {
          siteId,
          userIds,
        },
      },
    });

  public unassignManager = async ({
    siteId,
    userId,
  }: SiteClientUnassignManagerOptions): Promise<FetchResult<SiteUnassignManagerResponse>> =>
    this.client.mutate({
      mutation: gql`
        mutation SiteUnassignManager($input: InputSiteUnassignManager!) {
          siteUnassignManager(input: $input) {
            data {
              userId
            }
          }
        }
      `,
      variables: {
        input: {
          siteId,
          userId,
        },
      },
    });

  public listSitesWithOperatingTime = async ({
    paginationOptions,
    period,
  }: SiteClientListSitesWithOperatingTimeOptions): Promise<ApolloQueryResult<SiteListWithOperatingTime>> =>
    this.client.query({
      query: gql`
        query Sites($paginationOptions: InputPagingOptions!, $period: InputPeriod!) {
          sites(paginationOptions: $paginationOptions) {
            metadata {
              totalCount
              paginationToken
            }
            data {
              id
              name
              operatingTimeForPeriod(period: $period) {
                actualTotalOperatingTimeMs
                plannedTotalOperatingTimeMs
                startAt
                endAt
              }
            }
          }
        }
      `,
      variables: {
        paginationOptions,
        period,
      },
    });

  public listTags = async ({
    filter,
    paginationOptions,
  }: SiteClientListTagsOptions): Promise<ApolloQueryResult<SiteTagsList>> =>
    this.client.query({
      query: gql`
        query Tags($filter: InputFilterTagsList, $paginationOptions: InputPagingOptions!) {
          tags(filter: $filter, paginationOptions: $paginationOptions) {
            data {
              tag
              customerId
            }
            metadata {
              totalCount
              paginationToken
            }
          }
        }
      `,
      variables: {
        filter,
        paginationOptions,
      },
    });

  public listForRobotDashboard = async ({
    paginationOptions,
    filter,
    sortOptions,
  }: SiteClientListOptions): Promise<ApolloQueryResult<SiteList>> =>
    this.client.query({
      query: gql`
        query Sites(
          $paginationOptions: InputPagingOptions!
          $filter: InputFilterSitesList
          $sortOptions: InputSortOptions
        ) {
          sites(paginationOptions: $paginationOptions, filter: $filter, sortOptions: $sortOptions) {
            data {
              id
              name
            }
            metadata {
              totalCount
              paginationToken
            }
          }
        }
      `,
      variables: {
        paginationOptions,
        filter,
        sortOptions,
      },
    });

  public listWithCleaningStatistic = async ({
    paginationOptions,
    filter,
    cleaningStatisticFilter,
  }: SiteClientListWithRobotDataOptions): Promise<ApolloQueryResult<SiteList>> =>
    this.client.query({
      query: gql`
        query ListWithCleaningStatistic(
          $paginationOptions: InputPagingOptions!
          $filter: InputFilterSitesList
          $cleaningStatisticFilter: InputFilterSiteCleaningStatistic!
        ) {
          sites(paginationOptions: $paginationOptions, filter: $filter) {
            data {
              id
              cleaningStatistic(filter: $cleaningStatisticFilter) {
                data {
                  distanceDriven
                  taskCoverage
                  tasksCompleted
                  totalCleanedArea
                  totalCleaningHrs
                }
              }
            }
            metadata {
              totalCount
              paginationToken
            }
          }
        }
      `,
      variables: {
        paginationOptions,
        filter,
        cleaningStatisticFilter,
      },
    });

  public listWithMachines = async ({
    paginationOptions,
    filter,
    machinesOptions,
  }: SiteClientListWithRobotDataOptions): Promise<ApolloQueryResult<SiteMachinesList>> =>
    this.client.query({
      query: gql`
        query Sites(
          $paginationOptions: InputPagingOptions!
          $filter: InputFilterSitesList
          $machinesFilter: InputFilterSiteMachinesList
          $machinesPaginationOptions: InputPagingOptions!
        ) {
          sites(paginationOptions: $paginationOptions, filter: $filter) {
            data {
              id
              machines(filter: $machinesFilter, paginationOptions: $machinesPaginationOptions) {
                data {
                  id
                  name
                  serialNumber
                  materialNumber
                  robotStatus
                  connectionStatus
                  type {
                    name
                  }
                  site {
                    id
                    name
                  }
                }
                metadata {
                  paginationToken
                  totalCount
                }
              }
            }
            metadata {
              totalCount
              paginationToken
            }
          }
        }
      `,
      variables: {
        paginationOptions,
        filter,
        machinesFilter: machinesOptions?.filter,
        machinesPaginationOptions: machinesOptions?.paginationOptions,
      },
    });

  public listWithMachinesTelemetries = async ({
    paginationOptions,
    filter,
    machinesOptions,
  }: SiteClientListWithRobotDataOptions): Promise<ApolloQueryResult<SiteMachinesList>> =>
    this.client.query({
      query: gql`
        query Sites(
          $paginationOptions: InputPagingOptions!
          $filter: InputFilterSitesList
          $machinesFilter: InputFilterSiteMachinesList
          $machinesPaginationOptions: InputPagingOptions!
        ) {
          sites(paginationOptions: $paginationOptions, filter: $filter) {
            data {
              id
              machines(filter: $machinesFilter, paginationOptions: $machinesPaginationOptions) {
                data {
                  id
                  states {
                    machineId
                    stateName
                    stateValue
                  }
                }
                metadata {
                  paginationToken
                  totalCount
                }
              }
            }
            metadata {
              totalCount
              paginationToken
            }
          }
        }
      `,
      variables: {
        paginationOptions,
        filter,
        machinesFilter: machinesOptions?.filter,
        machinesPaginationOptions: machinesOptions?.paginationOptions,
      },
    });

  public listWithMachinesLatestCtr = async ({
    paginationOptions,
    filter,
    machinesOptions,
  }: SiteClientListWithRobotDataOptions): Promise<ApolloQueryResult<SiteMachinesList>> =>
    this.client.query({
      query: gql`
        query Sites(
          $paginationOptions: InputPagingOptions!
          $filter: InputFilterSitesList
          $machinesFilter: InputFilterSiteMachinesList
          $machinesPaginationOptions: InputPagingOptions!
        ) {
          sites(paginationOptions: $paginationOptions, filter: $filter) {
            data {
              id
              machines(filter: $machinesFilter, paginationOptions: $machinesPaginationOptions) {
                data {
                  id
                  cleaningTaskReportLatestGet {
                    data {
                      routeName
                      finishedAt
                    }
                  }
                }
                metadata {
                  paginationToken
                  totalCount
                }
              }
            }
            metadata {
              totalCount
              paginationToken
            }
          }
        }
      `,
      variables: {
        paginationOptions,
        filter,
        machinesFilter: machinesOptions?.filter,
        machinesPaginationOptions: machinesOptions?.paginationOptions,
      },
    });

  public listWithMachinesLatestRoutine = async ({
    paginationOptions,
    filter,
    machinesOptions,
  }: SiteClientListWithRobotDataOptions): Promise<ApolloQueryResult<SiteMachinesList>> =>
    this.client.query({
      query: gql`
        query Sites(
          $paginationOptions: InputPagingOptions!
          $filter: InputFilterSitesList
          $machinesFilter: InputFilterSiteMachinesList
          $machinesPaginationOptions: InputPagingOptions!
        ) {
          sites(paginationOptions: $paginationOptions, filter: $filter) {
            data {
              id
              machines(filter: $machinesFilter, paginationOptions: $machinesPaginationOptions) {
                data {
                  id
                  latestRoutine {
                    data {
                      createdAt
                      currentRepeat
                      estimatedEndDate
                      executionId
                      machineId
                      name
                      numberOfRepeats
                      routineId
                      startDate
                      status
                    }
                  }
                }
                metadata {
                  paginationToken
                  totalCount
                }
              }
            }
            metadata {
              totalCount
              paginationToken
            }
          }
        }
      `,
      variables: {
        paginationOptions,
        filter,
        machinesFilter: machinesOptions?.filter,
        machinesPaginationOptions: machinesOptions?.paginationOptions,
      },
    });

  public listWithMachinesPictures = async ({
    paginationOptions,
    filter,
    machinesOptions,
  }: SiteClientListWithRobotDataOptions): Promise<ApolloQueryResult<SiteMachinesListWithVariantData>> =>
    this.client.query({
      query: gql`
        query Sites(
          $paginationOptions: InputPagingOptions!
          $filter: InputFilterSitesList
          $machinesFilter: InputFilterSiteMachinesList
          $machinesPaginationOptions: InputPagingOptions!
        ) {
          sites(paginationOptions: $paginationOptions, filter: $filter) {
            data {
              id
              machines(filter: $machinesFilter, paginationOptions: $machinesPaginationOptions) {
                data {
                  id
                  variant {
                    picture {
                      overview
                      product
                      thumbnail
                    }
                  }
                }
                metadata {
                  paginationToken
                  totalCount
                }
              }
            }
            metadata {
              totalCount
              paginationToken
            }
          }
        }
      `,
      variables: {
        paginationOptions,
        filter,
        machinesFilter: machinesOptions?.filter,
        machinesPaginationOptions: machinesOptions?.paginationOptions,
      },
    });
}
