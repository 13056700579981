import { produce } from 'immer';
import { AnyAction } from 'redux';
import { Optional } from '../../../../../lib/types/Optional';
import { PaginationTokens } from '../../../../cross-cutting-concerns/state-management/interfaces/StateManagement.types';
import { IWorkIntervalLocal, IWorkIntervalUtc } from '../../../cleaning/interfaces/CleaningPlan.types';
import { CleaningActions } from '../../../cleaning/state/cleaningActions';
import { CleaningPlanUtils } from '../../../cleaning/utils/CleaningPlanUtils';
import { Machine } from '../../../machine-inventory/interfaces/Machine.types';
import { MachineUtils } from '../../../machine-inventory/utils/MachineUtils';
import { SiteDetailsPanelActions } from './siteDetailsPanelActions';
import { Site, User } from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { DEFAULT_PAGE_SIZE_VALUE, DEFAULT_PAGE_VALUE } from 'config/constants';

export interface SiteDetailsPanelState {
  data: Optional<Site>;
  isLoading: Optional<boolean>;
  isMapLoading: Optional<boolean>;
  machines: {
    data: Optional<Machine[]>;
    totalCount: Optional<number>;
    isLoading: Optional<boolean>;
    arePicturesLoading: boolean;
    paginationTokens: PaginationTokens;
    page: number;
    pageSize: number;
  };
  workIntervals: {
    data: Optional<IWorkIntervalLocal[]>;
    isLoading: boolean;
  };
  createWorkInterval: {
    error: Optional<Error>;
  };
  updateWorkInterval: {
    error: Optional<Error>;
  };
  managers: {
    data: Optional<User[]>;
    isLoading: Optional<boolean>;
    totalCount: Optional<number>;
    paginationTokens: PaginationTokens;
    page: number;
  };
}

export const initialState: SiteDetailsPanelState = {
  data: null,
  isLoading: null,
  isMapLoading: null,
  machines: {
    data: null,
    totalCount: null,
    isLoading: null,
    arePicturesLoading: true,
    paginationTokens: {},
    page: DEFAULT_PAGE_VALUE,
    pageSize: DEFAULT_PAGE_SIZE_VALUE,
  },
  workIntervals: {
    data: [],
    isLoading: false,
  },
  createWorkInterval: {
    error: null,
  },
  updateWorkInterval: {
    error: null,
  },
  managers: {
    data: [],
    totalCount: null,
    isLoading: false,
    paginationTokens: {},
    page: DEFAULT_PAGE_VALUE,
  },
};

export const siteDetailsPanelReducer = (state = initialState, action: AnyAction): SiteDetailsPanelState =>
  produce(state, draft => {
    switch (action.type) {
      case SiteDetailsPanelActions.GET_SITE_DETAILS_REQUEST: {
        draft.isLoading = true;
        draft.machines.isLoading = true;
        draft.machines.arePicturesLoading = true;
        return draft;
      }

      case SiteDetailsPanelActions.GET_SITE_DETAILS_SUCCESS: {
        const {
          site: { data },
        } = action.payload;

        const {
          site: {
            data: {
              machines: {
                data: machinesData,
                metadata: { totalCount: machinesTotalCount, paginationToken: machinesPaginationToken },
              },
            },
          },
        } = action.payload;

        draft.data = data;
        draft.isLoading = false;
        draft.machines.data = machinesData;
        draft.machines.isLoading = false;
        draft.machines.totalCount = machinesTotalCount;

        if (machinesPaginationToken) {
          draft.machines.paginationTokens[draft.machines.page + 1] = machinesPaginationToken;
        }

        return draft;
      }

      case SiteDetailsPanelActions.GET_SITE_DETAILS_MACHINE_PICTURES_SUCCESS: {
        const {
          site: {
            data: {
              machines: { data: machineVariantData },
            },
          },
        } = action.payload;

        draft.machines.arePicturesLoading = false;

        draft.machines.data = draft.machines.data?.map(machineWithoutVariantData =>
          MachineUtils.mergeMachineWithVariantData(machineWithoutVariantData, machineVariantData)
        );

        return draft;
      }

      case SiteDetailsPanelActions.GET_SITE_DETAILS_ERROR: {
        draft.isLoading = false;
        draft.machines.isLoading = false;
        return draft;
      }

      case SiteDetailsPanelActions.UPDATE_SITE_SUCCESS: {
        const {
          siteUpdate: { data },
        } = action.payload;

        draft.data = {
          ...data,
          machines: state.data?.machines,
        };

        return draft;
      }

      case SiteDetailsPanelActions.UPDATE_SITE_LOCATION_SUCCESS: {
        const {
          siteUpdate: { data },
        } = action.payload;

        draft.data = {
          ...data,
          machines: state.data?.machines,
        };

        return draft;
      }

      case SiteDetailsPanelActions.UPDATE_SITE_TAGS_SUCCESS: {
        const {
          siteTagsOverride: { data },
        } = action.payload;

        if (draft.data) {
          draft.data.tags = data.tags;
        }

        return draft;
      }

      case SiteDetailsPanelActions.CHANGE_TABLE_PAGE: {
        const { page } = action.payload;

        draft.machines.page = page;
        return draft;
      }

      case SiteDetailsPanelActions.CHANGE_TABLE_PAGE_SIZE: {
        const { pageSize } = action.payload;

        draft.machines.paginationTokens = {};
        draft.machines.page = DEFAULT_PAGE_VALUE;
        draft.machines.pageSize = pageSize;
        return draft;
      }

      case SiteDetailsPanelActions.RESET_STATE: {
        return initialState;
      }

      case SiteDetailsPanelActions.DELETE_SITE_REQUEST: {
        draft.isLoading = true;
        return draft;
      }
      case SiteDetailsPanelActions.DELETE_SITE_SUCCESS: {
        draft.isLoading = false;
        return draft;
      }
      case SiteDetailsPanelActions.DELETE_SITE_ERROR: {
        draft.isLoading = false;
        return draft;
      }

      case SiteDetailsPanelActions.SET_GEOFENCE_REQUEST: {
        draft.isLoading = true;
        return draft;
      }

      case SiteDetailsPanelActions.SET_GEOFENCE_SUCCESS: {
        draft.isLoading = false;
        return draft;
      }

      case SiteDetailsPanelActions.SET_GEOFENCE_ERROR: {
        draft.isLoading = false;
        return draft;
      }

      case SiteDetailsPanelActions.MAP_IS_LOADING: {
        draft.isMapLoading = true;
        return draft;
      }

      case SiteDetailsPanelActions.MAP_IS_NOT_LOADING: {
        draft.isMapLoading = false;
        return draft;
      }

      // Cleaning Actions
      case CleaningActions.GET_WORK_INTERVALS_REQUEST: {
        draft.workIntervals.isLoading = true;
        return draft;
      }

      case CleaningActions.GET_WORK_INTERVALS_SUCCESS: {
        const {
          workIntervals: { data },
        } = action.payload;

        draft.workIntervals.data = data.map(
          (workIntervalUtc: IWorkIntervalUtc): IWorkIntervalLocal =>
            CleaningPlanUtils.convertUtcWorkIntervalToLocal(workIntervalUtc)
        );
        draft.workIntervals.isLoading = false;

        return draft;
      }

      case CleaningActions.GET_WORK_INTERVALS_ERROR: {
        draft.workIntervals.isLoading = false;
        return draft;
      }

      case CleaningActions.WORK_INTERVALS_IS_LOADING: {
        draft.workIntervals.isLoading = true;
        return draft;
      }

      case CleaningActions.CREATE_WORK_INTERVAL_ERROR: {
        const { error } = action;
        draft.createWorkInterval.error = error;
        return draft;
      }

      case CleaningActions.RESET_CREATE_WORK_INTERVAL_ERROR: {
        draft.createWorkInterval.error = null;
        return draft;
      }

      case CleaningActions.UPDATE_WORK_INTERVAL_ERROR: {
        const { error } = action;
        draft.updateWorkInterval.error = error;
        return draft;
      }

      case CleaningActions.RESET_UPDATE_WORK_INTERVAL_ERROR: {
        draft.updateWorkInterval.error = null;
        return draft;
      }

      default:
        return draft;
    }
  });
