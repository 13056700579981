import React from 'react';
import classnames from 'classnames';
import { StyledSiteMapPin } from './SiteMapPin.styles';
import { ReactComponent as MapMarker } from 'app/assets/images/map/map-marker.svg';

export interface SiteMapPinProps {
  className?: string;
  lat?: number | null;
  lng?: number | null;
}

export const SiteMapPin = (props: SiteMapPinProps): JSX.Element => {
  const { className = '' } = props;
  const mapPinClassName = classnames('site-map-pin', className);

  return (
    <StyledSiteMapPin className={mapPinClassName}>
      <div className="site-map-pin__content">
        <MapMarker className="site-map-pin__map-marker" />
      </div>
    </StyledSiteMapPin>
  );
};
