import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { useEffect, useMemo, useState } from 'react';
import { AxisOptions, Chart } from 'react-charts';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import cloneDeep from 'lodash-es/cloneDeep';
import chunk from 'lodash-es/chunk';
import { isNil } from 'lodash-es';
import { useSelector } from 'react-redux';
import { SitePerformanceChartTooltip } from '../SitePerformanceChartTooltip/SitePerformanceChartTooltip';
import { SitePerformanceChartFilter } from '../SitePerformanceChartFilter/SitePerformanceChartFilter';
import { SitePerformanceChartGlobalStyles } from './SitePerformanceChart.global.styles';
import { StyledSitePerformanceChart } from './SitePerformanceChart.styles';
import { InfoTooltip } from 'lib/components/Tooltip/InfoTooltip/InfoTooltip';
import { DateTime } from 'lib/utils/date-handling/DateTime';
import {
  SitePerformanceChartData,
  SitePerformanceChartDatum,
} from 'app/modules/site-management/utils/SitePerformanceChartUtils';
import { SvgIcon } from 'lib/components/SvgIcon/SvgIcon';
import { Tooltip } from 'lib/components/Tooltip/Tooltip';
import { ReactChartUtils } from 'app/utils/react-charts/ReactChartUtils';
import * as authenticationSelectors from 'app/cross-cutting-concerns/authentication/state/authenticationSelectors';

export const NUM_CHART_COLUMNS = 10;

export interface SitePerformanceChartProps {
  data: SitePerformanceChartData[];
  startDate: string;
  endDate: string;
}

export const SitePerformanceChart = ({
  data,
  // Start date and end date only used for display in tooltip.
  startDate,
  endDate,
}: SitePerformanceChartProps): JSX.Element => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const [chartData, setChartData] = useState<SitePerformanceChartData[]>(data);
  const [chartIndex, setChartIndex] = useState<number>(0);

  const actualHrsData: SitePerformanceChartDatum[][] = chunk(data[0].data, NUM_CHART_COLUMNS);
  const plannedHrsData: SitePerformanceChartDatum[][] = chunk(data[1].data, NUM_CHART_COLUMNS);

  const isGcd = useSelector(authenticationSelectors.selectHasAccessToGCD);

  const firstChartColumnData = chartData[0]?.data;
  const secondChartColumnData = chartData[1]?.data;

  const shouldRenderChart = useMemo<boolean | undefined>(() => {
    if (isGcd) {
      return (
        !isNil(chartData) &&
        !isNil(firstChartColumnData) &&
        !isNil(secondChartColumnData) &&
        secondChartColumnData?.length > 0 &&
        firstChartColumnData.length > 0
      );
    }
    return !isNil(chartData) && !isNil(firstChartColumnData) && firstChartColumnData.length > 0;
  }, [isGcd, chartData, firstChartColumnData, secondChartColumnData]);

  useEffect((): void => {
    // Get chart data base on chart index
    const chartDataClone = cloneDeep(data);

    if (!isGcd) chartDataClone.pop();
    else chartDataClone[1].data = plannedHrsData[chartIndex];

    chartDataClone[0].data = actualHrsData[chartIndex];

    setChartData(chartDataClone);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, chartIndex, actualHrsData.length]);

  const primaryAxis = useMemo<AxisOptions<SitePerformanceChartDatum>>(
    () => ({
      getValue: (datum): string => datum.primary,
      showGrid: false,
      formatters: {
        scale: (value: string): string => ReactChartUtils.extractColumnName(value),
      },
    }),
    []
  );

  const secondaryAxes = useMemo<AxisOptions<SitePerformanceChartDatum>[]>(
    () => [
      {
        getValue: (datum): number => datum.secondary,
        min: 0,
        formatters: {
          scale: (value: number): string =>
            value ? DateTime.formatDurationByMilliseconds({ ms: value, showSeconds: false }) : '0',
          cursor: (value: number): string =>
            `${value ? DateTime.formatDurationByMilliseconds({ ms: value, showSeconds: false }) : '0'} ${t(
              'operatingHoursComparisonChart.hUnit'
            )}`,
        },
      },
    ],
    [t]
  );

  const onClickPrevBtn = (): void => setChartIndex(chartIndex - 1);
  const onClickNextBtn = (): void => setChartIndex(chartIndex + 1);

  return (
    <StyledSitePerformanceChart className="site-performance-chart">
      <div className="site-performance-chart__body">
        <SitePerformanceChartGlobalStyles />
        <div className="site-performance-chart__legend">
          <p className="site-performance-chart__title">{t('overview.fleetDashboard.sitePerformanceChart.title')}</p>
          <div className="site-performance-chart-bar">
            <SitePerformanceChartFilter />
            <Tooltip
              title={<InfoTooltip content="overview.fleetDashboard.sitePerformanceChart.description" />}
              overlayClassName="tooltip-overlay"
              placement="bottomRight"
            >
              <div>
                <SvgIcon name="info" className="site-performance-chart-bar__info-icon" />
              </div>
            </Tooltip>
          </div>
        </div>

        {shouldRenderChart && (
          <>
            <p className="site-performance-chart__hours-unit">{t('operatingHoursComparisonChart.hrsUnit')}</p>

            <div className="site-performance-chart__chart">
              <div className="site-performance-chart__chart-arrow">
                {chartIndex !== 0 && (
                  <LeftOutlined className="site-performance-chart__chart-arrow-icon" onClick={onClickPrevBtn} />
                )}
              </div>

              <div className="site-performance-chart__bar-stacked-chart">
                <div className="site-performance-chart__bar-stacked-chart-wrapper">
                  <Chart
                    options={{
                      data: chartData,
                      primaryAxis,
                      secondaryAxes,
                      defaultColors: [theme.colors.celadonGreen, theme.colors.lightCeladonGreen],
                      tooltip: {
                        render: (props): React.ReactNode =>
                          SitePerformanceChartTooltip(startDate, endDate, t, i18n, theme, props),
                      },
                    }}
                  />
                </div>
              </div>

              <div className="site-performance-chart__chart-arrow">
                {chartIndex !== actualHrsData.length - 1 && (
                  <RightOutlined className="site-performance-chart__chart-arrow-icon" onClick={onClickNextBtn} />
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </StyledSitePerformanceChart>
  );
};
