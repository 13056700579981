import { createGlobalStyle } from 'styled-components';

export const AddressesSuggestionAutoCompleteGlobalStyles = createGlobalStyle`
    .auto-complete__dropdown-text {
      ${(props): string => props.theme.fontStyles.p1.medium}
      margin: 5px 0;

      .auto-complete__words-match {
        color: ${(props): string => props.theme.colors.black};
      }
      
      .auto-complete__words-not-match {
        color: ${(props): string => props.theme.colors.darkGrey};
      }
    }
`;
