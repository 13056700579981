/* eslint-disable max-len */
import styled from 'styled-components';
import { Card } from 'lib/components/Card/Card';

export const StyledSiteDetailsInfo = styled(Card)`
  color: ${(props): string => props.theme.colors.black};
  min-height: inherit;
  width: 100%;

  .site-details-info__header-container {
    margin-bottom: ${(props): string => props.theme.spacing.md};

    .site-details-info__header {
      ${(props): string => props.theme.fontStyles.redesign.xl.strong}
      font-weight: 700;
      text-transform: capitalize;
      margin: 0;
    }
  }

  .site-details-info__sub-title {
    ${(props): string => props.theme.fontStyles.caption.bold}
    color: ${(props): string => props.theme.colors.black};
    text-transform: uppercase;
    padding-right: 0.5em;
  }

  .site-details-info__heading {
    ${(props): string => props.theme.fontStyles.redesign.sm.normal}
    margin-bottom: ${(props): string => props.theme.spacing.md};

    .site-details-info__sub-title {
      ${(props): string => props.theme.fontStyles.redesign.sm.delete}
    }
  }

  .site-details-info__heading:last-child {
    margin-bottom: 0;
  }

  .site-details-info__edit-button-tags {
    height: 17px;
    width: 17px;
    padding: 0 0 0 0;
    margin-bottom: 10px;

    svg {
      path {
        fill: ${(props): string => props.theme.colors.darkGrey};
      }
    }
  }
`;
