import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { SiteModalsActions } from '../../state/siteModalsActions';
import * as siteModalsSelectors from '../../state/siteModalsSelectors';
import { SiteDetailsPanelActions } from 'app/modules/site-management/site-details-panel/state/siteDetailsPanelActions';
import { Modal } from 'lib/components/Modal/Modal';
import { Site } from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { TextButton } from 'lib/components/Button/TextButton/TextButton';
import { PrimaryButton } from 'lib/components/Button/PrimaryButton/PrimaryButton';

export interface UnassignMachineToSiteModalProps {
  site: Site;
}

export const UnassignMachineFromSiteModal = ({ site }: UnassignMachineToSiteModalProps): JSX.Element | null => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const visible = useSelector(siteModalsSelectors.selectIsUnassignMachinesModalVisible);
  const isLoading = useSelector(siteModalsSelectors.selectIsUnassignMachinesModalLoading);
  const machineToUnassign = useSelector(siteModalsSelectors.selectUnAssignMachinesModalMachineToUnassign);

  const handleCancel = useCallback(() => dispatch(SiteModalsActions.hideUnassignMachinesModal()), [dispatch]);

  const handleOk = useCallback(() => {
    if (!site || !machineToUnassign) return;

    dispatch(
      SiteDetailsPanelActions.unassignMachineFromSiteRequest({
        siteId: site.id,
        machineId: machineToUnassign.id,
      })
    );
  }, [dispatch, machineToUnassign, site]);

  if (!machineToUnassign) {
    return null;
  }

  return (
    <Modal
      className="unassign-machine-to-site-modal"
      title={t('siteDetails.unassignMachineModal.unassignMachine')}
      open={visible}
      footer={[
        <TextButton size="m" onClick={handleCancel} className="cancel-button" key="cancel-button">
          {t('siteDetails.unassignMachineModal.cancel')}
        </TextButton>,
        <PrimaryButton
          size="m"
          key="submit"
          className="submit-button"
          type="primary"
          loading={isLoading}
          onClick={handleOk}
        >
          {t('siteDetails.unassignMachineModal.unassignMachine')}
        </PrimaryButton>,
      ]}
    >
      <p className="modal__body"> {t('siteDetails.unassignMachineModal.modalBody')}</p>
      <p className="modal__sub-title">
        {[machineToUnassign.name, machineToUnassign.type?.name].filter(Boolean).join(', ')}
      </p>
    </Modal>
  );
};
