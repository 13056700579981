import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'antd';
import { OpenSearch } from '../../../../../../config/constants';
import { useAnalyticsSetForm } from '../../../../../cross-cutting-concerns/analytics/hooks/useAnalyticsSetForm';
import { Site, Status } from '../../../../../cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { SiteDetailsPanelActions } from '../../../site-details-panel/state/siteDetailsPanelActions';
import * as siteModalsSelectors from '../../state/siteModalsSelectors';
import { SiteModalsActions } from '../../state/siteModalsActions';
import { StyledAssignSiteManagerModal } from './AssignSiteManagerModal.styles';
import { SITE_MANAGER_LIST_ASSIGNABLE_ROLE } from 'app/modules/site-management/site-details/components/SiteDetailsSiteManagerList/SiteDetailsSiteManagerList';
import { AnalyticsForm, AnalyticsLink } from 'app/cross-cutting-concerns/analytics/interfaces/Analytics.types';
import { useAnalyticsLinkActivated } from 'app/cross-cutting-concerns/analytics/hooks/useAnalyticsLinkActivated';
import { TextButton } from 'lib/components/Button/TextButton/TextButton';
import { PrimaryButton } from 'lib/components/Button/PrimaryButton/PrimaryButton';
import { Select } from 'lib/components/Select/Select';

export interface AssignSiteManagerModalProps {
  site: Site;
}

export interface AssignSiteManagerFormValues {
  siteManagers: string[];
}

export const AssignSiteManagerModal = ({ site }: AssignSiteManagerModalProps): JSX.Element => {
  const analyticsLinkActivated = useAnalyticsLinkActivated();
  const [formInstance] = Form.useForm();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const visible = useSelector(siteModalsSelectors.selectIsAssignSiteManagerModalVisible);
  const isLoading = useSelector(siteModalsSelectors.selectIsAssignSiteManagerModalLoading);
  const allSiteManagers = useSelector(siteModalsSelectors.selectAssignSiteManagerModalManagers);
  const areSiteManagersLoading = useSelector(siteModalsSelectors.selectAssignSiteManagerModalManagersLoading);
  const alreadyAssignedSiteManagers = site.managers ?? [];
  const alreadyAssignedSiteManagerIds = alreadyAssignedSiteManagers.map(manager => manager.id);
  const availableSiteManagers = allSiteManagers.filter(manager => !alreadyAssignedSiteManagerIds.includes(manager.id));

  const [selectedSiteManagers, setSelectedSiteManagers] = useState<string[]>([]);
  const [isSiteManagersOpen, setIsSiteMangersOpen] = useState(false);

  useEffect(() => {
    if (visible) {
      dispatch(
        SiteModalsActions.getSiteManagerListRequest({
          filter: {
            role: SITE_MANAGER_LIST_ASSIGNABLE_ROLE,
            status: Status.Active,
          },
          paginationOptions: {
            limit: OpenSearch.MAX_RESULT_WINDOW,
          },
        })
      );
    }
  }, [dispatch, visible]);

  useEffect(() => {
    formInstance.resetFields();
  }, [formInstance, visible]);

  const handleCancel = useCallback(() => dispatch(SiteModalsActions.hideAssignSiteManagerModal()), [dispatch]);

  const handleOk = useCallback(() => {
    setSelectedSiteManagers(formInstance.getFieldsValue());
    formInstance.submit();
  }, [formInstance]);

  const handleValuesChange = (): void => {
    setSelectedSiteManagers(formInstance.getFieldsValue());
  };

  const onFinish = useCallback(
    (values: { siteManagers: string[] }) => {
      analyticsLinkActivated({
        linkName: AnalyticsLink.ASSIGN_MANAGER,
      });

      dispatch(
        SiteDetailsPanelActions.assignManagersToSiteRequest({
          siteId: site.id,
          userIds: values.siteManagers,
        })
      );
    },
    [analyticsLinkActivated, dispatch, site.id]
  );

  useAnalyticsSetForm({
    name: AnalyticsForm.ASSIGN_MANAGER,
    fields: { siteManagers: setSelectedSiteManagers },
    isVisible: visible,
  });

  return (
    <StyledAssignSiteManagerModal
      className="assign-site-manager-modal"
      title={t('siteDetails.assignSiteManagerModal.assignSiteManager')}
      open={visible}
      footer={[
        <TextButton size="m" onClick={handleCancel} className="cancel-button" key="cancel-button">
          {t('common.cancel')}
        </TextButton>,
        <PrimaryButton
          size="m"
          key="submit"
          className="submit-button"
          type="primary"
          loading={isLoading}
          onClick={handleOk}
        >
          {t('siteDetails.assignSiteManagerModal.assign')}
        </PrimaryButton>,
      ]}
    >
      <p className="modal__body">{t('siteDetails.assignSiteManagerModal.modalBody')}</p>

      <Form
        form={formInstance}
        name="assign-site-manager"
        layout="vertical"
        onValuesChange={handleValuesChange}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          name="siteManagers"
          label={t('siteDetails.assignSiteManagerModal.form.siteManagers')}
          rules={[
            { required: true, message: t('siteDetails.assignSiteManagerModal.form.errors.siteManagersRequired') },
          ]}
        >
          <Select
            loading={areSiteManagersLoading}
            options={availableSiteManagers.map(user => ({
              label: user.displayName,
              value: user.id,
            }))}
            onDropdownVisibleChange={(isOpen: boolean): void => setIsSiteMangersOpen(isOpen)}
            dropdownVisibleState={isSiteManagersOpen}
            value={selectedSiteManagers}
            placeholder={t('machineModals.editMachineInfo.form.placeholder.default')}
            mode="multiple"
          />
        </Form.Item>
      </Form>
    </StyledAssignSiteManagerModal>
  );
};
