import styled from 'styled-components';

export const StyledSiteDetailsAssignedMachinesList = styled.div`
  .ant-table-row {
    cursor: pointer;

    td {
      .site-details__last-connection {
        ${(props): string => props.theme.mixins.truncateText}
      }
    }
  }

  .site-details__machine-list-table-head {
    margin-bottom: ${(props): string => props.theme.spacing.md};

    .site-details__sub-header {
      ${(props): string => props.theme.fontStyles.redesign.xl.strong}
      text-transform: capitalize;
      margin: 0;
    }
  }

  .site-details__machine-list-table-foot {
    margin-top: ${(props): string => props.theme.spacing.md};
  }

  .site-details__assign-btn-row {
    display: flex;
    justify-content: flex-end;
    margin-top: ${(props): string => props.theme.spacing.md};
  }

  .ant-table-row {
    td {
      .site-details-site-machine-list__unassign-btn {
        padding: 3px;

        svg {
          width: 16px;
          height: 24px;
        }
      }
    }
  }
`;
