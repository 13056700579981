import { ColumnsType } from 'antd/lib/table';
import { TFunction } from 'react-i18next';
import { Role, User } from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { getRoleTranslationString } from 'app/modules/user-management/utils/getRoleTranslationString';
import { TextButton } from 'lib/components/Button/TextButton/TextButton';
import { SvgIcon } from 'lib/components/SvgIcon/SvgIcon';

interface SiteDetailsSiteManagerListColumnsOptions {
  t: TFunction;
  handleUnassign: (user: User) => void;
  hasPermissionSiteStrategicManagerUpdate: boolean;
}

export const getSiteDetailsSiteManagerListColumns = ({
  t,
  handleUnassign,
  hasPermissionSiteStrategicManagerUpdate,
}: SiteDetailsSiteManagerListColumnsOptions): ColumnsType<User> => [
  {
    title: t('siteDetails.siteManagerList.table.columns.userName'),
    dataIndex: 'displayName',
    width: 282,
    key: 'displayName',
    sorter: false,
    defaultSortOrder: 'ascend',
    ellipsis: {
      showTitle: true,
    },
  },
  {
    title: t('siteDetails.siteManagerList.table.columns.role'),
    dataIndex: 'role',
    key: 'role',
    width: 282,
    render: (role: Role) => <>{t(getRoleTranslationString(role))}</>,
    ellipsis: {
      showTitle: true,
    },
  },
  ...(hasPermissionSiteStrategicManagerUpdate
    ? [
        {
          key: 'id',
          render: (_: string, user: User): React.JSX.Element => (
            <TextButton
              className="site-details-site-manager-list__unassign-btn"
              key="delete-button"
              onClick={(e): void => {
                e.stopPropagation();
                handleUnassign(user);
              }}
            >
              <SvgIcon name="delete" />
            </TextButton>
          ),
          width: 48,
        },
      ]
    : []),
];
// {
//   key: 'id',
//   width: '10%',
//   render: (_: string, user: User): JSX.Element => (
//     <TextButton
//       name="delete"
//       id={user.id}
// onClick={(e): void => {
//   e.stopPropagation();
//   handleUnassign(user);
// }}
//     />
//   ),
// },
