import React from 'react';
import { EventWrapperProps as RbcEventWrapperProps } from 'react-big-calendar';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import find from 'lodash-es/find';
import isNil from 'lodash-es/isNil';
import { ICalendarEvent } from '../../../../cleaning/interfaces/CleaningPlan.types';
import { Permission } from '../../../../../../config/permissions';
import * as siteDetailsSelectors from '../../state/siteDetailsPanelSelectors';
import { StyledCleaningPlanEventPopover } from './CleaningPlanEventPopover.styles';
import { TextButton } from 'lib/components/Button/TextButton/TextButton';
import { DateTime } from 'lib/utils/date-handling/DateTime';
import { ReactComponent as Delete } from 'app/assets/images/cleaning-plan/delete.svg';
import { ReactComponent as Edit } from 'app/assets/images/cleaning-plan/edit.svg';
import {
  IShowEditWorkIntervalModalAction,
  IShowDeleteWorkIntervalModalAction,
  SiteModalsActions,
} from 'app/modules/site-management/modals/state/siteModalsActions';
import { PermissionGuard } from 'app/cross-cutting-concerns/authentication/components/PermissionGuard/PermissionGuard';

type CleaningPlanEventPopoverProps = Pick<RbcEventWrapperProps<ICalendarEvent>, 'event'> & {
  timeSpan: string;
  hidePopover(): void;
};

export const CleaningPlanEventPopover: React.FC<CleaningPlanEventPopoverProps> = ({
  event,
  timeSpan,
  hidePopover,
}): JSX.Element | null => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { cleaningPlanEvent } = event.resource;

  const workIntervals = useSelector(siteDetailsSelectors.selectWorkIntervals) ?? [];

  const workInterval = find(workIntervals, { id: cleaningPlanEvent.workIntervalId });

  if (isNil(workInterval)) return null;

  const handleEdit = (): IShowEditWorkIntervalModalAction => {
    hidePopover();
    return dispatch(SiteModalsActions.showEditWorkIntervalModal(workInterval));
  };

  const handleDelete = (): IShowDeleteWorkIntervalModalAction => {
    hidePopover();
    return dispatch(SiteModalsActions.showDeleteWorkIntervalModal(workInterval));
  };

  const indexDayOfWeek = DateTime.getDayOfWeek({
    date: event.start?.toISOString() || '',
    weekStartsOnMonday: true,
  });

  const daysOfWeeks = t('siteDetails.cleaningPlan.daysOfWeeks', { returnObjects: true });

  return (
    <StyledCleaningPlanEventPopover className="cleaning-plan-event-popover">
      <p className="cleaning-plan-event-popover__machine-name">{cleaningPlanEvent.machine.name}</p>
      <p className="cleaning-plan-event-popover__device-variant-name">{cleaningPlanEvent.machine.type?.name}</p>
      <p className="cleaning-plan-event-popover__time-span">
        {daysOfWeeks[indexDayOfWeek]} • {timeSpan}
      </p>
      <PermissionGuard requiredPermissions={[Permission.Site.WorkInterval.UPDATE]}>
        <div className="cleaning-plan-event-popover__buttons">
          <TextButton className="cleaning-plan-event-popover__edit-button" onClick={handleEdit} icon={<Edit />} />
          <TextButton className="cleaning-plan-event-popover__delete-button" onClick={handleDelete} icon={<Delete />} />
        </div>
      </PermissionGuard>
    </StyledCleaningPlanEventPopover>
  );
};
