import { Action } from 'redux';
import {
  IActionCreatorErrorOptions,
  IErrorAction,
} from '../../../../cross-cutting-concerns/state-management/interfaces/StateManagement.types';
import { IWorkIntervalLocal } from '../../../cleaning/interfaces/CleaningPlan.types';
import {
  Machine,
  MachinesAvailableToBeAssigned,
  MachinesAvailableToBeAssignedVariantData,
} from '../../../machine-inventory/interfaces/Machine.types';
import { UserServiceListOptions } from '../../../user-management/UserService';
import { SiteDetailsPanelActions } from '../../site-details-panel/state/siteDetailsPanelActions';
import { SiteAddressesSuggestionResponse, SiteMachinesAvailable, SiteTagsList } from '../../interfaces/Site.types';
import { SiteClientGetAddressesSuggestionOptions, SiteClientListTagsOptions } from '../../SiteClient';
import {
  SiteServiceAvailableSiteMachinesOptions,
  SiteServiceListTagsOptions,
  SiteServiceManagerListOptions,
} from '../../SiteService';
import { UserListResponse } from 'app/modules/user-management/interfaces/User.types';
import {
  InputFilterSiteAddressesSuggestionList,
  User,
} from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';

export type IShowEditSiteNameModalAction = Action<typeof SiteModalsActions.SHOW_EDIT_SITE_NAME_MODAL>;
export type IHideEditSiteNameModalAction = Action<typeof SiteModalsActions.HIDE_EDIT_SITE_NAME_MODAL>;
export type IEditSiteNameModalIsLoadingAction = Action<typeof SiteModalsActions.EDIT_SITE_NAME_MODAL_IS_LOADING>;
export type IEditSiteNameModalIsNotLoadingAction = Action<typeof SiteModalsActions.EDIT_SITE_NAME_MODAL_IS_NOT_LOADING>;

export type IShowEditSiteLocationModalAction = Action<typeof SiteModalsActions.SHOW_EDIT_SITE_LOCATION_MODAL>;
export type IHideEditSiteLocationModalAction = Action<typeof SiteModalsActions.HIDE_EDIT_SITE_LOCATION_MODAL>;
export type IEditSiteLocationModalIsLoadingAction = Action<
  typeof SiteModalsActions.EDIT_SITE_LOCATION_MODAL_IS_LOADING
>;
export type IEditSiteLocationModalIsNotLoadingAction = Action<
  typeof SiteModalsActions.EDIT_SITE_LOCATION_MODAL_IS_NOT_LOADING
>;

export type IShowDeleteSiteModalAction = Action<typeof SiteModalsActions.SHOW_DELETE_SITE_MODAL>;
export type IHideDeleteSiteModalAction = Action<typeof SiteModalsActions.HIDE_DELETE_SITE_MODAL>;
export type IDeleteSiteModalIsLoadingAction = Action<typeof SiteModalsActions.DELETE_SITE_MODAL_IS_LOADING>;
export type IDeleteSiteModalIsNotLoadingAction = Action<typeof SiteModalsActions.DELETE_SITE_MODAL_IS_NOT_LOADING>;

export type IShowCreateSiteModalAction = Action<typeof SiteModalsActions.SHOW_CREATE_SITE_MODAL>;
export type IHideCreateSiteModalAction = Action<typeof SiteModalsActions.HIDE_CREATE_SITE_MODAL>;
export type ICreateSiteModalIsLoadingAction = Action<typeof SiteModalsActions.CREATE_SITE_MODAL_IS_LOADING>;
export type ICreateSiteModalIsNotLoadingAction = Action<typeof SiteModalsActions.CREATE_SITE_MODAL_IS_NOT_LOADING>;
export type ICreateSiteModalResetStateAction = Action<typeof SiteModalsActions.CREATE_SITE_MODAL_RESET_STATE>;

export type IShowAssignMachinesModalAction = Action<typeof SiteModalsActions.SHOW_ASSIGN_MACHINES_MODAL>;
export type IHideAssignMachinesModalAction = Action<typeof SiteModalsActions.HIDE_ASSIGN_MACHINES_MODAL>;
export type IAssignMachinesModalIsLoadingAction = Action<typeof SiteModalsActions.ASSIGN_MACHINES_MODAL_IS_LOADING>;
export type IAssignMachinesModalIsNotLoadingAction = Action<
  typeof SiteModalsActions.ASSIGN_MACHINES_MODAL_IS_NOT_LOADING
>;

export type IShowUnassignMachinesModalAction = Action<typeof SiteModalsActions.SHOW_UNASSIGN_MACHINES_MODAL> & {
  payload: {
    machineToUnassign: Machine;
  };
};
export type IHideUnassignMachinesModalAction = Action<typeof SiteModalsActions.HIDE_UNASSIGN_MACHINES_MODAL>;
export type IUnassignMachinesModalIsLoadingAction = Action<typeof SiteModalsActions.UNASSIGN_MACHINES_MODAL_IS_LOADING>;
export type IUnassignMachinesModalIsNotLoadingAction = Action<
  typeof SiteModalsActions.UNASSIGN_MACHINES_MODAL_IS_NOT_LOADING
>;

export type IAvailableMachinesRequestAction = Action<typeof SiteModalsActions.AVAILABLE_MACHINES_REQUEST>;

export type IAvailableMachinesSuccessOptions = MachinesAvailableToBeAssigned;
export type IAvailableMachinesSuccessAction = Action<typeof SiteModalsActions.AVAILABLE_MACHINES_SUCCESS> & {
  payload: MachinesAvailableToBeAssigned;
};

export type IAvailableMachinesPicturesSuccessOptions = MachinesAvailableToBeAssignedVariantData;
export type IAvailableMachinesPicturesSuccessAction = Action<
  typeof SiteModalsActions.AVAILABLE_MACHINES_PICTURES_SUCCESS
> & {
  payload: MachinesAvailableToBeAssignedVariantData;
};

export type IAvailableMachinesErrorAction = IErrorAction<typeof SiteModalsActions.AVAILABLE_MACHINES_ERROR>;

export type IChangeSiteMachineListTablePage = Action<typeof SiteDetailsPanelActions.CHANGE_TABLE_PAGE> & {
  payload: {
    page: number;
  };
};

export type IChangeSiteMachineListTablePageSize = Action<typeof SiteDetailsPanelActions.CHANGE_TABLE_PAGE_SIZE> & {
  payload: {
    pageSize: number;
  };
};

export type IGetAddressesSuggestionRequestAction = Action<typeof SiteModalsActions.GET_ADDRESSES_SUGGESTION_REQUEST> & {
  payload: SiteClientGetAddressesSuggestionOptions;
};

export type IGetAddressesSuggestionSuccessOptions = SiteAddressesSuggestionResponse & {
  filter: InputFilterSiteAddressesSuggestionList;
};
export type IGetAddressesSuggestionSuccessAction = Action<typeof SiteModalsActions.GET_ADDRESSES_SUGGESTION_SUCCESS> & {
  payload: SiteAddressesSuggestionResponse & {
    filter: InputFilterSiteAddressesSuggestionList;
  };
};

export type IGetAddressesSuggestionErrorOptions = IActionCreatorErrorOptions & {
  filter: InputFilterSiteAddressesSuggestionList;
};
export type IGetAddressesSuggestionErrorAction = IErrorAction<
  typeof SiteModalsActions.GET_ADDRESSES_SUGGESTION_ERROR
> & {
  payload: {
    filter: InputFilterSiteAddressesSuggestionList;
  };
};

export type ISetSearchingAddressValueAction = Action<typeof SiteModalsActions.SET_SEARCHING_ADDRESS_VALUE> & {
  payload: {
    value: string;
  };
};

export type IShowChangeGeofenceModalAction = Action<typeof SiteModalsActions.SHOW_CHANGE_GEOFENCE_MODAL>;
export type IHideChangeGeofenceModalAction = Action<typeof SiteModalsActions.HIDE_CHANGE_GEOFENCE_MODAL>;
export type IChangeGeofenceModalIsLoadingAction = Action<typeof SiteModalsActions.CHANGE_GEOFENCE_MODAL_IS_LOADING>;
export type IChangeGeofenceModalIsNotLoadingAction = Action<
  typeof SiteModalsActions.CHANGE_GEOFENCE_MODAL_IS_NOT_LOADING
>;

export type IShowAddWorkIntervalModalAction = Action<typeof SiteModalsActions.SHOW_ADD_WORK_INTERVAL_MODAL>;
export type IHideAddWorkIntervalModalAction = Action<typeof SiteModalsActions.HIDE_ADD_WORK_INTERVAL_MODAL>;
export type IAddWorkIntervalModalIsLoadingAction = Action<typeof SiteModalsActions.ADD_WORK_INTERVAL_MODAL_IS_LOADING>;
export type IAddWorkIntervalModalIsNotLoadingAction = Action<
  typeof SiteModalsActions.ADD_WORK_INTERVAL_MODAL_IS_NOT_LOADING
>;

export type IShowDeleteWorkIntervalModalAction = Action<typeof SiteModalsActions.SHOW_DELETE_WORK_INTERVAL_MODAL> & {
  payload: {
    workIntervalToDelete: IWorkIntervalLocal;
  };
};
export type IHideDeleteWorkIntervalModalAction = Action<typeof SiteModalsActions.HIDE_DELETE_WORK_INTERVAL_MODAL>;
export type IDeleteWorkIntervalModalIsLoadingAction = Action<
  typeof SiteModalsActions.DELETE_WORK_INTERVAL_MODAL_IS_LOADING
>;
export type IDeleteWorkIntervalModalIsNotLoadingAction = Action<
  typeof SiteModalsActions.DELETE_WORK_INTERVAL_MODAL_IS_NOT_LOADING
>;

export type IShowEditWorkIntervalModalAction = Action<typeof SiteModalsActions.SHOW_EDIT_WORK_INTERVAL_MODAL> & {
  payload: {
    workIntervalToEdit: IWorkIntervalLocal;
  };
};
export type IHideEditWorkIntervalModalAction = Action<typeof SiteModalsActions.HIDE_EDIT_WORK_INTERVAL_MODAL>;
export type IEditWorkIntervalModalIsLoadingAction = Action<
  typeof SiteModalsActions.EDIT_WORK_INTERVAL_MODAL_IS_LOADING
>;
export type IEditWorkIntervalModalIsNotLoadingAction = Action<
  typeof SiteModalsActions.EDIT_WORK_INTERVAL_MODAL_IS_NOT_LOADING
>;

export type IAvailableSiteMachinesRequestOptions = SiteServiceAvailableSiteMachinesOptions;
export type IAvailableSiteMachinesRequestAction = Action<typeof SiteModalsActions.AVAILABLE_SITE_MACHINES_REQUEST> & {
  payload: SiteServiceAvailableSiteMachinesOptions;
};
export type IAvailableSiteMachinesSuccessOptions = SiteMachinesAvailable;
export type IAvailableSiteMachinesSuccessAction = Action<typeof SiteModalsActions.AVAILABLE_SITE_MACHINES_SUCCESS> & {
  payload: SiteMachinesAvailable;
};
export type IAvailableSiteMachinesErrorAction = IErrorAction<typeof SiteModalsActions.AVAILABLE_SITE_MACHINES_ERROR>;

export type IGetSiteManagerListRequestOptions = UserServiceListOptions;
export type IGetSiteManagerListRequestAction = Action<typeof SiteModalsActions.GET_SITE_MANAGER_LIST_REQUEST> & {
  payload: SiteServiceManagerListOptions;
};
export type IGetSiteManagerListSuccessOptions = UserListResponse;
export type IGetSiteManagerListSuccessAction = Action<typeof SiteModalsActions.GET_SITE_MANAGER_LIST_REQUEST> & {
  payload: UserListResponse;
};
export type IGetSiteManagerListErrorAction = IErrorAction<typeof SiteModalsActions.GET_SITE_MANAGER_LIST_ERROR>;

export type IShowAssignSiteManagerModalAction = Action<typeof SiteModalsActions.SHOW_ASSIGN_SITE_MANAGER_MODAL>;
export type IHideAssignSiteManagerModalAction = Action<typeof SiteModalsActions.HIDE_ASSIGN_SITE_MANAGER_MODAL>;
export type IAssignSiteManagerIsLoadingAction = Action<typeof SiteModalsActions.ASSIGN_SITE_MANAGER_IS_LOADING>;
export type IAssignSiteManagerIsNotLoadingAction = Action<typeof SiteModalsActions.ASSIGN_SITE_MANAGER_IS_NOT_LOADING>;

export type IShowUnassignSiteManagerModalAction = Action<typeof SiteModalsActions.SHOW_UNASSIGN_SITE_MANAGER_MODAL> & {
  payload: {
    siteManagerToUnassign: User;
  };
};
export type IHideUnassignSiteManagerModalAction = Action<typeof SiteModalsActions.HIDE_UNASSIGN_SITE_MANAGER_MODAL>;
export type IUnassignSiteManagerIsLoadingAction = Action<typeof SiteModalsActions.UNASSIGN_SITE_MANAGER_IS_LOADING>;
export type IUnassignSiteManagerIsNotLoadingAction = Action<
  typeof SiteModalsActions.UNASSIGN_SITE_MANAGER_IS_NOT_LOADING
>;

export type IShowEditSiteTagsModalAction = Action<typeof SiteModalsActions.SHOW_EDIT_SITE_TAGS_MODAL>;
export type IHideEditSiteTagsModalAction = Action<typeof SiteModalsActions.HIDE_EDIT_SITE_TAGS_MODAL>;
export type IEditSiteTagsModalIsLoadingAction = Action<typeof SiteModalsActions.EDIT_SITE_TAGS_MODAL_IS_LOADING>;
export type IEditSiteTagsModalIsNotLoadingAction = Action<typeof SiteModalsActions.EDIT_SITE_TAGS_MODAL_IS_NOT_LOADING>;
export type IEditSiteTagsModalResetStateAction = Action<typeof SiteModalsActions.EDIT_SITE_TAGS_MODAL_RESET_STATE>;

export type IListTagsRequestOptions = SiteServiceListTagsOptions;
export type IListTagsRequestAction = Action<typeof SiteModalsActions.LIST_TAGS_REQUEST> & {
  payload: SiteClientListTagsOptions;
};

export type IListTagsSuccessOptions = SiteTagsList;
export type IListTagsSuccessAction = Action<typeof SiteModalsActions.LIST_TAGS_SUCCESS> & {
  payload: SiteTagsList;
};

export type IListTagsErrorAction = IErrorAction<typeof SiteModalsActions.LIST_TAGS_ERROR>;

export type IShowEditSiteInfoModalAction = Action<typeof SiteModalsActions.SHOW_EDIT_SITE_INFO_MODAL>;
export type IHideEditSiteInfoModalAction = Action<typeof SiteModalsActions.HIDE_EDIT_SITE_INFO_MODAL>;
export type IEditSiteInfoModalIsLoadingAction = Action<typeof SiteModalsActions.EDIT_SITE_INFO_MODAL_IS_LOADING>;
export type IEditSiteInfoModalIsNotLoadingAction = Action<typeof SiteModalsActions.EDIT_SITE_INFO_MODAL_IS_NOT_LOADING>;

export class SiteModalsActions {
  public static SHOW_EDIT_SITE_NAME_MODAL = 'SITE_MODALS::SHOW_EDIT_SITE_NAME_MODAL';
  public static HIDE_EDIT_SITE_NAME_MODAL = 'SITE_MODALS::HIDE_EDIT_SITE_NAME_MODAL';
  public static EDIT_SITE_NAME_MODAL_IS_LOADING = 'SITE_MODALS::EDIT_SITE_NAME_MODAL_IS_LOADING';
  public static EDIT_SITE_NAME_MODAL_IS_NOT_LOADING = 'SITE_MODALS::EDIT_SITE_NAME_MODAL_IS_NOT_LOADING';

  public static SHOW_EDIT_SITE_LOCATION_MODAL = 'SITE_MODALS::SHOW_EDIT_SITE_LOCATION_MODAL';
  public static HIDE_EDIT_SITE_LOCATION_MODAL = 'SITE_MODALS::HIDE_EDIT_SITE_LOCATION_MODAL';
  public static EDIT_SITE_LOCATION_MODAL_IS_LOADING = 'SITE_MODALS::EDIT_SITE_LOCATION_MODAL_IS_LOADING';
  public static EDIT_SITE_LOCATION_MODAL_IS_NOT_LOADING = 'SITE_MODALS::EDIT_SITE_LOCATION_MODAL_IS_NOT_LOADING';

  public static SHOW_DELETE_SITE_MODAL = 'SITE_MODALS::SHOW_DELETE_SITE_MODAL';
  public static HIDE_DELETE_SITE_MODAL = 'SITE_MODALS::HIDE_DELETE_SITE_MODAL';
  public static DELETE_SITE_MODAL_IS_LOADING = 'SITE_MODALS::DELETE_SITE_MODAL_IS_LOADING';
  public static DELETE_SITE_MODAL_IS_NOT_LOADING = 'SITE_MODALS::DELETE_SITE_MODAL_IS_NOT_LOADING';

  public static SHOW_CREATE_SITE_MODAL = 'SITE_MODALS::SHOW_CREATE_SITE_MODAL';
  public static HIDE_CREATE_SITE_MODAL = 'SITE_MODALS::HIDE_CREATE_SITE_MODAL';
  public static CREATE_SITE_MODAL_IS_LOADING = 'SITE_MODALS::CREATE_SITE_MODAL_IS_LOADING';
  public static CREATE_SITE_MODAL_IS_NOT_LOADING = 'SITE_MODALS::CREATE_SITE_MODAL_IS_NOT_LOADING';
  public static CREATE_SITE_MODAL_RESET_STATE = 'SITE_MODALS::CREATE_SITE_MODAL_RESET_STATE';

  public static SHOW_ASSIGN_MACHINES_MODAL = 'SITE_MODALS::SHOW_ASSIGN_MACHINES_MODAL';
  public static HIDE_ASSIGN_MACHINES_MODAL = 'SITE_MODALS::HIDE_ASSIGN_MACHINES_MODAL';
  public static ASSIGN_MACHINES_MODAL_IS_LOADING = 'SITE_MODALS::ASSIGN_MACHINES_MODAL_IS_LOADING';
  public static ASSIGN_MACHINES_MODAL_IS_NOT_LOADING = 'SITE_MODALS::ASSIGN_MACHINES_MODAL_IS_NOT_LOADING';

  public static SHOW_UNASSIGN_MACHINES_MODAL = 'SITE_MODALS::SHOW_UNASSIGN_MACHINES_MODAL';
  public static HIDE_UNASSIGN_MACHINES_MODAL = 'SITE_MODALS::HIDE_UNASSIGN_MACHINES_MODAL';
  public static UNASSIGN_MACHINES_MODAL_IS_LOADING = 'SITE_MODALS::UNASSIGN_MACHINES_MODAL_IS_LOADING';
  public static UNASSIGN_MACHINES_MODAL_IS_NOT_LOADING = 'SITE_MODALS::UNASSIGN_MACHINES_MODAL_IS_NOT_LOADING';

  public static AVAILABLE_MACHINES_REQUEST = 'SITE_MODALS::AVAILABLE_MACHINES_REQUEST';
  public static AVAILABLE_MACHINES_SUCCESS = 'SITE_MODALS::AVAILABLE_MACHINES_SUCCESS';
  public static AVAILABLE_MACHINES_PICTURES_SUCCESS = 'SITE_MODALS::AVAILABLE_MACHINES_PICTURES_SUCCESS';
  public static AVAILABLE_MACHINES_ERROR = 'SITE_MODALS::AVAILABLE_MACHINES_ERROR';

  public static SET_SEARCHING_ADDRESS_VALUE = 'SITE_MODALS::SET_SEARCHING_ADDRESS_VALUE';
  public static GET_ADDRESSES_SUGGESTION_REQUEST = 'SITE_MODALS::GET_ADDRESSES_SUGGESTION_REQUEST';
  public static GET_ADDRESSES_SUGGESTION_SUCCESS = 'SITE_MODALS::GET_ADDRESSES_SUGGESTION_SUCCESS';
  public static GET_ADDRESSES_SUGGESTION_ERROR = 'SITE_MODALS::GET_ADDRESSES_SUGGESTION_ERROR';

  public static SHOW_CHANGE_GEOFENCE_MODAL = 'SITE_MODALS::SHOW_CHANGE_GEOFENCE_MODAL';
  public static HIDE_CHANGE_GEOFENCE_MODAL = 'SITE_MODALS::HIDE_CHANGE_GEOFENCE_MODAL';
  public static CHANGE_GEOFENCE_MODAL_IS_LOADING = 'SITE_MODALS::CHANGE_GEOFENCE_MODAL_IS_LOADING';
  public static CHANGE_GEOFENCE_MODAL_IS_NOT_LOADING = 'SITE_MODALS::CHANGE_GEOFENCE_MODAL_IS_NOT_LOADING';

  public static SHOW_ADD_WORK_INTERVAL_MODAL = 'SITE_MODALS::SHOW_ADD_WORK_INTERVAL_MODAL';
  public static HIDE_ADD_WORK_INTERVAL_MODAL = 'SITE_MODALS::HIDE_ADD_WORK_INTERVAL_MODAL';
  public static ADD_WORK_INTERVAL_MODAL_IS_LOADING = 'SITE_MODALS::ADD_WORK_INTERVAL_MODAL_IS_LOADING';
  public static ADD_WORK_INTERVAL_MODAL_IS_NOT_LOADING = 'SITE_MODALS::ADD_WORK_INTERVAL_MODAL_IS_NOT_LOADING';

  public static SHOW_DELETE_WORK_INTERVAL_MODAL = 'SITE_MODALS::SHOW_DELETE_WORK_INTERVAL_MODAL';
  public static HIDE_DELETE_WORK_INTERVAL_MODAL = 'SITE_MODALS::HIDE_DELETE_WORK_INTERVAL_MODAL';
  public static DELETE_WORK_INTERVAL_MODAL_IS_LOADING = 'SITE_MODALS::DELETE_WORK_INTERVAL_MODAL_IS_LOADING';
  public static DELETE_WORK_INTERVAL_MODAL_IS_NOT_LOADING = 'SITE_MODALS::DELETE_WORK_INTERVAL_MODAL_IS_NOT_LOADING';

  public static SHOW_EDIT_WORK_INTERVAL_MODAL = 'SITE_MODALS::SHOW_EDIT_WORK_INTERVAL_MODAL';
  public static HIDE_EDIT_WORK_INTERVAL_MODAL = 'SITE_MODALS::HIDE_EDIT_WORK_INTERVAL_MODAL';
  public static EDIT_WORK_INTERVAL_MODAL_IS_LOADING = 'SITE_MODALS::EDIT_WORK_INTERVAL_MODAL_IS_LOADING';
  public static EDIT_WORK_INTERVAL_MODAL_IS_NOT_LOADING = 'SITE_MODALS::EDIT_WORK_INTERVAL_MODAL_IS_NOT_LOADING';

  public static AVAILABLE_SITE_MACHINES_REQUEST = 'SITE_MODALS::AVAILABLE_SITE_MACHINES_REQUEST';
  public static AVAILABLE_SITE_MACHINES_SUCCESS = 'SITE_MODALS::AVAILABLE_SITE_MACHINES_SUCCESS';
  public static AVAILABLE_SITE_MACHINES_ERROR = 'SITE_MODALS::AVAILABLE_SITE_MACHINES_ERROR';

  public static GET_SITE_MANAGER_LIST_REQUEST = 'SITE_MODALS::GET_SITE_MANAGER_LIST_REQUEST';
  public static GET_SITE_MANAGER_LIST_SUCCESS = 'SITE_MODALS::GET_SITE_MANAGER_LIST_SUCCESS';
  public static GET_SITE_MANAGER_LIST_ERROR = 'SITE_MODALS::GET_SITE_MANAGER_LIST_ERROR';

  public static SHOW_ASSIGN_SITE_MANAGER_MODAL = 'SITE_MODALS::SHOW_ASSIGN_SITE_MANAGER_MODAL';
  public static HIDE_ASSIGN_SITE_MANAGER_MODAL = 'SITE_MODALS::HIDE_ASSIGN_SITE_MANAGER_MODAL';
  public static ASSIGN_SITE_MANAGER_IS_LOADING = 'SITE_MODALS::ASSIGN_SITE_MANAGER__IS_LOADING';
  public static ASSIGN_SITE_MANAGER_IS_NOT_LOADING = 'SITE_MODALS::ASSIGN_SITE_MANAGER__IS_NOT_LOADING';

  public static SHOW_UNASSIGN_SITE_MANAGER_MODAL = 'SITE_MODALS::SHOW_UNASSIGN_SITE_MANAGER_MODAL';
  public static HIDE_UNASSIGN_SITE_MANAGER_MODAL = 'SITE_MODALS::HIDE_UNASSIGN_SITE_MANAGER_MODAL';
  public static UNASSIGN_SITE_MANAGER_IS_LOADING = 'SITE_MODALS::UNASSIGN_SITE_MANAGER__IS_LOADING';
  public static UNASSIGN_SITE_MANAGER_IS_NOT_LOADING = 'SITE_MODALS::UNASSIGN_SITE_MANAGER__IS_NOT_LOADING';

  public static SHOW_EDIT_SITE_TAGS_MODAL = 'SITE_MODALS::SHOW_EDIT_SITE_TAGS_MODAL';
  public static HIDE_EDIT_SITE_TAGS_MODAL = 'SITE_MODALS::HIDE_EDIT_SITE_TAGS_MODAL';
  public static EDIT_SITE_TAGS_MODAL_IS_LOADING = 'SITE_MODALS::EDIT_SITE_TAGS_MODAL_IS_LOADING';
  public static EDIT_SITE_TAGS_MODAL_IS_NOT_LOADING = 'SITE_MODALS::EDIT_SITE_TAGS_MODAL_IS_NOT_LOADING';
  public static EDIT_SITE_TAGS_MODAL_RESET_STATE = 'SITE_MODALS::EDIT_SITE_TAGS_MODAL_RESET_STATE';

  public static LIST_TAGS_REQUEST = 'SITE_MODALS::LIST_TAGS_REQUEST';
  public static LIST_TAGS_SUCCESS = 'SITE_MODALS::LIST_TAGS_SUCCESS';
  public static LIST_TAGS_ERROR = 'SITE_MODALS::LIST_TAGS_ERROR';

  public static SHOW_EDIT_SITE_INFO_MODAL = 'SITE_MODALS::SHOW_EDIT_SITE_INFO_MODAL';
  public static HIDE_EDIT_SITE_INFO_MODAL = 'SITE_MODALS::HIDE_EDIT_SITE_INFO_MODAL';
  public static EDIT_SITE_INFO_MODAL_IS_LOADING = 'SITE_MODALS::EDIT_SITE_INFO_MODAL_IS_LOADING';
  public static EDIT_SITE_INFO_MODAL_IS_NOT_LOADING = 'SITE_MODALS::EDIT_SITE_INFO_MODAL_IS_NOT_LOADING';

  public static showEditSiteNameModal(): IShowEditSiteNameModalAction {
    return {
      type: SiteModalsActions.SHOW_EDIT_SITE_NAME_MODAL,
    };
  }

  public static hideEditSiteNameModal(): IHideEditSiteNameModalAction {
    return {
      type: SiteModalsActions.HIDE_EDIT_SITE_NAME_MODAL,
    };
  }

  public static editSiteNameModalIsLoading(): IEditSiteNameModalIsLoadingAction {
    return {
      type: SiteModalsActions.EDIT_SITE_NAME_MODAL_IS_LOADING,
    };
  }

  public static editSiteNameModalIsNotLoading(): IEditSiteNameModalIsNotLoadingAction {
    return {
      type: SiteModalsActions.EDIT_SITE_NAME_MODAL_IS_NOT_LOADING,
    };
  }

  public static showEditSiteLocationModal(): IShowEditSiteLocationModalAction {
    return {
      type: SiteModalsActions.SHOW_EDIT_SITE_LOCATION_MODAL,
    };
  }

  public static hideEditSiteLocationModal(): IHideEditSiteLocationModalAction {
    return {
      type: SiteModalsActions.HIDE_EDIT_SITE_LOCATION_MODAL,
    };
  }

  public static editSiteLocationModalIsLoading(): IEditSiteLocationModalIsLoadingAction {
    return {
      type: SiteModalsActions.EDIT_SITE_LOCATION_MODAL_IS_LOADING,
    };
  }

  public static editSiteLocationModalIsNotLoading(): IEditSiteLocationModalIsNotLoadingAction {
    return {
      type: SiteModalsActions.EDIT_SITE_LOCATION_MODAL_IS_NOT_LOADING,
    };
  }

  public static showDeleteSiteModal(): IShowDeleteSiteModalAction {
    return {
      type: SiteModalsActions.SHOW_DELETE_SITE_MODAL,
    };
  }

  public static hideDeleteSiteModal(): IHideDeleteSiteModalAction {
    return {
      type: SiteModalsActions.HIDE_DELETE_SITE_MODAL,
    };
  }

  public static deleteSiteModalIsLoading(): IDeleteSiteModalIsLoadingAction {
    return {
      type: SiteModalsActions.DELETE_SITE_MODAL_IS_LOADING,
    };
  }

  public static deleteSiteModalIsNotLoading(): IDeleteSiteModalIsNotLoadingAction {
    return {
      type: SiteModalsActions.DELETE_SITE_MODAL_IS_NOT_LOADING,
    };
  }

  public static showCreateSiteModal(): IShowCreateSiteModalAction {
    return {
      type: SiteModalsActions.SHOW_CREATE_SITE_MODAL,
    };
  }

  public static createSiteModalResetState(): ICreateSiteModalResetStateAction {
    return {
      type: SiteModalsActions.CREATE_SITE_MODAL_RESET_STATE,
    };
  }

  public static hideCreateSiteModal(): IHideCreateSiteModalAction {
    return {
      type: SiteModalsActions.HIDE_CREATE_SITE_MODAL,
    };
  }

  public static createSiteModalIsLoading(): ICreateSiteModalIsLoadingAction {
    return {
      type: SiteModalsActions.CREATE_SITE_MODAL_IS_LOADING,
    };
  }

  public static createSiteModalIsNotLoading(): ICreateSiteModalIsNotLoadingAction {
    return {
      type: SiteModalsActions.CREATE_SITE_MODAL_IS_NOT_LOADING,
    };
  }

  public static showAssignMachinesModal(): IShowAssignMachinesModalAction {
    return {
      type: SiteModalsActions.SHOW_ASSIGN_MACHINES_MODAL,
    };
  }

  public static hideAssignMachinesModal(): IHideAssignMachinesModalAction {
    return {
      type: SiteModalsActions.HIDE_ASSIGN_MACHINES_MODAL,
    };
  }

  public static assignMachinesModalIsLoading(): IAssignMachinesModalIsLoadingAction {
    return {
      type: SiteModalsActions.ASSIGN_MACHINES_MODAL_IS_LOADING,
    };
  }

  public static assignMachinesModalIsNotLoading(): IAssignMachinesModalIsNotLoadingAction {
    return {
      type: SiteModalsActions.ASSIGN_MACHINES_MODAL_IS_NOT_LOADING,
    };
  }

  public static showUnassignMachinesModal(machineToUnassign: Machine): IShowUnassignMachinesModalAction {
    return {
      type: SiteModalsActions.SHOW_UNASSIGN_MACHINES_MODAL,
      payload: {
        machineToUnassign,
      },
    };
  }

  public static hideUnassignMachinesModal(): IHideUnassignMachinesModalAction {
    return {
      type: SiteModalsActions.HIDE_UNASSIGN_MACHINES_MODAL,
    };
  }

  public static unassignMachinesModalIsLoading(): IUnassignMachinesModalIsLoadingAction {
    return {
      type: SiteModalsActions.UNASSIGN_MACHINES_MODAL_IS_LOADING,
    };
  }

  public static unassignMachinesModalIsNotLoading(): IUnassignMachinesModalIsNotLoadingAction {
    return {
      type: SiteModalsActions.UNASSIGN_MACHINES_MODAL_IS_NOT_LOADING,
    };
  }

  public static availableMachinesRequest(): IAvailableMachinesRequestAction {
    return {
      type: SiteModalsActions.AVAILABLE_MACHINES_REQUEST,
    };
  }

  public static availableMachinesSuccess(data: IAvailableMachinesSuccessOptions): IAvailableMachinesSuccessAction {
    return {
      type: SiteModalsActions.AVAILABLE_MACHINES_SUCCESS,
      payload: data,
    };
  }

  public static availableMachinesPicturesSuccess(
    data: IAvailableMachinesPicturesSuccessOptions
  ): IAvailableMachinesPicturesSuccessAction {
    return {
      type: SiteModalsActions.AVAILABLE_MACHINES_PICTURES_SUCCESS,
      payload: data,
    };
  }

  public static availableMachinesError({ error }: IActionCreatorErrorOptions): IAvailableMachinesErrorAction {
    return {
      type: SiteModalsActions.AVAILABLE_MACHINES_ERROR,
      error,
    };
  }

  public static getAddressesSuggestionRequest({
    filter,
    paginationOptions,
  }: SiteClientGetAddressesSuggestionOptions): IGetAddressesSuggestionRequestAction {
    return {
      type: SiteModalsActions.GET_ADDRESSES_SUGGESTION_REQUEST,
      payload: {
        filter,
        paginationOptions,
      },
    };
  }

  public static getAddressesSuggestionSuccess(
    data: IGetAddressesSuggestionSuccessOptions
  ): IGetAddressesSuggestionSuccessAction {
    return {
      type: SiteModalsActions.GET_ADDRESSES_SUGGESTION_SUCCESS,
      payload: data,
    };
  }

  public static getAddressesSuggestionError({
    error,
    filter,
  }: IGetAddressesSuggestionErrorOptions): IGetAddressesSuggestionErrorAction {
    return {
      type: SiteModalsActions.GET_ADDRESSES_SUGGESTION_ERROR,
      error,
      payload: {
        filter,
      },
    };
  }

  public static setSearchingAddressValue(value = ''): ISetSearchingAddressValueAction {
    return {
      type: SiteModalsActions.SET_SEARCHING_ADDRESS_VALUE,
      payload: {
        value,
      },
    };
  }

  public static showChangeGeofenceModal(): IShowChangeGeofenceModalAction {
    return {
      type: SiteModalsActions.SHOW_CHANGE_GEOFENCE_MODAL,
    };
  }

  public static hideChangeGeofenceModal(): IHideChangeGeofenceModalAction {
    return {
      type: SiteModalsActions.HIDE_CHANGE_GEOFENCE_MODAL,
    };
  }

  public static changeGeofenceModalIsLoading(): IChangeGeofenceModalIsLoadingAction {
    return {
      type: SiteModalsActions.CHANGE_GEOFENCE_MODAL_IS_LOADING,
    };
  }

  public static changeGeofenceModalIsNotLoading(): IChangeGeofenceModalIsNotLoadingAction {
    return {
      type: SiteModalsActions.CHANGE_GEOFENCE_MODAL_IS_NOT_LOADING,
    };
  }

  public static showAddWorkIntervalModal(): IShowAddWorkIntervalModalAction {
    return {
      type: SiteModalsActions.SHOW_ADD_WORK_INTERVAL_MODAL,
    };
  }

  public static hideAddWorkIntervalModal(): IHideAddWorkIntervalModalAction {
    return {
      type: SiteModalsActions.HIDE_ADD_WORK_INTERVAL_MODAL,
    };
  }

  public static addWorkIntervalModalIsLoading(): IAddWorkIntervalModalIsLoadingAction {
    return {
      type: SiteModalsActions.ADD_WORK_INTERVAL_MODAL_IS_LOADING,
    };
  }

  public static addWorkIntervalModalIsNotLoading(): IAddWorkIntervalModalIsNotLoadingAction {
    return {
      type: SiteModalsActions.ADD_WORK_INTERVAL_MODAL_IS_NOT_LOADING,
    };
  }

  public static showDeleteWorkIntervalModal(
    workIntervalToDelete: IWorkIntervalLocal
  ): IShowDeleteWorkIntervalModalAction {
    return {
      type: SiteModalsActions.SHOW_DELETE_WORK_INTERVAL_MODAL,
      payload: {
        workIntervalToDelete,
      },
    };
  }

  public static hideDeleteWorkIntervalModal(): IHideDeleteWorkIntervalModalAction {
    return {
      type: SiteModalsActions.HIDE_DELETE_WORK_INTERVAL_MODAL,
    };
  }

  public static deleteWorkIntervalModalIsLoading(): IDeleteWorkIntervalModalIsLoadingAction {
    return {
      type: SiteModalsActions.DELETE_WORK_INTERVAL_MODAL_IS_LOADING,
    };
  }

  public static deleteWorkIntervalModalIsNotLoading(): IDeleteWorkIntervalModalIsNotLoadingAction {
    return {
      type: SiteModalsActions.DELETE_WORK_INTERVAL_MODAL_IS_NOT_LOADING,
    };
  }

  public static showEditWorkIntervalModal(workIntervalToEdit: IWorkIntervalLocal): IShowEditWorkIntervalModalAction {
    return {
      type: SiteModalsActions.SHOW_EDIT_WORK_INTERVAL_MODAL,
      payload: {
        workIntervalToEdit,
      },
    };
  }

  public static hideEditWorkIntervalModal(): IHideEditWorkIntervalModalAction {
    return {
      type: SiteModalsActions.HIDE_EDIT_WORK_INTERVAL_MODAL,
    };
  }

  public static editWorkIntervalModalIsLoading(): IEditWorkIntervalModalIsLoadingAction {
    return {
      type: SiteModalsActions.EDIT_WORK_INTERVAL_MODAL_IS_LOADING,
    };
  }

  public static editWorkIntervalModalIsNotLoading(): IEditWorkIntervalModalIsNotLoadingAction {
    return {
      type: SiteModalsActions.EDIT_WORK_INTERVAL_MODAL_IS_NOT_LOADING,
    };
  }

  public static availableSiteMachinesRequest({
    id,
    filter,
    machinePaginationOptions,
  }: IAvailableSiteMachinesRequestOptions): IAvailableSiteMachinesRequestAction {
    return {
      type: SiteModalsActions.AVAILABLE_SITE_MACHINES_REQUEST,
      payload: {
        id,
        filter,
        machinePaginationOptions,
      },
    };
  }

  public static availableSiteMachinesSuccess(
    data: IAvailableSiteMachinesSuccessOptions
  ): IAvailableSiteMachinesSuccessAction {
    return {
      type: SiteModalsActions.AVAILABLE_SITE_MACHINES_SUCCESS,
      payload: data,
    };
  }

  public static availableSiteMachinesError({ error }: IActionCreatorErrorOptions): IAvailableSiteMachinesErrorAction {
    return {
      type: SiteModalsActions.AVAILABLE_SITE_MACHINES_ERROR,
      error,
    };
  }

  public static getSiteManagerListRequest({
    filter,
    paginationOptions,
  }: IGetSiteManagerListRequestOptions): IGetSiteManagerListRequestAction {
    return {
      type: SiteModalsActions.GET_SITE_MANAGER_LIST_REQUEST,
      payload: {
        filter,
        paginationOptions,
      },
    };
  }

  public static getSiteManagerListSuccess(data: IGetSiteManagerListSuccessOptions): IGetSiteManagerListSuccessAction {
    return {
      type: SiteModalsActions.GET_SITE_MANAGER_LIST_SUCCESS,
      payload: data,
    };
  }

  public static getSiteManagerListError({ error }: IActionCreatorErrorOptions): IGetSiteManagerListErrorAction {
    return {
      type: SiteModalsActions.GET_SITE_MANAGER_LIST_ERROR,
      error,
    };
  }

  public static showAssignSiteManagerModal(): IShowAssignSiteManagerModalAction {
    return {
      type: SiteModalsActions.SHOW_ASSIGN_SITE_MANAGER_MODAL,
    };
  }

  public static hideAssignSiteManagerModal(): IHideAssignSiteManagerModalAction {
    return {
      type: SiteModalsActions.HIDE_ASSIGN_SITE_MANAGER_MODAL,
    };
  }

  public static assignSiteManagerModalIsLoading(): IAssignSiteManagerIsLoadingAction {
    return {
      type: SiteModalsActions.ASSIGN_SITE_MANAGER_IS_LOADING,
    };
  }

  public static assignSiteManagerModalIsNotLoading(): IAssignSiteManagerIsNotLoadingAction {
    return {
      type: SiteModalsActions.ASSIGN_SITE_MANAGER_IS_NOT_LOADING,
    };
  }

  public static showUnassignSiteManagerModal({
    siteManagerToUnassign,
  }: {
    siteManagerToUnassign: User;
  }): IShowUnassignSiteManagerModalAction {
    return {
      type: SiteModalsActions.SHOW_UNASSIGN_SITE_MANAGER_MODAL,
      payload: {
        siteManagerToUnassign,
      },
    };
  }

  public static hideUnassignSiteManagerModal(): IHideUnassignSiteManagerModalAction {
    return {
      type: SiteModalsActions.HIDE_UNASSIGN_SITE_MANAGER_MODAL,
    };
  }

  public static unassignSiteManagerModalIsLoading(): IUnassignSiteManagerIsLoadingAction {
    return {
      type: SiteModalsActions.UNASSIGN_SITE_MANAGER_IS_LOADING,
    };
  }

  public static unassignSiteManagerModalIsNotLoading(): IUnassignSiteManagerIsNotLoadingAction {
    return {
      type: SiteModalsActions.UNASSIGN_SITE_MANAGER_IS_NOT_LOADING,
    };
  }

  public static showEditSiteTagsModal(): IShowEditSiteTagsModalAction {
    return {
      type: SiteModalsActions.SHOW_EDIT_SITE_TAGS_MODAL,
    };
  }

  public static hideEditSiteTagsModal(): IHideEditSiteTagsModalAction {
    return {
      type: SiteModalsActions.HIDE_EDIT_SITE_TAGS_MODAL,
    };
  }

  public static editSiteTagsModalIsLoading(): IEditSiteTagsModalIsLoadingAction {
    return {
      type: SiteModalsActions.EDIT_SITE_TAGS_MODAL_IS_LOADING,
    };
  }

  public static editSiteTagsModalIsNotLoading(): IEditSiteTagsModalIsNotLoadingAction {
    return {
      type: SiteModalsActions.EDIT_SITE_TAGS_MODAL_IS_NOT_LOADING,
    };
  }

  public static editSiteTagsModalResetState(): IEditSiteTagsModalResetStateAction {
    return {
      type: SiteModalsActions.EDIT_SITE_TAGS_MODAL_RESET_STATE,
    };
  }

  public static listTagsRequest({ filter, paginationOptions }: IListTagsRequestOptions): IListTagsRequestAction {
    return {
      type: SiteModalsActions.LIST_TAGS_REQUEST,
      payload: {
        filter,
        paginationOptions,
      },
    };
  }

  public static listTagsSuccess(data: IListTagsSuccessOptions): IListTagsSuccessAction {
    return {
      type: SiteModalsActions.LIST_TAGS_SUCCESS,
      payload: data,
    };
  }

  public static listTagsError({ error }: IActionCreatorErrorOptions): IListTagsErrorAction {
    return {
      type: SiteModalsActions.LIST_TAGS_ERROR,
      error,
    };
  }

  public static showEditSiteInfoModal(): IShowEditSiteInfoModalAction {
    return {
      type: SiteModalsActions.SHOW_EDIT_SITE_INFO_MODAL,
    };
  }

  public static hideEditSiteInfoModal(): IHideEditSiteInfoModalAction {
    return {
      type: SiteModalsActions.HIDE_EDIT_SITE_INFO_MODAL,
    };
  }

  public static editSiteInfoModalIsLoading(): IEditSiteInfoModalIsLoadingAction {
    return {
      type: SiteModalsActions.EDIT_SITE_INFO_MODAL_IS_LOADING,
    };
  }

  public static editSiteInfoModalIsNotLoading(): IEditSiteInfoModalIsNotLoadingAction {
    return {
      type: SiteModalsActions.EDIT_SITE_INFO_MODAL_IS_NOT_LOADING,
    };
  }
}
