import styled from 'styled-components';
import { Modal } from '../../../../../../lib/components/Modal/Modal';

export const StyledAssignMachineToSiteModal = styled(Modal)`
  .assign-machine-to-site-modal__dropdown {
    width: 100%;
  }

  .machine-option {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .machine-option__image {
    margin-right: 16px;
  }

  .machine-option__image--loading {
    width: 20px;
    height: 20px;
  }

  .machine-option__checkbox {
    width: 16px;
    height: 16px;
    border: 2px solid ${(props): string => props.theme.colors.black};
    margin-left: auto;
  }

  .ant-select-item-option-selected {
    .machine-option__checkbox {
      display: none;
    }
  }

  .ant-select-dropdown {
    border-top: none;
  }

  .assign-machine-to-site-modal__search-input-wrapper {
    border-left: 2px solid ${(props): string => props.theme.colors.darkGrey};
    border-right: 2px solid ${(props): string => props.theme.colors.darkGrey};
    padding: 8px 16px;
  }

  .ant-select-dropdown-placement-topLeft .assign-machine-to-site-modal__search-input-wrapper {
    border-top: 2px solid ${(props): string => props.theme.colors.darkGrey};
    padding: 8px 16px;
  }

  .ant-empty-small {
    margin: 0;
    padding: 8px;
    border-left: 2px solid ${(props): string => props.theme.colors.darkGrey};
    border-right: 2px solid ${(props): string => props.theme.colors.darkGrey};
  }

  .assign-machine-to-site-modal__search-input {
    padding: 10px;

    input {
      height: 30px;
      bottom: 10px;
    }

    &.ant-input-affix-wrapper-status-error {
      background: unset !important;
      border-color: ${(props): string => props.theme.colors.darkGrey} !important;

      .ant-input-prefix {
        color: unset;
      }
    }
  }
`;
