import { CallEffect, ForkEffect, GetContextEffect, PutEffect } from 'redux-saga/effects';
import { getContext, call, put, takeLatest } from 'typed-redux-saga';
import { IDependencies } from '../../../../../cross-cutting-concerns/dependency-injection/interfaces/IDependencies';
import {
  IGetSiteListWithOperatingTimeRequestAction,
  IGetSiteListWithOperatingTimeSuccessAction,
  IGetSiteListWithOperatingTimeErrorAction,
  SiteWidgetsFleetDashboardActions,
} from './siteWidgetsFleetDashboardActions';
import { SiteListWithOperatingTime } from 'app/modules/site-management/interfaces/Site.types';

export function* getSiteListWithOperatingTimeSaga(
  action: IGetSiteListWithOperatingTimeRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<SiteListWithOperatingTime>
  | PutEffect<IGetSiteListWithOperatingTimeSuccessAction>
  | PutEffect<IGetSiteListWithOperatingTimeErrorAction>,
  void,
  IDependencies
> {
  const { siteService } = yield* getContext<IDependencies>('dependencies');

  try {
    const response = yield* call(siteService.listSitesWithOperatingTime, action.payload);

    yield* put(SiteWidgetsFleetDashboardActions.getSiteListWithOperatingTimeSuccess(response));
  } catch (error) {
    console.error(error);

    yield* put(
      SiteWidgetsFleetDashboardActions.getSiteListWithOperatingTimeError({
        error,
      })
    );
  }
}

export function* siteWidgetsFleetDashboardSaga(): Generator<ForkEffect<never>, void> {
  yield* takeLatest(
    SiteWidgetsFleetDashboardActions.GET_SITE_LIST_WITH_OPERATING_TIME_REQUEST,
    getSiteListWithOperatingTimeSaga
  );
}
