import React, { useState } from 'react';
import { EventWrapperProps as RbcEventWrapperProps } from 'react-big-calendar';
import { Popover } from 'antd';
import { differenceInMinutes } from 'date-fns';
import classNames from 'classnames';
import { ICalendarEvent } from '../../../../cleaning/interfaces/CleaningPlan.types';
import { CleaningPlanEventPopover } from '../CleaningPlanEventPopover/CleaningPlanEventPopover';
import { StyledCleaningPlanEvent } from './CleaningPlanEvent.styles';

export const CleaningPlanEvent: React.FC<RbcEventWrapperProps<ICalendarEvent>> = (props): JSX.Element => {
  const { style, event, label } = props;

  const [visiblePopover, setVisiblePopover] = useState(false);

  const handleHidePopover = (): void => {
    setVisiblePopover(false);
  };

  const handleVisibleChange = (newVisible: boolean): void => {
    setVisiblePopover(newVisible);
  };

  const eventStyle = {
    ...style,
    top: style?.top ? `${style.top}%` : undefined,
    left: style?.left ? `${style.left}%` : undefined,
    right: style?.right ? `${style.right}%` : undefined,
    bottom: style?.bottom ? `${style.bottom}%` : undefined,
    width: style?.width ? `${style?.width}%` : undefined,
    height: style?.height ? `${style?.height}%` : undefined,
  };

  const { cleaningPlanEvent } = event.resource;
  const deviceVariantName = cleaningPlanEvent.machine.type?.name;
  const eventName = deviceVariantName
    ? [cleaningPlanEvent.machine.name, deviceVariantName].join(', ')
    : cleaningPlanEvent.machine.name;

  const cleaningEventDurationMinutes = event.start && event.end ? differenceInMinutes(event.end, event.start) : 0;

  return (
    <Popover
      placement="right"
      arrow={{ pointAtCenter: false }}
      content={<CleaningPlanEventPopover hidePopover={handleHidePopover} timeSpan={label} event={event} />}
      destroyTooltipOnHide={true}
      open={visiblePopover}
      onOpenChange={handleVisibleChange}
      getPopupContainer={(triggerNode): HTMLElement =>
        (document.querySelector('.rbc-time-content') as HTMLElement) ?? triggerNode
      }
    >
      <StyledCleaningPlanEvent className="rbc-event cleaning-plan-event" style={eventStyle}>
        <p
          className={classNames('cleaning-plan-event__event-name', {
            'cleaning-plan-event__event-name--single-line': cleaningEventDurationMinutes < 30,
          })}
        >
          {eventName}
        </p>
        <p className="cleaning-plan-event__time-span">{label}</p>
      </StyledCleaningPlanEvent>
    </Popover>
  );
};
