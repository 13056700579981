import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { List } from 'antd';
import dayjs from 'dayjs';
import { DATE_FORMAT_PATTERN } from '../../../../../../lib/utils/date-handling/DateTime.types';
import { useAnalyticsSetForm } from '../../../../../cross-cutting-concerns/analytics/hooks/useAnalyticsSetForm';
import { Site } from '../../../../../cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { SiteModalsActions } from '../../state/siteModalsActions';
import * as siteModalsSelectors from '../../state/siteModalsSelectors';
import { StyledDeleteWorkIntervalModal } from './DeleteWorkIntervalModal.styles';
import { LegacySecondaryButton } from 'lib/components/LegacyButton/SecondaryButton/LegacySecondaryButton';
import { LegacyPrimaryButton } from 'lib/components/LegacyButton/LegacyPrimaryButton/LegacyPrimaryButton';
import { DateTime } from 'lib/utils/date-handling/DateTime';
import { CleaningActions } from 'app/modules/cleaning/state/cleaningActions';
import { useAnalyticsLinkActivated } from 'app/cross-cutting-concerns/analytics/hooks/useAnalyticsLinkActivated';
import { AnalyticsForm, AnalyticsLink } from 'app/cross-cutting-concerns/analytics/interfaces/Analytics.types';

export interface DeleteWorkIntervalModalProps {
  site: Site;
}

export const DeleteWorkIntervalModal = ({ site }: DeleteWorkIntervalModalProps): JSX.Element | null => {
  const analyticsLinkActivated = useAnalyticsLinkActivated();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const isVisible = useSelector(siteModalsSelectors.selectIsDeleteWorkIntervalModalVisible);
  const isLoading = useSelector(siteModalsSelectors.selectIsDeleteWorkIntervalModalLoading);
  const workIntervalToDelete = useSelector(siteModalsSelectors.selectDeleteWorkIntervalModalWorkIntervalToDelete);

  const daysOfWeekOptions = t('siteDetails.addWorkIntervalModal.daysOfWeekOptions', {
    returnObjects: true,
  }) as { label: string; value: number }[];

  const handleCancel = useCallback(() => dispatch(SiteModalsActions.hideDeleteWorkIntervalModal()), [dispatch]);

  const handleOk = useCallback(() => {
    if (!workIntervalToDelete) return;

    analyticsLinkActivated({
      linkName: AnalyticsLink.DELETE_WORK_INTERVAL,
    });

    dispatch(
      CleaningActions.deleteWorkIntervalRequest({
        id: workIntervalToDelete.id,
        siteId: site.id,
      })
    );
  }, [workIntervalToDelete, analyticsLinkActivated, dispatch, site.id]);

  const timeSpan = useMemo(() => {
    if (!workIntervalToDelete) {
      return null;
    }

    const startTime = dayjs()
      .local()
      .hour(workIntervalToDelete?.startHoursLocal ?? 0)
      .minute(workIntervalToDelete?.startMinutesLocal ?? 0)
      .second(0)
      .millisecond(0)
      .toISOString();

    const endTime = dayjs(startTime)
      .add(workIntervalToDelete?.durationMs ?? 0, 'ms')
      .toISOString();

    return [
      DateTime.formatDateByLocale(i18n.language, startTime, DATE_FORMAT_PATTERN.TIME_WITHOUT_SECOND),
      DateTime.formatDateByLocale(i18n.language, endTime, DATE_FORMAT_PATTERN.TIME_WITHOUT_SECOND),
    ].join(' - ');
  }, [i18n.language, workIntervalToDelete]);

  useAnalyticsSetForm({
    name: AnalyticsForm.DELETE_WORK_INTERVAL,
    fields: {
      workIntervalToDelete,
    },
    isVisible,
  });

  if (!workIntervalToDelete) {
    return null;
  }

  const deviceVariantName = workIntervalToDelete.machine.type?.name;
  const workIntervalName = deviceVariantName
    ? [workIntervalToDelete.machine.name, deviceVariantName].join(', ')
    : workIntervalToDelete.machine.name;

  return (
    <StyledDeleteWorkIntervalModal
      className="delete-work-interval-modal"
      title={t('siteDetails.deleteWorkIntervalModal.modalTitle')}
      open={isVisible}
      footer={[
        <LegacySecondaryButton size="s" onClick={handleCancel} className="cancel-button" key="cancel-button">
          {t('common.cancel')}
        </LegacySecondaryButton>,
        <LegacyPrimaryButton
          key="submit"
          className="submit-button"
          type="primary"
          loading={isLoading}
          onClick={handleOk}
        >
          {t('siteDetails.deleteWorkIntervalModal.deleteButton')}
        </LegacyPrimaryButton>,
      ]}
    >
      <p className="modal__body">{t('siteDetails.deleteWorkIntervalModal.modalBody')}</p>
      <List
        size="small"
        dataSource={daysOfWeekOptions}
        renderItem={(item): React.ReactNode => (
          <List.Item
            className={workIntervalToDelete.weekdaysLocal.includes(item.value) ? 'ant-list-item-selected' : ''}
          >
            {item.label}
          </List.Item>
        )}
      />
      <h3>{workIntervalName}</h3>
      <p>{timeSpan}</p>
    </StyledDeleteWorkIntervalModal>
  );
};
