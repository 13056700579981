import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { isNil } from 'lodash-es';
import { useAnalyticsLinkActivated } from '../../../../../cross-cutting-concerns/analytics/hooks/useAnalyticsLinkActivated';
import { AnalyticsLink } from '../../../../../cross-cutting-concerns/analytics/interfaces/Analytics.types';
import { Site } from '../../../../../cross-cutting-concerns/communication/interfaces/am-api-graphql';
import * as siteModalsSelectors from '../../state/siteModalsSelectors';
import { SiteModalsActions } from '../../state/siteModalsActions';
import { StyledUnassignSiteManagerModal } from './UnassignSiteManagerModal.styles';
import { SiteDetailsPanelActions } from 'app/modules/site-management/site-details-panel/state/siteDetailsPanelActions';
import { TextButton } from 'lib/components/Button/TextButton/TextButton';
import { PrimaryButton } from 'lib/components/Button/PrimaryButton/PrimaryButton';

export interface UnassignSiteManagerModalProps {
  site: Site;
}

export const UnassignSiteManagerModal = ({ site }: UnassignSiteManagerModalProps): JSX.Element => {
  const analyticsLinkActivated = useAnalyticsLinkActivated();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const visible = useSelector(siteModalsSelectors.selectIsUnassignSiteManagerModalVisible);
  const isLoading = useSelector(siteModalsSelectors.selectIsUnassignSiteManagerModalLoading);
  const siteManagerToUnassign = useSelector(siteModalsSelectors.selectUnassignSiteManagerModalSiteManagerToUnassign);

  const handleCancel = useCallback(() => dispatch(SiteModalsActions.hideUnassignSiteManagerModal()), [dispatch]);

  const handleOk = useCallback(() => {
    if (isNil(siteManagerToUnassign)) return;

    analyticsLinkActivated({
      linkName: AnalyticsLink.UNASSIGN_MANAGER,
    });

    dispatch(
      SiteDetailsPanelActions.unassignManagerFromSiteRequest({
        siteId: site.id,
        userId: siteManagerToUnassign.id,
      })
    );
  }, [analyticsLinkActivated, dispatch, site.id, siteManagerToUnassign]);

  return (
    <StyledUnassignSiteManagerModal
      className="unassign-site-manager-modal"
      title={t('siteDetails.unassignSiteManagerModal.unassignSiteManager')}
      open={visible}
      footer={[
        <TextButton size="m" onClick={handleCancel} className="cancel-button" key="cancel-button">
          {t('common.cancel')}
        </TextButton>,
        <PrimaryButton
          size="m"
          key="submit"
          className="submit-button"
          type="primary"
          loading={isLoading}
          onClick={handleOk}
        >
          {t('siteDetails.unassignSiteManagerModal.unassign')}
        </PrimaryButton>,
      ]}
    >
      <p className="modal__body">{t('siteDetails.unassignSiteManagerModal.modalBody')}</p>
      <p className="modal__sub-title">{siteManagerToUnassign?.displayName}</p>
    </StyledUnassignSiteManagerModal>
  );
};
