import styled from 'styled-components';
import { Modal } from 'lib/components/Modal/Modal';

export const StyledEditModalInfo = styled(Modal)`
  .ant-form-item-has-error
    :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
  .ant-form-item-has-error
    :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover,
  .ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
  .ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
    border-color: ${(props): string => props.theme.colors.semanticError};
    box-shadow: none;
    border-right-width: 2px !important;
  }

  .ant-form-item-explain-error {
    color: ${(props): string => props.theme.colors.semanticError};
  }

  .ant-modal-footer {
    margin-top: ${(props): string => props.theme.spacing.lg};
  }

  .ant-select-single {
    height: 32px;
  }

  .ant-form-item
    .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    content: '';
    margin-inline-end: 0;
  }

  .ant-form-item .ant-form-item-label > .ant-form-item-required::after {
    display: inline-block;
    margin-inline-end: 4px;
    visibility: visible;
    color: ${(props): string => props.theme.colors.semanticError};
    ${(props): string => props.theme.fontStyles.redesign.sm.delete};
    line-height: 1;
    content: '*';
  }
`;
