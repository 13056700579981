import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { StyledSiteDetailsSiteManagerList } from './SiteDetailsSiteManagerList.styles';
import { getSiteDetailsSiteManagerListColumns } from './columns/SiteDetailsSiteManagerList.columns';
import { Site, Role, User } from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { Table } from 'lib/components/Table/Table';
import {
  IShowAssignSiteManagerModalAction,
  SiteModalsActions,
} from 'app/modules/site-management/modals/state/siteModalsActions';
import { PermissionGuard } from 'app/cross-cutting-concerns/authentication/components/PermissionGuard/PermissionGuard';
import { Permission } from 'config/permissions';
import { SecondaryButton } from 'lib/components/Button/SecondaryButton/SecondaryButton';
import { selectPermissions } from 'app/cross-cutting-concerns/authentication/state/authenticationSelectors';
import { isUserPermitted } from 'app/cross-cutting-concerns/authentication/utils/isUserPermitted';

interface SiteDetailsSiteManagerListProps {
  site: Site;
}

export const SITE_MANAGER_LIST_ASSIGNABLE_ROLE: Role = Role.CustomerStrategicManager;

export const SiteDetailsSiteManagerList: React.FC<SiteDetailsSiteManagerListProps> = ({ site }): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const siteManagers: User[] = site.managers ?? [];

  const userPermissions = useSelector(selectPermissions);

  const hasPermissionSiteStrategicManagerUpdate = useMemo(
    () => isUserPermitted(userPermissions, [Permission.Site.StrategicManager.UPDATE]),
    [userPermissions]
  );

  const handleUnassign = (user: User): void => {
    dispatch(
      SiteModalsActions.showUnassignSiteManagerModal({
        siteManagerToUnassign: user,
      })
    );
  };

  const tableColumns = getSiteDetailsSiteManagerListColumns({
    t,
    handleUnassign,
    hasPermissionSiteStrategicManagerUpdate,
  });

  return (
    <StyledSiteDetailsSiteManagerList className="site-details-site-manager-list">
      <Row justify="space-between" align="middle" className="site-details-site-manager-list__head">
        <Col>
          <h3 tabIndex={0} className="site-details-site-manager-list__title">
            {t('siteDetails.siteManagerList.title')}
          </h3>
        </Col>
      </Row>

      <div className="site-details-site-manager-list__body">
        <Table
          dataSource={siteManagers}
          className="site-details-site-manager-list__table"
          columns={tableColumns}
          rowKey="id"
          // Re-enable when site managers can be queried with sorting
          // onChange={onTableChange}
          sortDirections={['ascend', 'descend', 'ascend']}
        />
      </div>

      <PermissionGuard requiredPermissions={[Permission.Site.StrategicManager.UPDATE]} fallback={undefined}>
        <div className="site-details-site-manager-list__assign-new-manager-button-wrapper">
          <SecondaryButton
            size="s"
            onClick={(): IShowAssignSiteManagerModalAction => dispatch(SiteModalsActions.showAssignSiteManagerModal())}
            className="site-details-site-manager-list__assign-new-manager-button"
            key="site-details-site-manager-list__assign-new-manager-button"
          >
            {t('siteDetails.siteManagerList.assignNewManager')}
          </SecondaryButton>
        </div>
      </PermissionGuard>
    </StyledSiteDetailsSiteManagerList>
  );
};
