import styled from 'styled-components';

export const StyledSiteMapPin = styled.div`
  width: 40px;
  height: 48px;
  transform: translate(-50%, -100%);

  .site-map-pin__content {
    display: flex;
    align-items: center;
  }

  .site-map-pin__map-marker {
    width: 37px;
    height: 47px;
    color: ${(props): string => props.theme.colors.black};
    filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.3));
  }
`;
